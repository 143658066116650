export const stepsData = firstName => {
  return [
    //step1
    {
      header: `Welcome ${firstName}`,
      image: '/media/icons/onboarding/step1.svg',
      description:
        'Your experience in ZenAdmin starts with the people directory. View the names and faces of your company at a glance, familiarize with role and departments, and stay on top of key events.',
      feature: [
        {header: 'Add your team', description: 'Click “Add new team member” to get started'},
        {
          header: 'Get your profile in shape',
          description: 'Click “View profile” and add your details',
        },
        {
          header: 'See upcoming events ',
          description:
            'Click “Show events” to view upcoming birthdays, anniversaries, joiners, leavers and more',
        },
      ],
    },
    //step2
    {
      header: `Public Profile`,
      image: '/media/icons/onboarding/step2.svg',
      description: 'Let your people and team get to know each other.',
      feature: [
        {header: 'A bit about work', description: 'A summary of key employment details'},
        {
          header: 'A bit about them',
          description: 'Team members can share who they are and what they like',
        },
      ],
    },
    //step3
    {
      header: `Detailed Profile`,
      image: '/media/icons/onboarding/step3.svg',
      description:
        'Collect and securely store all the information you need. From basic details, banking, employment, documents, and more. All in a central place - an essential resource for organization.',
      feature: [
        {
          header: 'All the records you need',
          description: 'For remote, for distributed, for everyone.',
        },
        {
          header: 'Get documents together',
          description:
            'Store identification, personal documents and grant access to company shared documents.',
        },
        {
          header: 'Employment records',
          description:
            'Access historical data about roles and compensation, as well as employment details.',
        },
      ],
    },
    //step4
    {
      header: `Procure anywhere`,
      showCustomComponent: true,
      description:
        'Your experience in ZenAdmin starts with the people directory. View the names and faces of your company at a glance, familiarize with role and departments, and stay on top of key events.',
      feature: [
        {header: 'Add your team', description: 'Click “Add new member” to get started.'},
        {
          header: 'Get your profile in shape',
          description: 'Click “View profile” and add your details',
        },
      ],
    },
    //step5
    {
      header: `Insights`,
      image: '/media/icons/onboarding/step5.svg',
      description:
        'Learn about turnover, headcount changes, salary spend and tenure, and follow time off trends in seconds, without any manual work.',
      feature: [
        {
          header: 'Monitor the things that matter',
          description: 'Track company salary spend, turnover and compare between periods',
        },
        {
          header: 'View team composition',
          description: 'Click “View profile” and add your details',
        },
        {
          header: 'Plan for unexpected',
          description: 'Compare time off trends to make sure team is rested',
        },
      ],
    },
    //step6
    {
      header: `Manage time away`,
      image: '/media/icons/onboarding/step6.svg',
      description:
        'Flexible, easy to use and designed with remote teams in mind. Administering staff leave with ease.',
      feature: [
        {
          header: 'A comprehensive calendar view',
          description: 'See when people aren’t around and who’s working away',
        },
        {
          header: 'Global public holidays view',
          description:
            'See public holiday for people distributed across multiple regions or countries.',
        },
        {
          header: 'Integrates with the tools you love',
          description: 'Connect with slack, google calendar',
        },
      ],
    },
  ]
}
