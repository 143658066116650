import * as React from 'react'
import classes from './styles.module.css'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  SVG,
  Button,
  BUTTON_VARIANT,
} from '@hybr1d-tech/charizard'
import {DayPicker, Matcher, PropsSingle} from 'react-day-picker'
import {ICONS} from 'app/utils/constants/icon'
import {toDate} from 'date-fns-tz' // Importing necessary functions from date-fns-tz

interface HUIDatePickerProps extends Omit<PropsSingle, 'onSelect' | 'selected'> {
  value?: Date | string
  onChange: (value: string | null) => void
  variant?: 'default' | 'form'
  disableDatepicker?: boolean
  buttonVariant?: any
  customContainerStyles?: React.CSSProperties
  popoverConfig?: {
    placement?: 'top' | 'bottom' | 'left' | 'right'
  }
  isError?: boolean
  errorMsg?: string
  displayDateFormat?: Intl.DateTimeFormatOptions
  disabled?: Matcher | Matcher[]
  showOutsideDays?: boolean
}

export default function HUIDatePickerV2({
  value,
  onChange,
  mode = 'single',
  variant = 'default',
  disableDatepicker = false,
  buttonVariant = 'ghost',
  customContainerStyles,
  popoverConfig = {placement: 'bottom'},
  isError = false,
  errorMsg = '',
  displayDateFormat = {day: '2-digit', month: 'short', year: 'numeric'},
  disabled,
  ...props
}: HUIDatePickerProps) {
  const formatter = new Intl.DateTimeFormat('en-US', displayDateFormat)

  const date = React.useMemo(() => {
    if (!value) return undefined
    return typeof value === 'string' ? toDate(value) : value // Convert string to Date object using toDate
  }, [value])

  const displayDate = React.useMemo(() => {
    if (!date) return 'Pick a date'
    return formatter.format(date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const btnRef = React.useRef<HTMLDivElement>(null)

  const handleDateSelect = (selectedDate: Date | undefined) => {
    if (selectedDate) {
      // Convert the selected date to ISO format in UTC for API submission
      const isoDate = selectedDate.toISOString()

      console.log({selectedDate, isoDate})
      onChange(isoDate)
    } else {
      onChange(null)
    }
    btnRef?.current?.click()
  }

  const renderButtonContent = () => (
    <div className={variant === 'form' ? classes.formButton : classes.buttonContent} ref={btnRef}>
      <span style={{color: !date ? 'var(--text-secondary)' : undefined}}>{displayDate}</span>
      <SVG path={ICONS.calendarDotGrid2} width={20} />
    </div>
  )

  return (
    <div className={classes.huiDatePicker} style={customContainerStyles}>
      <Popover placement={popoverConfig.placement}>
        <PopoverTrigger openOnHover={false}>
          <Button
            variant={variant === 'form' ? BUTTON_VARIANT.MINIMAL : buttonVariant}
            size="adapt"
            disabled={disableDatepicker}
            customStyles={{
              padding: variant === 'form' ? '0 12px' : undefined,
              cursor: disableDatepicker ? 'not-allowed' : 'pointer',
              borderColor: isError ? 'var(--status-danger)' : undefined,
            }}
          >
            {renderButtonContent()}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          bg="var(--neutral-white)"
          className={classes.popoverContent}
          positionerStyles={{zIndex: 4}}
        >
          <DayPicker
            mode={mode}
            selected={date}
            onSelect={handleDateSelect}
            classNames={{
              month: classes.month,
              month_caption: classes.caption,
              caption_label: classes.captionLabel,
              dropdown: classes.dropdown,
              dropdowns: classes.captionDropdowns,
              button_previous: classes.navButtonPrevious,
              button_next: classes.navButtonNext,
              month_grid: classes.table,
              weekdays: classes.headRow,
              weekday: classes.headCell,
              week: classes.row,
              day: classes.cell,
              selected: classes.daySelected,
              today: classes.dayToday,
              outside: classes.dayOutside,
              disabled: classes.dayDisabled,
              hidden: classes.dayHidden,
              day_button: classes.dayButton,
              dropdown_icon: classes.dropdownIcon,
            }}
            components={{
              Chevron: props => (
                <SVG
                  path={props.orientation === 'left' ? ICONS.chevronLeft : ICONS.chevronRight}
                  width={20}
                  height={20}
                />
              ),
            }}
            captionLayout="dropdown"
            // startMonth={new Date(2022, 0)}
            endMonth={new Date(2050, 0)}
            // hidden={[{before: new Date(2022, 0), after: new Date(2050, 0)}]}
            disabled={disabled}
            {...props}
          />
        </PopoverContent>
      </Popover>
      {isError && <p className={classes.errorMsg}>{errorMsg}</p>}
    </div>
  )
}
