import * as React from 'react'
import * as hoverCard from '@zag-js/hover-card'
import clsx from 'clsx'
import classes from './styles.module.css'
import {useMachine, normalizeProps, Portal} from '@zag-js/react'
import {SVG} from '@hybr1d-tech/charizard'
import {AnimatePresence, motion} from 'framer-motion'
import {useLocation} from 'react-router-dom'
import {checkIsActive} from 'hybrid-ui/helpers'
import {ICONS} from 'app/utils/constants/icon'
import {useSidebarMinimized} from 'app/store/layout'

interface SidebarMenuItemWithSubProps {
  to: string | string[]
  title: string
  icon: {default: string; active: string}
  isMobile?: boolean
  menuItems?: any[]
}

export default function SidebarMenuItemWithSub({
  to,
  title,
  icon,
  isMobile = false,
  menuItems,
}: SidebarMenuItemWithSubProps) {
  const [state, send] = useMachine(
    hoverCard.machine({
      id: React.useId(),
      positioning: {placement: 'right-start'},
      openDelay: 200,
      closeDelay: 200,
    }),
  )
  const api = hoverCard.connect(state, send, normalizeProps)
  const [expanded, setExpanded] = React.useState(false)
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const isMini = useSidebarMinimized()
  const condition = !isMini || isMobile
  const condition2 = isMini && !isMobile

  const handleClick = () => {
    setExpanded(s => !s)
  }

  React.useEffect(() => {
    if (isMini) {
      setExpanded(false)
    }
  }, [isMini])

  return isMini && !isMobile ? (
    <>
      <div {...api.getTriggerProps()} style={{width: '100%'}}>
        <ItemWithSub
          {...{
            isMini,
            isActive,
            title,
            handleClick,
            condition2,
            icon,
            condition,
            expanded,
            isMobile,
          }}
        />
      </div>
      <AnimatePresence>
        {api.open && (
          <Portal>
            <motion.div
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{
                type: 'spring',
                stiffness: 711.1,
                damping: 40,
              }}
              id={api.getPositionerProps().id}
              style={api.getPositionerProps().style}
              data-scope={api.getPositionerProps()['data-scope']}
              data-part={api.getPositionerProps()['data-part']}
            >
              <div {...api.getContentProps()} className={classes.content}>
                <div className={classes.titleMini}>{title}</div>
                {menuItems?.map(item => item.component)}
              </div>
            </motion.div>
          </Portal>
        )}
      </AnimatePresence>
    </>
  ) : (
    <ItemWithSub
      {...{isMini, isActive, title, handleClick, condition2, icon, condition, expanded, isMobile}}
    />
  )
}

function ItemWithSub({
  isMini,
  isActive,
  title,
  handleClick,
  condition2,
  icon,
  condition,
  expanded,
  isMobile,
}) {
  return (
    <div
      className={clsx(classes.menus, isMini && classes.menusMini, isActive && classes.menusActive)}
      key={title}
    >
      <div
        className={clsx('hui-reset-btn', classes.link, condition2 && classes.linkMini)}
        onClick={handleClick}
      >
        <div className={classes.logoBox}>
          <SVG
            path={icon.default}
            svgClassName={clsx(
              classes.logo,
              condition2 && classes.logoMini,
              isActive && classes.logoCurrActive,
            )}
            spanClassName={classes.logoSpan}
          />
          <SVG
            path={icon.active}
            svgClassName={clsx(
              classes.logoActive,
              condition2 && classes.logoMini,
              isActive && classes.logoCurrActive,
            )}
          />
        </div>

        {condition && (
          <motion.span
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.6}}
            className={clsx(classes.title, isActive && classes.activeTitle)}
          >
            <div className={classes.pendingTask}>{title}</div>
          </motion.span>
        )}

        {condition && (
          <span style={{marginLeft: 'auto'}}>
            <SVG
              path={expanded ? ICONS.minus : ICONS.plus}
              svgClassName={clsx(
                classes.toggleLogo,
                condition2 && classes.toggleLogoMini,
                isActive && classes.toggleLogoActive,
              )}
            />
          </span>
        )}

        {isMini && !isMobile && (
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.moreIcon}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 4.99998C6.55228 4.99998 7 5.44473 7 5.99336L7 6.00661C7 6.55523 6.55228 6.99998 6 6.99998C5.44772 6.99998 5 6.55523 5 6.00661L5 5.99336C5 5.44473 5.44772 4.99998 6 4.99998Z"
              fill="#070F2C"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 1.99998C6.55228 1.99998 7 2.44473 7 2.99336L7 3.00661C7 3.55523 6.55228 3.99998 6 3.99998C5.44772 3.99998 5 3.55523 5 3.00661L5 2.99336C5 2.44473 5.44772 1.99998 6 1.99998Z"
              fill="#070F2C"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 7.99998C6.55228 7.99998 7 8.44474 7 8.99336L7 9.00661C7 9.55524 6.55228 9.99999 6 9.99999C5.44772 9.99999 5 9.55523 5 9.00661L5 8.99336C5 8.44473 5.44772 7.99998 6 7.99998Z"
              fill="#070F2C"
            />
          </svg>
        )}
      </div>
    </div>
  )
}
