import {TABLE_ACTION_TYPES} from './utils'

export type MDMList = {
  id: string
  model: string
  operating_system: string
  compliant: boolean
  last_checked_at: string
  serial_number: string
  user_email: string
  integration_id: string
  created_at: string
  name: string
  device_status: string
  type: string
  location: string
  manufacturer: string
  allocated_to: {
    id: string
    first_name: string
    middle_name?: string
    last_name: string
    profile_img_url: string
    work_email: string
    is_identified: boolean
  }
  ram: string | null
  enrollment_status: string | null
  total_storage: string | null
  battery_level: string | null
  wifi_mac: string | null
  available_storage: string | null
  os_version: string | null
  build_version: string | null
  wifi_ssid: string | null
  security_pin: string | null
  encrypted: string | null
  imei_1: string | null
}

export type MDMLastSynced = {
  integration_name: string
  last_sync_date: string
}

export type MDMUser = {
  user_email: string
  location: any
  device_count: string
  user_group_names: string[]
  department_id: string[]
  role_id: string[]
  identified: boolean
  id: string
  last_name: string
  middle_name?: string
  first_name: string
  profile_img_url: any
}

export type MDMDevice = {
  id: string
  company_id: string
  model: string
  serial_number: string
  operating_system_id: string
  product_companies_id: string
  product_company: string
  last_checked_at: string
  last_scanned_at: string
  allocated_to: AllocatedTo
  enrollment_status: string
  location: any
  total_storage: string
  battery_level: number
  udid: string
  wifi_mac: string
  user_email: string
  created_at: string
  updated_at: any
  deleted_at: any
  available_storage: string
  name: string
  os_version: string
  build_version: string
  ram: any
  wifi_ssid: any
  ipaddress: any
  imei_1: any
  imei_2: any
  phoneno_1: any
  phoneno_2: any
  meid: any
  product_types_id: string
  compliant: boolean
  active_status: any
  operating_system: string
  type: string
  applications: Application[]
  policies: Policy[]
  manual_sync_count: number
  integration_id: string
  integration_name: string
}

export interface AllocatedTo {
  id: string
  first_name: string
  middle_name?: string
  last_name: string
  work_email: string
  profile_img_url: any
  is_identified: boolean
}

export interface Application {
  name: string
  version?: string
  size: string
}

export interface Policy {
  name: string
  version: string
}

export type SingleData = {
  name: string
  value: number
}
export type MDMSummary = {
  last_sync_at: MDMLastSynced[]
  active_devices: string | undefined
  inactive_devices: string | undefined
  devices_by_operating_system: SingleData[]
  devices_by_product_company: SingleData[]
  compliant: SingleData[]
  devices_by_department: SingleData[]
  devices_by_apps: SingleData[]
  user_count: string | undefined
  encrypted_devices_count: string | undefined
  recent_active_device: {
    less_than_six_hours: string
    six_to_twelve_hours: string
    twelve_to_twenty_four_hours: string
    one_to_two_days: string
    more_than_three_days: string
  }
}

export type MDMPolicy = {
  device_id: string
  id: string
  name: string
  mdm_policy_id: string
  version: string
  created_date: string
  device_count: number
}

export type MDMDeviceAssociatedPolicy = {
  device_id: string
  id: string
  mdm_policy_id: string
  name: string
  version: string
  active_version: string
}

export type MDMDeviceGroup = {
  id: string
  group_name: string
  description: string
  last_modified_at: string
  device_count: number
}

export type MDMDeviceActivity = {
  backgroundColor: string
  content: string
  date: Date
  icon: string
  type: TABLE_ACTION_TYPES
}

export type MDMApplication = {
  id: string
  mdm_application_id: string
  name: string
  version: string
  price: string
  size: string
}

export type MDMInstalledApplication = {
  id: string
  name: string
  active_version: string
  latest_version: string
  install_time: ''
}

export enum MDM_DEVICE_ACTION_TYPE {
  DIS_ENROLL = 'dis_enroll',
  LOCK = 'lock',
  UNLOCK = 'unlock',
  WIPE_DEVICE = 'wipe_device',
  SHUTDOWN = 'shutdown',
  RESTART = 'restart',
  DISABLE_LOST_MODE = 'disable_lost_mode',
  ENABLE_LOST_MODE = 'enable_lost_mode',
}

export type MDMDeviceActionOptions = {
  value: string
  label: string
  icon: string
  pin?: boolean
}
