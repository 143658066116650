import * as React from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import HUIIconV2 from '../hui-icon-v2/HUIIconV2'
import classes from './styles.module.css'
import clsx from 'clsx'
import {formatBytes, handleImageUpload} from './upload-helper'
import {DOCS_TYPE} from 'app/modules/team/pages/team-member-details/utils'
import {ICONS} from 'app/utils/constants/icon'

export type UploadFileType = {
  id?: string
  ext: string
  type: string
  url: string
  metaData?: {
    fileName: string
    key: string
  }
  key: string
  fileName: string
  isUploaded: boolean
  size: string
  setIntervalFunction?: any
}

export enum UPLOAD_ALIGN_BTN_CONTENT {
  LEFT = 'flex-start',
  CENTER = 'center',
  RIGHT = 'flex-end',
}
interface HUIUploadProps {
  type: DOCS_TYPE
  disabled?: boolean
  getUploadDoc: (any) => void
  setIsUploading?: (any) => void
  customComponent?: React.ReactChild
  fileUploadLimit?: number
  isMultiple?: boolean
  singleFileClassName?: string
  fileContainerClassName?: string
  uploadFileLimit?: number //its in MB
  uploadBtnClassName?: string
  alignContent?: UPLOAD_ALIGN_BTN_CONTENT
  showUploadIcon?: boolean
  additionalNode?: React.ReactElement
  addDocumentText?: string
  customUrl?: string
  beforeUploadHandler?: () => void
  inventoryId?: string | null
  softwareId?: string | null
  preLoadedFiles?: Array<UploadFileType>
  showFileList?: boolean
  acceptedFileTypes?: string
  extraSubtitleText?: string
  error?: string | null
  addDocumentSubtitle?: string
}

interface progressBarType {
  name: string
  progress: number
}

export default function HUIUpload({
  getUploadDoc,
  setIsUploading,
  customComponent,
  fileUploadLimit = 10,
  type,
  isMultiple = true,
  uploadFileLimit = 5,
  singleFileClassName,
  fileContainerClassName,
  uploadBtnClassName,
  alignContent,
  showUploadIcon = false,
  additionalNode,
  disabled = false,
  addDocumentText = 'Add document',
  customUrl,
  beforeUploadHandler,
  extraSubtitleText,
  inventoryId = undefined,
  softwareId,
  preLoadedFiles = [],
  showFileList = true,
  acceptedFileTypes = 'image/png, image/jpeg, image/jpeg, image/webp, application/pdf, .doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  error,
  addDocumentSubtitle,
}: HUIUploadProps) {
  const [files, setFiles] = React.useState<UploadFileType[] | []>([])
  const [fileUploadProgress, setFileUploadProgress] = React.useState<progressBarType[]>([])
  const [fileUploadLimitError, setFileUploadLimitError] = React.useState<string | null>(null)
  const [uploadLimitError, setUploadLimitError] = React.useState('')
  const fileInputRef = React.useRef<any>()
  const isInputDisabled = fileUploadLimit && files.length >= fileUploadLimit ? true : false

  React.useEffect(() => {
    if (preLoadedFiles.length > 0) {
      const newFiles = preLoadedFiles.map(file => ({...file, isUploaded: true}))
      setFiles(newFiles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preLoadedFiles])
  async function handleFileChange({target: {files: uploadedFiles}}) {
    setFileUploadLimitError(null)
    setUploadLimitError('')
    let allFiles: any = []
    if (fileUploadLimit && uploadedFiles.length + files.length > fileUploadLimit) {
      setFileUploadLimitError(`Error: You can only upload ${fileUploadLimit} files`)
      return
    }
    let flag = false
    for (let key in uploadedFiles) {
      if (
        typeof uploadedFiles[key] === 'object' &&
        (uploadFileLimit || 5) >= Number((uploadedFiles[key]?.size / (1024 * 1024)).toFixed(2))
      ) {
        allFiles.push({
          type: uploadedFiles[key].type,
          url: URL.createObjectURL(uploadedFiles[key]),
          fileName: uploadedFiles[key]?.name,
          isUploaded: false,
          size: formatBytes(uploadedFiles[key]?.size),
        })
      }
      if ((uploadFileLimit || 5) < Number((uploadedFiles[key]?.size / (1024 * 1024)).toFixed(2))) {
        flag = true
      }
    }

    allFiles = allFiles.map((file: UploadFileType) => {
      const key = (Math.random() + 1).toString(36).substring(7)
      const interval = addProgressToImageUpload(key)
      return {...file, setIntervalFunction: interval, key}
    })
    if (flag) {
      setUploadLimitError(`Maximum file size allowed is  ${uploadFileLimit || 5}MB.`)
      return
    }
    if (uploadedFiles.length > allFiles.length) {
      setUploadLimitError(
        `Few files were not uploaded as they are bigger than maximum size allowed ${uploadFileLimit || 5}MB.`,
      )
      // return
    }

    if (!isMultiple) {
      setFiles([...allFiles])
      fileInputRef.current.value = ''
      return
    }
    setFiles([...files, ...allFiles])
    fileInputRef.current.value = ''
  }

  function handleFileRemove(key) {
    const newFiles = files.filter((items, idx) => items.key !== key)
    setFiles(newFiles)
    getUploadDoc(newFiles)
  }

  const addProgressToImageUpload = (name: string) => {
    let step = 0.5
    let currentProgress = 0
    const interval = setInterval(function () {
      currentProgress += step
      let progress = Math.round((Math.atan(currentProgress) / (Math.PI / 2)) * 100 * 1000) / 1000
      if (progress >= 70) {
        step = 0.2
      }
      setFileUploadProgress(preState => {
        const newFileUploadProgress = fileUploadProgress.find(progress => progress.name === name)
        if (newFileUploadProgress) {
          return preState.map(progress => {
            if (progress.name !== name) return progress
            return {name: name, progress: progress} as unknown as progressBarType
          })
        } else {
          return [...preState, {name: name, progress: progress}]
        }
      })
    }, 100)
    return interval
  }
  const getFileUploadProgress = (name: string) => {
    return fileUploadProgress.findLast(progress => progress.name === name)?.progress
  }
  useDeepCompareEffect(() => {
    const uploadFile = async () => {
      const newFiles = structuredClone(files)
      setIsUploading && setIsUploading(true)
      const uploadedFiles = await handleImageUpload(
        newFiles,
        type,
        undefined,
        undefined,
        customUrl,
        inventoryId,
        softwareId,
      )
      setFiles(uploadedFiles)
      getUploadDoc(uploadedFiles)
      setIsUploading && setIsUploading(false)
    }
    if (files.length) uploadFile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  return (
    <>
      <input
        ref={fileInputRef}
        onChange={handleFileChange}
        type="file"
        accept={acceptedFileTypes}
        style={{display: 'none'}}
        multiple={isMultiple}
        max={10}
        disabled={disabled || files.length >= 10}
      />
      <div style={{width: '100%'}}>
        {/* {fileUploadLimit && (
          <p className={classes.fileUploadLimit}>
            Note: You can upload maximum of {fileUploadLimit} file(s)
          </p>
        )} */}
        {(fileUploadLimitError || !!uploadLimitError) && (
          <p className={classes.fileUploadLimitError}>{fileUploadLimitError || uploadLimitError}</p>
        )}
        <div
          onClick={() => {
            beforeUploadHandler && beforeUploadHandler()
            fileInputRef.current.click()
          }}
          onDrop={e => {
            e.preventDefault()
            e.persist()
            handleFileChange({target: {files: e.dataTransfer.files}})
          }}
          className={
            customComponent
              ? ''
              : clsx(
                  classes.uploadBtn,
                  uploadBtnClassName,
                  disabled ? classes.disabledUploadBtn : '',
                )
          }
          onDragOver={e => {
            e.preventDefault()
          }}
          onDragLeave={e => {
            e.preventDefault()
          }}
          style={{
            background: isInputDisabled ? '#F4F4F4' : '',
            cursor: disabled || isInputDisabled ? 'not-allowed' : 'pointer',
          }}
        >
          {customComponent ? (
            customComponent
          ) : (
            <>
              <div className={clsx(classes.parentContainer)} style={{justifyContent: alignContent}}>
                {showUploadIcon && (
                  <HUIIconV2
                    path={ICONS.plus}
                    variant={disabled ? 'secondary' : 'theme'}
                    size={24}
                  />
                )}
                <div className={clsx(classes.uploadContainer)} style={{alignItems: alignContent}}>
                  <div className={clsx(classes.title, disabled ? classes.disabledTitle : '')}>
                    {addDocumentText}
                  </div>
                  <div className={clsx(classes.subTitle, disabled ? classes.disabledSubTitle : '')}>
                    Choose file or drag and drop here
                  </div>
                  <div className={clsx(classes.subTitle, disabled ? classes.disabledSubTitle : '')}>
                    {addDocumentSubtitle ||
                      `PDF, Doc, Docx, PNG, WEBP,Xls, Xlsx and JPEG. Max ${fileUploadLimit} ${fileUploadLimit <= 1 ? 'file' : 'files'} can be
                    attached.`}
                  </div>
                  {extraSubtitleText && (
                    <div
                      className={clsx(classes.subTitle, disabled ? classes.disabledSubTitle : '')}
                    >
                      {extraSubtitleText}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        {additionalNode}
        {showFileList && (
          <div className={clsx(classes.fileContainer, fileContainerClassName)}>
            {!!files.length &&
              files.map((file: UploadFileType, index) => (
                <div key={file.key + file.fileName}>
                  {file.isUploaded ? (
                    <div
                      className={clsx(classes.singleDoc, singleFileClassName)}
                      key={file.fileName ?? index}
                    >
                      <div className={classes.contentContainer}>
                        <div className={classes.dFlex}>
                          <img
                            className={classes.uploading}
                            src={ICONS.fileVertical}
                            alt="upload"
                          />
                          <div className={classes.detailsContainer}>
                            <a
                              href={file.url}
                              target="_blank"
                              rel="noreferrer"
                              className={classes.fileName}
                            >
                              {file.fileName}
                            </a>
                            <div className={classes.subTitle2}>
                              {(file.ext || file.type) && (
                                <div className={classes.fileType}>
                                  {file.ext || file.type?.split('/')[1]}{' '}
                                </div>
                              )}
                              {file.size && <div className={classes.smallCircle}></div>}
                              <div> {file.size}</div>
                            </div>
                          </div>
                        </div>
                        <img
                          onClick={() => handleFileRemove(file.key)}
                          className={classes.deleteIcon}
                          src={ICONS.delete.deleteOutline}
                          alt="delete"
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={clsx(classes.singleDoc, singleFileClassName)}
                      key={file.fileName ?? index}
                    >
                      <div
                        className={classes.progressBar}
                        style={{width: `${getFileUploadProgress(file.key)}%`}}
                      ></div>

                      <div className={classes.contentContainer}>
                        <div className={classes.dFlex}>
                          <img className={classes.uploading} src={ICONS.fileUpload} alt="upload" />
                          <div className={classes.detailsContainer}>
                            <div className={classes.fileName}>{file.fileName}</div>
                            <div className={classes.subTitle2}>
                              <div className={classes.fileType}>{file.type?.split('/')[1]} </div>
                              <div className={classes.smallCircle}></div>
                              <div>{file.size}</div>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
        {error && <p className={classes.errorMsg}>{error}</p>}
      </div>
    </>
  )
}
