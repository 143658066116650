import {QueryKey, useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {useMutate} from 'app/utils/hooks/useMutate'
import {IProfile} from 'types/team'
import {TeamService} from './team.service'
import {teamKeys} from '../query-key-factory'
import {TeamQueries} from 'app/store/team'
import {TEAM_TOASTS} from 'app/utils/constants/toast'
import {CACHE_STATE} from 'types/utils'

const svc = new TeamService()

export const useGetTeamListFilters = () => {
  const res = useQuery({
    queryKey: teamKeys.listFilters(),
    queryFn: svc.getTeamListFilters,
  })

  return {
    filters: res.data || [],
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useGetTeamMembers = (query: TeamQueries) => {
  const res = useQuery({
    queryKey: teamKeys.members(query),
    queryFn: () => svc.getTeamMembers(query),
  })

  return {
    members: res.data?.data || [],
    fetchingMembers: res.isPending,
    errorFetchingMembers: res.isError,
    metaData: res?.data?.meta_data,
  }
}

export const useGetTeamMembersCount = (query: TeamQueries) => {
  const res = useQuery({
    queryKey: teamKeys.membersCount(query),
    queryFn: () => svc.getTeamMembersCount(query),
  })

  return {
    membersCount: res.data || [],
    isPending: res.isPending,
    isLoading: res.isLoading,
    isError: res.isError,
  }
}

export const useGetTeamMembersLegacy = (key: QueryKey, query, options = {}) => {
  const response = useInfiniteQuery({
    queryKey: key,
    queryFn: ({pageParam}) => svc.getInfiniteTeamMembers(query, pageParam),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
    ...options,
  })

  const totalMembers = response?.data?.pages?.map(page => page.data).flat()

  return {
    users: totalMembers ?? [],
    fetchingUsers: response.isLoading,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    ...response,
  }
}

export const useGetTeamEvents = () => {
  const res = useInfiniteQuery({
    queryKey: teamKeys.events(),
    queryFn: svc.getTeamEvents,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
  })

  const events = res.data?.pages?.map(page => page.data).flat()

  return {
    events: events ?? [],
    isPending: res.isPending,
    isError: res.isError,
    metaData: res?.data?.pages?.[0]?.meta_data,
    fetchNextPage: res.fetchNextPage,
    isFetchingNextPage: res.isFetchingNextPage,
  }
}

export const useGetWebstoreTeam = (key, query) => {
  const response = useInfiniteQuery({
    queryKey: [key],
    queryFn: ({pageParam}) => svc.getWebstoreTeam(query, pageParam),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
  })

  const totalMembers = response?.data?.pages?.map(page => page.data).flat()

  return {
    users: totalMembers ?? [],
    fetchingUsers: response.isLoading,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    ...response,
  }
}

export const useGetAllTeamMembers = (payload?: object) => {
  const queryKeys = payload ? Object.values(payload) : []
  const response = useQuery({
    queryKey: ['all-team-members', ...queryKeys],
    queryFn: () => svc.getAllTeamMembers(payload),
  })
  return {users: response.data, fetchingUsers: response.isLoading, ...response}
}

export const useGetUser = id => {
  const response = useQuery({
    queryKey: [id],
    queryFn: () => svc.getUserByID(id),
  })
  return {user: response.data?.data, fetchingUser: response.isLoading, ...response}
}

export const useAddActiveTeamMember = () => {
  return useMutate(svc.addActiveMember, teamKeys.all)
}
export const useAddNewHire = () => {
  return useMutate(svc.addNewHire, teamKeys.all)
}

export const useOffboardMemberLegacy = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.offboardUserLegacy, queryIdToInvalidate, successMsg)
}

export const useOffboardMember = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.offboardUser, queryIdToInvalidate, successMsg)
}

export const useBulkUploadTeam = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.bulkUploadTeam, queryIdToInvalidate, successMsg)
}

export const useBulkUploadTeamV2 = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.bulkUploadTeamV2, queryIdToInvalidate, successMsg)
}

export const useGetBulkUploadTeamV2Template = (id?: QueryKey) => {
  const response = useQuery({
    queryKey: [`csv${id}`],
    queryFn: () => svc.getBulkUploadTeamV2Template(id),
    enabled: !!id,
  })
  return {url: response?.data, ...response}
}

export const useGetBulkUploadTeamV2Headers = (id?: string) => {
  const response = useQuery({
    queryKey: [`headers${id}`],
    queryFn: () => svc.getBulkUploadTeamV2TemplateHeader(id),
    enabled: !!id,
  })
  console.log({response})
  return {headers: response?.data, ...response}
}

export const useActivateUser = () => {
  return useMutate(svc.activateUser, teamKeys.all, TEAM_TOASTS.activateUser)
}

// org chart
export const useOrgTree = () => {
  const res = useQuery({
    queryKey: teamKeys.orgChart(),
    queryFn: svc.getOrgTree,
  })
  return {
    tree: res.data,
    isLoading: res.isLoading,
    isError: res.isError,
    isFetching: res.isFetching,
  }
}

export const useProfileDrawer = id => {
  const res = useQuery({
    queryKey: teamKeys.profileDrawer(id),
    queryFn: () => svc.getOrgTreeUser(id),
    enabled: id ? true : false,
  })
  return {user: res.data, isLoading: res.isLoading, isError: res.isError}
}

export const useDeleteCompensation = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deleteCompensation, queryIdToInvalidate, successMsg)
}

export const useAssignTeam = () => {
  return useMutate(svc.assignTeam, teamKeys.all, TEAM_TOASTS.assignTag)
}

export const useGetTeamEventsLegacy = (key, query) => {
  const response = useInfiniteQuery({
    queryKey: [key],
    queryFn: ({pageParam}) => svc.getTeamEventsLegacy(query, pageParam),
    getNextPageParam: lastPage => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
  })

  const totalEvents = response?.data?.pages?.map(page => page.data.events).flat()
  const todaysEvents = response?.data?.pages?.[0]?.data?.today_events

  const eventsMeta = {
    upcomingEvents: response?.data?.pages?.[0]?.meta_data.total_items ?? 0,
    todaysEvents: todaysEvents?.length ?? 0,
  }

  return {
    events: totalEvents ?? [],
    todaysEvents: todaysEvents ?? [],
    fetchingEvents: response.isLoading,
    eventsMeta,
    ...response,
  }
}

export const useGetEmploymentData = (key, query) => {
  const response = useQuery({
    queryKey: [key],
    queryFn: () => svc.getEmploymentData(query),
  })
  return {employmentData: response?.data, ...response}
}

export const useGetProfile = (key, userId) => {
  const response = useQuery({
    queryKey: [key, userId],
    queryFn: () => svc.getProfile(userId),
  })
  const profile: IProfile | undefined = response?.data?.data
  return {profile, fetchingProfile: response?.isLoading, ...response}
}

export const useEditPersonal = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
  userId?: string,
) => {
  return useMutate((...args) => svc.editPersonal(...args, userId), queryIdToInvalidate, successMsg)
}

export const useEditPersonalAddress = (
  queryIdToInvalidate?: any,
  successMsg?: string,
  userId?: string,
) => {
  return useMutate(
    (...args) => svc.editPersonalAddress(...args, userId),
    queryIdToInvalidate,
    successMsg,
  )
}

export const useEditHealth = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
  userId?: string,
) => {
  return useMutate((...args) => svc.editHealth(...args, userId), queryIdToInvalidate, successMsg)
}

export const useEditBanking = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
  userId?: string,
) => {
  return useMutate((...args) => svc.editBanking(...args, userId), queryIdToInvalidate, successMsg)
}

export const useEditSocial = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
  userId?: string,
) => {
  return useMutate((...args) => svc.editSocial(...args, userId), queryIdToInvalidate, successMsg)
}

export const useGetAllergies = key => {
  const response = useQuery({
    queryKey: [key],
    queryFn: () => svc.getAllergies(),
  })
  const allergies =
    response?.data?.data.map(obj => ({...obj, label: obj.name, value: obj.id})) ?? []
  return {allergies, fetchingAllergies: response?.isLoading, ...response}
}

export const useCreateAllergy = (queryIdToInvalidate?: QueryKey) => {
  return useMutate(svc.createAllergy, queryIdToInvalidate, undefined, {hideToast: true})
}

export const useGetOffboardingDetails = (userId: string) => {
  const response = useQuery({
    queryKey: [`${userId}-offboarding`],
    queryFn: () => svc.getOffboardingDetails(userId),
  })
  return response
}

export const useGetTimeAwayPeriodDetails = (key, userId) => {
  const response = useQuery({
    queryKey: [key, userId],
    queryFn: () => svc.getTimeAwayPeriodDetails(userId),
    enabled: userId ? true : false,
  })
  return response
}

export const useAddAdjustment = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.addAdjustment, queryIdToInvalidate, successMsg)
}
export const useUpdateAdjustment = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateAdjustment, queryIdToInvalidate, successMsg)
}
export const useDeleteAdjustment = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deleteAdjustment, queryIdToInvalidate, successMsg)
}
export const useGetPolicyList = key => {
  const response = useQuery({
    queryKey: [key],
    queryFn: () => svc.getPolicyList(),
  })
  return response
}

export const useAddPolicyToUser = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.addPolicyToUser, queryIdToInvalidate, successMsg)
}

export const useEditUserPolicy = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.editPolicyToUser, queryIdToInvalidate, successMsg)
}
export const useDeletePeriodPolicy = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deletePeriodPolicy, queryIdToInvalidate, successMsg)
}

export const useEditUpcomingLeave = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.editUpcomingLeave, queryIdToInvalidate, successMsg)
}

export const useDeleteUpcomingLeave = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deleteUpcomingLeave, queryIdToInvalidate, successMsg)
}

export const useGetHistoryBreakdown = (key, userId) => {
  const response = useQuery({
    queryKey: [key],
    queryFn: () => svc.getHistoryBreakdown(userId),
  })
  return response
}

export const useGetUserRoleHistory = (key, userId) => {
  const response = useQuery({
    queryKey: [key],
    queryFn: () => svc.getUserRoleHistory(userId),
  })
  return response
}

export const useDeleteUserRoleHistory = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  const response = useMutate(svc.deleteUserRoleHistory, queryIdToInvalidate, successMsg)
  return response
}

export const useUpdateUserRoleHistory = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  const response = useMutate(svc.updateUserRoleHistory, queryIdToInvalidate, successMsg)
  return response
}

export const useGetSubSectionDetails = (subSectionId: string, userId: string) => {
  const response = useQuery({
    queryKey: [subSectionId],
    queryFn: () => svc.getSubSectionDetails(subSectionId, userId),
    enabled: !!subSectionId,
  })
  return response
}
export const useGetIdentificationSubSectionDetails = (subSectionId: string, userId: string) => {
  const response = useQuery({
    queryKey: [subSectionId],
    queryFn: () => svc.getIdentificationSubSectionDetails(subSectionId, userId),
    enabled: !!subSectionId,
  })
  return response
}

export const useUpdateSubCategoryFields = (queryIdToInvalidate?: any, successMsg?: string) => {
  const response = useMutate(svc.updateSubCategoryField, queryIdToInvalidate, successMsg)
  return response
}

export const useGetBulkUploadOptions = () => {
  const response = useQuery({
    queryKey: ['team-bulk-upload-options'],
    queryFn: () => svc.getBulkUploadOptions(),
  })
  return response
}

export const useGetTeamBulkUploadMapping = (templateId: string) => {
  const response = useQuery({
    queryKey: [templateId],
    queryFn: () => svc.getTeamBulkUploadMapping(templateId),
  })
  return response
}

export const useGetNewHireCache = ({enabled}) => {
  const res = useQuery({
    queryKey: teamKeys.newHireCache(),
    queryFn: () => svc.getNewHireCache(),
    enabled,
  })
  return {
    cache: res.data,
    fetchingCache: res.isLoading || res.isFetching,
    isErrorCache: res.isError,
  }
}

export const useCreateNewHireDetailsCache = () => {
  const res = useMutate(svc.createNewHireDetailsCache, teamKeys.newHireCache())
  return {
    createNewHireDetailsCache: res.mutateAsync,
    isCreatingNewHireDetailsCache: res.isPending,
  }
}

export const useDeleteNewHireCache = () => {
  const res = useMutate(svc.deleteNewHireCache, teamKeys.newHireCache())
  return {
    deleteNewHireCache: res.mutateAsync,
    isDeletingNewHireCache: res.isPending,
  }
}

export const useCreateNewHireCache = (cacheState: CACHE_STATE, successMessage?: string) => {
  const isFinalState = cacheState === CACHE_STATE.FINAL
  const res = useMutate(
    svc.createNewHireCache,
    teamKeys.newHireCache(),
    isFinalState ? successMessage || TEAM_TOASTS.createNewHire : undefined,
  )
  return {
    createNewHireCache: res.mutateAsync,
    isCreatingNewHireCache: res.isPending,
  }
}

export const useGetReHireCache = ({enabled}) => {
  const res = useQuery({
    queryKey: teamKeys.reHireCache(),
    queryFn: () => svc.getReHireCache(),
    enabled,
  })
  return {
    cache: res.data,
    fetchingCache: res.isLoading || res.isFetching,
    isErrorCache: res.isError,
  }
}

export const useDeleteReHireCache = () => {
  const res = useMutate(svc.deleteReHireCache, teamKeys.reHireCache())
  return {
    deleteReHireCache: res.mutateAsync,
    isDeletingReHireCache: res.isPending,
  }
}

export const useCreateReHireDetailsCache = () => {
  const res = useMutate(svc.createReHireDetailsCache, teamKeys.reHireCache())
  return {
    createReHireDetailsCache: res.mutateAsync,
    isCreatingReHireDetailsCache: res.isPending,
  }
}

export const useCreateReHireCache = (cacheState: CACHE_STATE, successMessage?: string) => {
  const isFinalState = cacheState === CACHE_STATE.FINAL
  const res = useMutate(
    svc.createReHireCache,
    teamKeys.reHireCache(),
    isFinalState ? successMessage || TEAM_TOASTS.createReHire : undefined,
  )
  return {
    createReHireCache: res.mutateAsync,
    isCreatingReHireCache: res.isPending,
  }
}

export const useGetActivateCache = ({enabled}) => {
  const res = useQuery({
    queryKey: teamKeys.activateCache(),
    queryFn: () => svc.getActivateCache(),
    enabled,
  })
  return {
    cache: res.data,
    fetchingCache: res.isLoading || res.isFetching,
    isErrorCache: res.isError,
  }
}

export const useDeleteActivateCache = () => {
  const res = useMutate(svc.deleteActivateCache, teamKeys.activateCache())
  return {
    deleteActivateCache: res.mutateAsync,
    isDeletingActivateCache: res.isPending,
  }
}

export const useCreateActivateDetailsCache = () => {
  const res = useMutate(svc.createActivateDetailsCache, teamKeys.activateCache())
  return {
    createActivateDetailsCache: res.mutateAsync,
    isCreatingActivateDetailsCache: res.isPending,
  }
}

export const useCreateActivateCache = (cacheState: CACHE_STATE, successMessage?: string) => {
  const isFinalState = cacheState === CACHE_STATE.FINAL
  const res = useMutate(
    svc.createActivateCache,
    teamKeys.activateCache(),
    isFinalState ? successMessage || TEAM_TOASTS.activate : undefined,
  )
  return {
    createActivateCache: res.mutateAsync,
    isCreatingActivateCache: res.isPending,
  }
}
