import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

// @todo
type PageNav = {label: string; value: string; to: string}

export interface ILayoutStore {
  isSidebarMinimized: boolean
  pageTitle: string
  purgeLayout: boolean
  pageNav: PageNav[] | null
  customStyles: React.CSSProperties | null

  actions: {
    toggleMini: () => void
    // setIsSidebarMinimized: (data: boolean) => void
    setPageTitle: (title: string) => void
    resetStore: () => void
    resetMini: () => void
    setPurgeLayout: (bool: boolean) => void
    setPageNav: (links: PageNav[] | null) => void
    setCustomStyles: (styles: React.CSSProperties | null) => void
  }
}

const initialStore = {
  isSidebarMinimized: true,
  pageTitle: '',
  purgeLayout: false,
  pageNav: null,
  customStyles: null,
}

const useLayoutStore = create<ILayoutStore>()(
  devtools(set => ({
    ...initialStore,
    actions: {
      toggleMini: () => set(state => ({isSidebarMinimized: !state.isSidebarMinimized})),
      // setIsSidebarMinimized: isSidebarMinimized => set({isSidebarMinimized}),
      setPageTitle: pageTitle => set({pageTitle}),
      resetStore: () => set(initialStore),
      resetMini: () => set({isSidebarMinimized: true}),
      setPurgeLayout: purgeLayout => set({purgeLayout}),
      setPageNav: pageNav => set({pageNav}),
      setCustomStyles: customStyles => set({customStyles}),
    },
  })),
)

// * selectors
export const useSidebarMinimized = () => useLayoutStore(s => s.isSidebarMinimized)
export const usePageTitle = () => useLayoutStore(s => s.pageTitle)
export const usePurgeLayoutSelector = () => useLayoutStore(s => s.purgeLayout)
export const usePageNav = () => useLayoutStore(s => s.pageNav)
export const useHeaderCustomStyles = () => useLayoutStore(s => s.customStyles)

// * actions
export const useLayoutActions = () => {
  return useLayoutStore(s => s.actions)
}
