import {useUpdateShowTutorial} from 'app/services'
import clsx from 'clsx'
import HUIModal from 'hybrid-ui/components/hui-modal'
import classes from '../styles.module.css'
import {useAuthActions, useCurrentUser} from 'app/store/auth'

function FinanceUserOnboarding({isOpen}) {
  const {mutateAsync: updateShowTutorial} = useUpdateShowTutorial([], '')

  const currentUser = useCurrentUser()
  const {requestUser} = useAuthActions()

  async function handleTutorialComplete() {
    try {
      await updateShowTutorial({is_show_tutorial: false})
      await requestUser()
    } catch (error) {
      console.log({error})
    }
  }

  return (
    <HUIModal
      showFooter={false}
      showHeader={false}
      size={'lg'}
      isOpen={isOpen}
      onClose={handleTutorialComplete}
      onOk={handleTutorialComplete}
      heading={''}
    >
      <div className={classes.modalContainer}>
        <div className="d-flex justify-content-between mb-10">
          <div className="d-flex align-item-center">
            <div>
              <div className={classes.modalHeader}>
                {' '}
                Hello! {currentUser?.first_name}, Welcome to
              </div>
              <img
                className={classes.hyrbidImg}
                src="/assets/logo-full.svg"
                alt="hybrid"
                style={{display: 'inline', marginTop: '-10px'}}
              />
            </div>
          </div>
          <div className={classes.closeBtn} onClick={handleTutorialComplete}>
            Close
          </div>
        </div>
        <div className={classes.modalSecondContaienr}>
          <div className={clsx(classes.modalDescriptionContainer, 'd-flex flex-column w-55')}>
            <div className={clsx(classes.text, 'mb-10')}>
              {/* <b>{currentUser?.first_name}</b> invited you to <b>{ currentUser?.company?.name}</b> workplace */}
            </div>
            <div className={clsx(classes.text, 'mb-10')}>
              ZenAdmin will help you keep all your work information in one place, learn about your
              colleagues, see who’s off when, and take time off.
            </div>
            <div className={clsx(classes.textHeader, 'mb-6')}>
              Here are 2 things to get you started:
            </div>
            <div className={classes.text}>
              <b>Get your profile in shape </b>
            </div>
            <div className={clsx(classes.text, 'mb-6')}>
              Click “View your profile” (top left), add your details, and ensure your profile is
              complete with up to date information.
            </div>
            <div className={classes.text}>
              <b>Familarise with names and faces</b>
            </div>
            <div className={clsx(classes.text, 'mb-6')}>
              {/* {' '}
              {currentUser?.company?.name} currently has 4 people! */}
              Have a look around and see who your colleagues are and what they do.
            </div>
          </div>
          <div className={classes.modalImageContainer}>
            <img src="/media/icons/onboarding/finance-user.svg" alt="onboarding" />
          </div>
        </div>
      </div>
    </HUIModal>
  )
}

export default FinanceUserOnboarding
