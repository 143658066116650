import * as React from 'react'
import {
  BUTTON_V2_VARIANT,
  Drawer,
  EmptyState,
  Input,
  InputContainer,
  InputGroup,
  InputLabel,
  Loader,
  Select,
} from '@hybr1d-tech/charizard'
import {useCreateLeaveRequests, useGetLeaveBalance, useGetLeaveConfig} from 'app/services'
import {useFormik} from 'formik'
import {getLeaveRequestSchema, leaveRequestInitialValues} from '../../utils/schema'
import {
  // LEAVE_AWAY_TYPE,
  LeaveRequestPayload,
  LeaveTypeConfigResponse,
} from 'types/time-away'
import HuiDatePicker from 'hybrid-ui/components/hui-date-picker'
import {getLeaveAwayOptions, LeaveAwayOption} from '../../utils/data'
import {formatDateYYYYMMDD, getDifference, getFutureDate, getPastDate} from 'app/utils/helper'
import HUIUpload from 'hybrid-ui/components/hui-upload-v2/HUIUpload'
import {DOCS_TYPE} from '../../../../utils'
import classes from './styles.module.css'
import {ICONS} from 'app/utils/constants/icon'
import {Matcher} from 'react-day-picker'
// import {isSameDay} from 'date-fns'

interface LeaveRequestProps {
  userId: string
  isOpen: boolean
  onClose: () => void
}

export default function LeaveRequest({userId, isOpen, onClose}: LeaveRequestProps) {
  const {configs, fetchingConfigs, isErrorConfigs, configIsSuccess} = useGetLeaveConfig(userId)
  const [balance, setBalance] = React.useState<string>('')
  const {createRequest, isCreatingRequest} = useCreateLeaveRequests()
  const {getLeaveBalance} = useGetLeaveBalance()
  const [selectedLeaveConfig, setSelectedLeaveConfig] =
    React.useState<LeaveTypeConfigResponse | null>(null)
  const [startAwayOption, setStartAwayOption] = React.useState<LeaveAwayOption[]>([])
  const [endAwayOption, setEndAwayOption] = React.useState<LeaveAwayOption[]>([])
  const leaveTypeOptions = React.useMemo(() => {
    return configs?.map(config => {
      return {
        label: config.emoji ? `${config.name} ${config.emoji}` : config.name,
        value: config.id,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs?.length])

  const formik = useFormik<LeaveRequestPayload>({
    initialValues: leaveRequestInitialValues,
    validationSchema: getLeaveRequestSchema(selectedLeaveConfig),
    onSubmit: async values => {
      const payload = {
        ...values,
      }
      if (values.supporting_document) {
        payload.supporting_document = values.supporting_document.url
        payload.supporting_document_details = {
          file_name: values.supporting_document.metaData.fileName,
          key: values.supporting_document.metaData.key,
          type: values.supporting_document.type,
          ext: values.supporting_document.ext,
        }
      } else {
        delete payload.supporting_document
        delete payload.supporting_document_details
      }
      delete payload.isValid
      await createRequest({userId, payload})
      onClose()
    },
  })

  const {start_date, end_date, start_away, end_away, leave_type_id} = formik.values

  React.useEffect(() => {
    if (start_date && end_date && start_away && end_away && leave_type_id) {
      getLeaveBalance({
        userId,
        payload: {
          start_date,
          end_date,
          start_away,
          end_away,
          leave_type_id,
        },
      }).then(data => {
        setBalance(data.message)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, end_date, start_away, end_away, leave_type_id])

  const getUploadDoc = file => {
    formik.setFieldValue('supporting_document', file[0])
  }

  const isDocumentRequired = selectedLeaveConfig
    ? getDifference(formik.values.start_date!, formik.values.end_date!) >=
        selectedLeaveConfig?.minimum_leave_document && selectedLeaveConfig?.supporting_document
    : false

  const enablePastDays = selectedLeaveConfig?.past_dates
  const pastDays = selectedLeaveConfig?.past_days
  const enableFutureDays = selectedLeaveConfig?.future_dates
  const futureDays = selectedLeaveConfig?.future_days

  const docError = formik.submitCount > 0 ? (formik.errors.supporting_document as string) : ''
  const startDateError = formik.touched.start_date ? formik.errors.start_date : ''
  let endDateError = formik.touched.end_date ? formik.errors.end_date : ''
  if (formik.errors?.isValid) {
    endDateError = `maximum ${selectedLeaveConfig?.maximum_leaves} leaves are allowed`
  }

  React.useEffect(() => {
    const awayOptions = getLeaveAwayOptions(
      selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
    )
    setStartAwayOption(awayOptions)
    setEndAwayOption(awayOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLeaveConfig?.id])

  const startDateFrom = enablePastDays
    ? pastDays
      ? getPastDate(pastDays)
      : undefined
    : formatDateYYYYMMDD(new Date())
  const startDateTo = formik.values.end_date
    ? formik.values.end_date
    : enableFutureDays
      ? futureDays
        ? getFutureDate(futureDays)
        : undefined
      : formatDateYYYYMMDD(new Date())

  const endDateFrom = formik.values.start_date
    ? formik.values.start_date
    : enablePastDays
      ? pastDays
        ? getPastDate(pastDays)
        : undefined
      : formatDateYYYYMMDD(new Date())
  const endDateTo = enableFutureDays
    ? futureDays
      ? getFutureDate(futureDays)
      : undefined
    : formatDateYYYYMMDD(new Date())

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size="sm"
      title="Submit Request"
      buttons={
        configs?.length
          ? [
              {
                btnText: 'Cancel',
                onClick: onClose,
                variant: BUTTON_V2_VARIANT.TERTIARY,
              },
              {
                btnText: isCreatingRequest ? 'Submitting' : 'Submit Request',
                onClick: formik.handleSubmit,
                variant: BUTTON_V2_VARIANT.PRIMARY,
                disabled: isCreatingRequest,
              },
            ]
          : []
      }
    >
      {fetchingConfigs && <Loader />}
      {isErrorConfigs && <EmptyState title="Something went wrong" icon={ICONS.alertCircle} />}
      <div className={classes.formContainer}>
        {balance && <div className={classes.balance}>{balance}</div>}
        {configIsSuccess &&
          (!!configs?.length ? (
            <div className={classes.container}>
              <InputContainer>
                <InputLabel required>Select Leave Type </InputLabel>
                <Select
                  options={leaveTypeOptions}
                  onChange={val => {
                    formik.setFieldValue('start_date', null)
                    formik.setFieldValue('end_date', null)
                    formik.setFieldValue('start_away', '')
                    formik.setFieldValue('end_away', '')
                    formik.setFieldValue('leave_type_id', val)
                    const selected = configs.find(config => {
                      return config.id === val
                    })
                    setSelectedLeaveConfig(selected as LeaveTypeConfigResponse)
                  }}
                  placeholder="Select leave type"
                  errorMsg={formik.touched.leave_type_id && formik.errors.leave_type_id}
                />
              </InputContainer>
              {selectedLeaveConfig && (
                <>
                  <InputContainer>
                    <InputLabel required>From</InputLabel>
                    <HuiDatePicker
                      mode="single"
                      onChange={value => {
                        // if (formik.values.end_date && value) {
                        // const sameDay = isSameDay(formatDateYYYYMMDD(new Date(value)), formik.values.end_date)
                        //   if (sameDay) {
                        //     if (formik.values.start_away) {
                        //       switch (formik.values.start_away) {
                        //         case LEAVE_AWAY_TYPE.ALL_DAY: {
                        //           formik.setFieldValue('end_away', LEAVE_AWAY_TYPE.ALL_DAY)
                        //           const awayOptions = getLeaveAwayOptions(
                        //             selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //           )
                        //           setEndAwayOption(
                        //             awayOptions.filter(op => op.value === LEAVE_AWAY_TYPE.ALL_DAY),
                        //           )
                        //           break
                        //         }
                        //         case LEAVE_AWAY_TYPE.FIRST_HALF: {
                        //           formik.setFieldValue('end_away', '')
                        //           const awayOptions = getLeaveAwayOptions(
                        //             selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //           )
                        //           setEndAwayOption(
                        //             awayOptions.filter(op => op.value !== LEAVE_AWAY_TYPE.ALL_DAY),
                        //           )
                        //           break
                        //         }
                        //         case LEAVE_AWAY_TYPE.SECOND_HALF: {
                        //           formik.setFieldValue('end_away', LEAVE_AWAY_TYPE.SECOND_HALF)
                        //           const awayOptions = getLeaveAwayOptions(
                        //             selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //           )
                        //           setEndAwayOption(
                        //             awayOptions.filter(
                        //               op => op.value === LEAVE_AWAY_TYPE.SECOND_HALF,
                        //             ),
                        //           )
                        //           break
                        //         }

                        //         default: {
                        //           const awayOptions = getLeaveAwayOptions(
                        //             selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //           )
                        //           setEndAwayOption(awayOptions)
                        //           break
                        //         }
                        //       }
                        //     }
                        //   } else {
                        //     const awayOptions = getLeaveAwayOptions(
                        //       selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //     )
                        //     setEndAwayOption(awayOptions)
                        //   }
                        // } else {
                        //   formik.setFieldValue('end_away', '')
                        //   const awayOptions = getLeaveAwayOptions(
                        //     selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //   )
                        //   setEndAwayOption(awayOptions)
                        // }
                        if (value) {
                          formik.setFieldValue('start_date', formatDateYYYYMMDD(new Date(value)))
                        } else {
                          formik.setFieldValue('start_date', null)
                        }
                      }}
                      value={formik.values.start_date || undefined}
                      variant="form"
                      customContainerStyles={{width: '100%'}}
                      disabled={
                        {
                          before: startDateFrom,
                          after: startDateTo,
                        } as Matcher | Matcher[] | undefined
                      }
                      errorMsg={startDateError}
                    />
                  </InputContainer>
                  <InputContainer>
                    <InputLabel required>To</InputLabel>
                    <HuiDatePicker
                      mode="single"
                      onChange={value => {
                        // if (formik.values.start_date && value) {
                        // const sameDay = isSameDay(formatDateYYYYMMDD(new Date(value)), formik.values.start_date)
                        //   if (sameDay) {
                        //     if (formik.values.start_away) {
                        //       switch (formik.values.start_away) {
                        //         case LEAVE_AWAY_TYPE.ALL_DAY: {
                        //           formik.setFieldValue('end_away', LEAVE_AWAY_TYPE.ALL_DAY)
                        //           const awayOptions = getLeaveAwayOptions(
                        //             selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //           )
                        //           setEndAwayOption(
                        //             awayOptions.filter(op => op.value === LEAVE_AWAY_TYPE.ALL_DAY),
                        //           )
                        //           break
                        //         }
                        //         case LEAVE_AWAY_TYPE.FIRST_HALF: {
                        //           formik.setFieldValue('end_away', '')
                        //           const awayOptions = getLeaveAwayOptions(
                        //             selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //           )
                        //           setEndAwayOption(
                        //             awayOptions.filter(op => op.value !== LEAVE_AWAY_TYPE.ALL_DAY),
                        //           )
                        //           break
                        //         }
                        //         case LEAVE_AWAY_TYPE.SECOND_HALF: {
                        //           formik.setFieldValue('end_away', LEAVE_AWAY_TYPE.SECOND_HALF)
                        //           const awayOptions = getLeaveAwayOptions(
                        //             selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //           )
                        //           setEndAwayOption(
                        //             awayOptions.filter(
                        //               op => op.value === LEAVE_AWAY_TYPE.SECOND_HALF,
                        //             ),
                        //           )
                        //           break
                        //         }

                        //         default: {
                        //           const awayOptions = getLeaveAwayOptions(
                        //             selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //           )
                        //           setEndAwayOption(awayOptions)
                        //           break
                        //         }
                        //       }
                        //     }
                        //   } else {
                        //     const awayOptions = getLeaveAwayOptions(
                        //       selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //     )
                        //     setEndAwayOption(awayOptions)
                        //   }
                        // } else {
                        //   formik.setFieldValue('end_away', '')
                        //   const awayOptions = getLeaveAwayOptions(
                        //     selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //   )
                        //   setEndAwayOption(awayOptions)
                        // }
                        if (value) {
                          formik.setFieldValue('end_date', formatDateYYYYMMDD(new Date(value)))
                        } else {
                          formik.setFieldValue('end_date', null)
                        }
                      }}
                      value={formik.values.end_date || undefined}
                      variant="form"
                      customContainerStyles={{width: '100%'}}
                      disabled={
                        {
                          before: endDateFrom,
                          after: endDateTo,
                        } as Matcher | Matcher[] | undefined
                      }
                      errorMsg={endDateError}
                    />
                  </InputContainer>
                  <InputContainer>
                    <InputLabel required>Starting</InputLabel>
                    <Select
                      options={startAwayOption}
                      onChange={val => {
                        // if (formik.values.start_date && formik.values.end_date) {
                        //   const sameDay = isSameDay(
                        //     formik.values.start_date,
                        //     formik.values.end_date,
                        //   )
                        //   if (sameDay) {
                        //     switch (val) {
                        //       case LEAVE_AWAY_TYPE.ALL_DAY: {
                        //         formik.setFieldValue('end_away', LEAVE_AWAY_TYPE.ALL_DAY)
                        //         const awayOptions = getLeaveAwayOptions(
                        //           selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //         )
                        //         setEndAwayOption(
                        //           awayOptions.filter(op => op.value === LEAVE_AWAY_TYPE.ALL_DAY),
                        //         )
                        //         break
                        //       }
                        //       case LEAVE_AWAY_TYPE.FIRST_HALF: {
                        //         formik.setFieldValue('end_away', '')
                        //         const awayOptions = getLeaveAwayOptions(
                        //           selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //         )
                        //         setEndAwayOption(
                        //           awayOptions.filter(op => op.value !== LEAVE_AWAY_TYPE.ALL_DAY),
                        //         )
                        //         break
                        //       }
                        //       case LEAVE_AWAY_TYPE.SECOND_HALF: {
                        //         formik.setFieldValue('end_away', LEAVE_AWAY_TYPE.SECOND_HALF)
                        //         const awayOptions = getLeaveAwayOptions(
                        //           selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //         )
                        //         setEndAwayOption(
                        //           awayOptions.filter(
                        //             op => op.value === LEAVE_AWAY_TYPE.SECOND_HALF,
                        //           ),
                        //         )
                        //         break
                        //       }
                        //       default: {
                        //         const awayOptions = getLeaveAwayOptions(
                        //           selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //         )
                        //         setEndAwayOption(awayOptions)
                        //         break
                        //       }
                        //     }
                        //   } else {
                        //     const awayOptions = getLeaveAwayOptions(
                        //       selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //     )
                        //     setEndAwayOption(awayOptions)
                        //   }
                        // } else {
                        //   const awayOptions = getLeaveAwayOptions(
                        //     selectedLeaveConfig ? selectedLeaveConfig?.half_day : true,
                        //   )
                        //   setEndAwayOption(awayOptions)
                        // }
                        formik.setFieldValue('start_away', val)
                      }}
                      customValue={
                        startAwayOption.find(op => op.value === formik.values.start_away) || null
                      }
                      placeholder="Starting from"
                      errorMsg={formik.touched.start_away && formik.errors.start_away}
                    />
                  </InputContainer>
                  <InputContainer>
                    <InputLabel required>Ending</InputLabel>
                    <Select
                      options={endAwayOption}
                      onChange={val => {
                        formik.setFieldValue('end_away', val)
                      }}
                      customValue={
                        endAwayOption.find(op => op.value === formik.values.end_away) || null
                      }
                      placeholder="Ending on"
                      errorMsg={formik.touched.end_away && formik.errors.end_away}
                    />
                  </InputContainer>
                  <InputContainer>
                    <InputLabel required={isDocumentRequired}>Upload</InputLabel>
                    <InputGroup>
                      <HUIUpload
                        getUploadDoc={getUploadDoc}
                        type={DOCS_TYPE.LEAVE_DOCS}
                        fileUploadLimit={1}
                        showFileList
                        error={docError}
                        fileContainerClassName={classes.fileList}
                      />
                    </InputGroup>
                  </InputContainer>
                  <InputContainer>
                    <InputLabel required={selectedLeaveConfig?.note}>Note</InputLabel>
                    <Input
                      {...formik.getFieldProps('note')}
                      placeholder="Enter note"
                      errorMsg={formik.touched.note && formik.errors.note}
                    />
                  </InputContainer>
                </>
              )}
            </div>
          ) : (
            <EmptyState
              title="No leave type found"
              desc="There are no leave types to submit leave request."
              icon={ICONS.calendarGrid}
            />
          ))}
      </div>
    </Drawer>
  )
}
