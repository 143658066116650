import * as React from 'react'
import NoUserFound from 'app/modules/auth/pages/login/components/NoUserFound'
import classes from './styles.module.css'
import clsx from 'clsx'
import {toast, Id, ToastOptions} from 'react-toastify'
import {SVG} from '@hybr1d-tech/charizard'
import {ICONS} from 'app/utils/constants/icon'
import type {ToastCloseButtonProps} from 'types/toast'

type ToastType = ({msg, options}: {msg: string | React.ReactElement; options?: ToastOptions}) => Id

export const toastSuccess: ToastType = ({msg, options}) =>
  toast.success(msg, {
    position: 'top-right',
    autoClose: 1800,
    ...options,
    icon: (
      <SVG
        path={ICONS.checkedCircleOutlined}
        svgClassName={clsx(classes.icon, classes.success)}
        spanClassName={classes.iconSpan}
      />
    ),
  })

export const toastError: ToastType = ({msg, options}) =>
  toast.error(msg, {
    position: 'top-right',
    autoClose: 1800,
    ...options,
    icon: (
      <SVG
        path={ICONS.alertCircle}
        svgClassName={clsx(classes.icon, classes.error)}
        spanClassName={classes.iconSpan}
      />
    ),
  })

export const toastInfo: ToastType = ({msg, options}) =>
  toast.info(msg, {
    position: 'top-right',
    autoClose: 1800,
    ...options,
    icon: (
      <SVG
        path={ICONS.infoCircle}
        svgClassName={clsx(classes.icon, classes.info)}
        spanClassName={classes.iconSpan}
      />
    ),
  })

export const toastWarning: ToastType = ({msg, options}) =>
  toast.warning(msg, {
    position: 'top-right',
    autoClose: 1800,
    ...options,
    icon: (
      <SVG
        path={ICONS.alertTriangle}
        svgClassName={clsx(classes.icon, classes.warning)}
        spanClassName={classes.iconSpan}
      />
    ),
  })

export const noUserFoundToast = () =>
  toast.error(<NoUserFound />, {
    position: 'top-right',
    autoClose: 1800,
  })

export function ToastCloseButton({closeToast}: ToastCloseButtonProps) {
  return (
    <button className={classes.closeToastBtn} onClick={closeToast}>
      <SVG
        path={ICONS.close}
        svgClassName={classes.closeIcon}
        spanClassName={classes.closeIconSpan}
      />
    </button>
  )
}
