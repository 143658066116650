import {
  APP_STORE,
  ATTENDANCE_ROOT,
  // AWAY_ROOT,
  CHECKLIST,
  DASHBOARD_ROOT,
  DOCUMENT,
  INVENTORY_ROOT,
  IT_SUPPORT,
  LEAVE_ROOT,
  MDM_ROOT,
  ORDERS,
  ORG_CHART,
  PRODUCTS_ROOT,
  REPORTS,
  SETTINGS_ROOT,
  SOFTWARE_TRACKING_ROOT,
  TASKS_ROOT,
  TEAM_MEMBERS,
  TEAM_ROOT,
  WORKFLOWS_ROOT,
} from './getters'

export const privateRoutes = {
  DASHBOARD: DASHBOARD_ROOT,
  TEAM: TEAM_ROOT,
  TEAM_MEMBERS: TEAM_MEMBERS,
  ORG_CHART: ORG_CHART,
  ORDERS: ORDERS,
  PRODUCTS: PRODUCTS_ROOT,
  INVENTORY: INVENTORY_ROOT,
  SETTINGS: SETTINGS_ROOT,
  REPORTS: REPORTS,
  APP_STORE: APP_STORE,
  // AWAY: AWAY_ROOT,
  // LEAVE: AWAY_ROOT,
  TASK: TASKS_ROOT,
  CHECKLIST: CHECKLIST,
  IT_SUPPORT: IT_SUPPORT,
  WORKFLOWS: WORKFLOWS_ROOT,
  SOFTWARE_TRACKING: SOFTWARE_TRACKING_ROOT,
  MDM: MDM_ROOT,
  ONBOARDING: '/onboarding',
  BILLING: '/billing',
  DOCUMENT: DOCUMENT,
  ATTENDANCE: ATTENDANCE_ROOT,
  LEAVE: LEAVE_ROOT,
}

// used in settings/manage-permissions
export const accessDetails = [
  {module: 'Dashboard', route: privateRoutes.DASHBOARD},
  {module: 'Team', route: privateRoutes.TEAM},
  {module: 'Orders', route: privateRoutes.ORDERS},
  {module: 'Webstore', route: privateRoutes.PRODUCTS},
  {module: 'Inventory', route: privateRoutes.INVENTORY},
  {module: 'Insights', route: privateRoutes.DASHBOARD},
  {module: 'Reports', route: privateRoutes.REPORTS},
  {module: 'Settings', route: privateRoutes.SETTINGS},
  // {module: 'Leave', route: privateRoutes.AWAY},
  {module: 'Task', route: privateRoutes.TASK},
  {module: 'Checklist', route: privateRoutes.CHECKLIST},
  {module: 'It Support', route: privateRoutes.IT_SUPPORT},
  {module: 'Workflows', route: privateRoutes.WORKFLOWS},
  {module: 'App store', route: privateRoutes.APP_STORE},
  {module: 'Software Tracking', route: privateRoutes.SOFTWARE_TRACKING},
  {module: 'MDM', route: privateRoutes.MDM},
  {module: 'Documents', route: privateRoutes.DOCUMENT},
  // {module: 'Attendance', route: privateRoutes.ATTENDANCE},
  {module: 'Leave Management', route: privateRoutes.LEAVE},
]

export const ownerPrivateRoute = [
  privateRoutes.DASHBOARD,
  privateRoutes.TEAM,
  privateRoutes.ORDERS,
  privateRoutes.PRODUCTS,
  privateRoutes.INVENTORY,
  privateRoutes.SETTINGS,
  privateRoutes.REPORTS,
  privateRoutes.BILLING,
  privateRoutes.APP_STORE,
  // privateRoutes.AWAY,
  privateRoutes.TASK,
  privateRoutes.CHECKLIST,
  privateRoutes.IT_SUPPORT,
  privateRoutes.WORKFLOWS,
  privateRoutes.SOFTWARE_TRACKING,
  privateRoutes.MDM,
  privateRoutes.DOCUMENT,
  privateRoutes.ATTENDANCE,
  privateRoutes.LEAVE,
]

export const adminPrivateRoute = [
  privateRoutes.DASHBOARD,
  privateRoutes.TEAM,
  privateRoutes.ORDERS,
  privateRoutes.PRODUCTS,
  privateRoutes.INVENTORY,
  privateRoutes.BILLING,
  privateRoutes.APP_STORE,
  // privateRoutes.AWAY,
  privateRoutes.TASK,
  privateRoutes.CHECKLIST,
  privateRoutes.IT_SUPPORT,
  privateRoutes.WORKFLOWS,
  privateRoutes.SOFTWARE_TRACKING,
  privateRoutes.MDM,
  privateRoutes.ATTENDANCE,
  privateRoutes.LEAVE,
]

export const financePrivateRoute = [
  privateRoutes.DASHBOARD,
  privateRoutes.TEAM,
  privateRoutes.ORDERS,
  privateRoutes.DASHBOARD,
  privateRoutes.REPORTS,
  privateRoutes.BILLING,
  // privateRoutes.AWAY,
  privateRoutes.TASK,
  privateRoutes.CHECKLIST,
  privateRoutes.WORKFLOWS,
  privateRoutes.SOFTWARE_TRACKING,
  privateRoutes.INVENTORY,
  privateRoutes.MDM,
  privateRoutes.LEAVE,
]

export const userPrivateRoute = [
  privateRoutes.TEAM_MEMBERS,
  privateRoutes.ORG_CHART,
  privateRoutes.LEAVE,
  privateRoutes.TASK,
  privateRoutes.CHECKLIST,
]

export const itPrivateRoute = [
  privateRoutes.DASHBOARD,
  privateRoutes.TEAM,
  privateRoutes.INVENTORY,
  // privateRoutes.AWAY,
  privateRoutes.TASK,
  privateRoutes.CHECKLIST,
  privateRoutes.IT_SUPPORT,
  privateRoutes.WORKFLOWS,
  privateRoutes.SOFTWARE_TRACKING,
  privateRoutes.PRODUCTS,
  privateRoutes.ORDERS,
  privateRoutes.APP_STORE,
  privateRoutes.MDM,
  privateRoutes.LEAVE,
]

export enum USER_ROLE {
  user = 'user',
  admin = 'admin',
  finance = 'finance',
  owner = 'owner',
  it = 'it',
}

export const getRoleBasedPrivateRoutes = {
  [USER_ROLE.admin]: adminPrivateRoute,
  [USER_ROLE.finance]: financePrivateRoute,
  [USER_ROLE.user]: userPrivateRoute,
  [USER_ROLE.it]: itPrivateRoute,
  [USER_ROLE.owner]: ownerPrivateRoute,
}

export const hasAccessToRoute = (role, route) => {
  const allRoutes = getRoleBasedPrivateRoutes[role]
  let flag = false
  allRoutes.forEach(accessRoute => {
    if (route.includes(accessRoute)) {
      flag = true
    }
  })
  return flag
}

export function getRoleBasedRouteAccess(role, navigate, route, is_show_tutorial) {
  if (
    is_show_tutorial &&
    route.includes(privateRoutes.ONBOARDING) &&
    (role === USER_ROLE.admin || role === USER_ROLE.owner)
  ) {
    return true
  } else if (
    is_show_tutorial &&
    !route.includes(privateRoutes.ONBOARDING) &&
    (role === USER_ROLE.admin || role === USER_ROLE.owner)
  ) {
    navigate(privateRoutes.ONBOARDING)
  } else if (role === USER_ROLE.owner) {
    return true
  } else if (hasAccessToRoute(role, route)) {
    return true
  } else {
    navigate(TEAM_MEMBERS)
  }
}

export function getModuleBasedRouteAccess(hasAccess, navigate) {
  if (hasAccess) {
    return true
  } else {
    navigate(TEAM_MEMBERS)
  }
}
