import classes from './styles.module.css'
import {authLinks} from 'app/modules/auth/utils/constants'

export default function NoUserFound() {
  return (
    <div>
      {authLinks.bookDemo.nonLinkText}
      <a href={authLinks.bookDemo.link} target="_blank" rel="noreferrer" className={classes.link}>
        {authLinks.bookDemo.name}
      </a>{' '}
    </div>
  )
}
