import * as React from 'react'
import * as menu from '@zag-js/menu'
import clsx from 'clsx'
import NotificationPreferencesModal from '../../notifications/NotificationPreferencesModal'
import classes from './styles.module.css'
import {useMachine, normalizeProps} from '@zag-js/react'
import {SVG} from '@hybr1d-tech/charizard'
// import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'
import {useLogout} from 'app/store/auth'
import {ICONS} from 'app/utils/constants/icon'
import {profileMenuItems} from '../ProfileBox'
import {useSidebarMinimized} from 'app/store/layout'

export enum PROFILE_MENU_ITEMS {
  SETTINGS = 'settings',
  NOTIFICATION = 'notification',
  CHANGE_PASSWORD = 'change-password',
  SIGN_OUT = 'sign-out',
}

export default function ProfileDropdown({isOpen, onOpen, onClose}) {
  const logout = useLogout()
  const isMini = useSidebarMinimized()

  const [state, send] = useMachine(
    menu.machine({
      id: React.useId(),
      'aria-label': 'Profile Menu',
      onSelect: ({value}) => {
        switch (value) {
          case PROFILE_MENU_ITEMS.NOTIFICATION:
            onOpen()
            break
          case PROFILE_MENU_ITEMS.SIGN_OUT:
            logout()
            break
        }
      },
      positioning: {placement: 'top-end'},
    }),
  )

  const menuApi = menu.connect(state, send, normalizeProps)

  return (
    <div className={classes.box} onClick={e => e.stopPropagation()} style={{position: 'relative'}}>
      {!isMini && (
        <>
          <button {...menuApi.getTriggerProps()} className={clsx(classes.trigger, 'hui-reset-btn')}>
            <SVG path={ICONS.threeDots} svgClassName={classes.logo1} />
          </button>
          {menuApi.open && (
            <div {...menuApi.getPositionerProps()} className={classes.menus}>
              <ul {...menuApi.getContentProps()}>
                {profileMenuItems.map(item => (
                  <li
                    key={item.id}
                    {...menuApi.getItemProps({value: item.id})}
                    className={classes.menu}
                  >
                    <div className={classes.iconBox}>
                      <SVG path={item.icon} svgClassName={classes.logo} />
                      <SVG path={item.iconActive} svgClassName={classes.logoActive} />
                    </div>
                    <span className={classes.title}>{item.label}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
      {isOpen && <NotificationPreferencesModal isOpen={isOpen} onClose={onClose} />}
    </div>
  )
}
