import clsx from 'clsx'
import classes from '../../styles.module.css'

function OwnerAdminOnboarding({
  data,
  updateStep,
  currentStep,
  totalStep,
  component,
  handleSkipOrFinishTutorial,
}) {
  return (
    <section className={classes.container}>
      <div className={classes.subContainer}>
        <div className={classes.descriptionContainer}>
          <div>
            <h1 className={classes.header}> {data?.header}</h1>
            <div className={classes.description}> {data?.description}</div>
          </div>
          <div className={classes.subDescriptionContainer}>
            {data?.feature?.map(details => (
              <div className={classes.subDescription} key={details.header}>
                <div className="d-flex align-items-center">
                  <img src="/media/icons/onboarding/description-arrow.svg" alt="description" />
                  <h6 className={classes.subHeader}> {details.header}</h6>
                </div>
                <div style={{paddingLeft: '1.5rem'}} className={classes.description}>
                  {details.description}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.footer}>
          <img
            onClick={() => updateStep(false)}
            className={clsx(classes.stepChangeBtn, {[classes.disabled]: currentStep === 1})}
            src="/media/icons/onboarding/backward-arrow.svg"
            alt="change-step"
          />
          <div className={classes.currentStep}>{`${currentStep}/${totalStep}`}</div>
          {currentStep === totalStep ? (
            <div onClick={handleSkipOrFinishTutorial} className={classes.finishBtn}>
              Get started
            </div>
          ) : (
            <img
              onClick={() => updateStep(true)}
              className={clsx(classes.stepChangeBtn, {
                [classes.disabled]: currentStep === totalStep,
              })}
              src="/media/icons/onboarding/forward-arrow.svg"
              alt="change-step"
            />
          )}
        </div>
      </div>
      <div className={classes.imageContainer}>
        <div onClick={handleSkipOrFinishTutorial} className={classes.skipBtn}>
          Skip the product tour
        </div>
        {data?.showCustomComponent ? (
          component
        ) : (
          <img src={data?.image} alt="description" className={classes.descriptionImage} />
        )}
      </div>
    </section>
  )
}

export default OwnerAdminOnboarding
