import {Route, Link, Routes, Outlet} from 'react-router-dom'
import {Error500} from './components/Error500'
import {Error404} from './components/Error404'
import {toAbsoluteUrl} from 'hybrid-ui/helpers'
import {DASHBOARD_SUMMARY} from 'app/routing/getters'

const ErrorsLayout = () => {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`}}
      >
        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20">
          <a href={DASHBOARD_SUMMARY} className="mb-10 pt-lg-20">
            <div className="d-flex align-items-center justify-content-center">
              <img alt="Logo" src={toAbsoluteUrl('/assets/logo-full.svg')} className="h-80px" />
            </div>
          </a>
          <div className="pt-lg-10 mb-10">
            <Outlet />
            <div className="text-center">
              <Link to="/" className="btn btn-lg btn-primary fw-bolder">
                Go to homepage
              </Link>
            </div>
          </div>
          <div
            className="
          d-flex
          flex-row-auto
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-bottom
          min-h-100px min-h-lg-350px
        "
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}
          ></div>
        </div>

        <div className="d-flex flex-center flex-column-auto p-10">
          <div className="d-flex align-items-center fw-bold fs-6">
            <a href="https://www.zenadmin.ai/" className="text-muted text-hover-primary px-2">
              About
            </a>
            <a
              href="https://www.zenadmin.ai/contact-us"
              className="text-muted text-hover-primary px-2"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const ErrorsPage = ({isOnly500}: {isOnly500?: boolean}) =>
  isOnly500 ? (
    <Routes>
      <Route element={<ErrorsLayout />}>
        <Route index element={<Error500 />} />
      </Route>
    </Routes>
  ) : (
    <Routes>
      <Route element={<ErrorsLayout />}>
        <Route path="404" element={<Error404 />} />
        <Route path="500" element={<Error500 />} />
        <Route index element={<Error404 />} />
      </Route>
    </Routes>
  )

export {ErrorsPage}
