export enum MEMBER_DETAILS_NAV {
  PROFILE = '/profile',
  IDENTIFICATION = '/identification',
  EMPLOYMENT = '/employment',
  DOCUMENTS = '/documents',
  SOFTWARE = '/software',
  EMERGENCY_CONTACT = '/emergency-contact',
  EQUIPMENT = '/equipment',
  MANAGE_TIME_AWAY = '/manage-time-away',
  TIMESHEET = '/timesheet',
  OFFBOARDING_DETAILS = '/offboarding-details',
  OFFBOARDING = '/offboard',
  DEALLOCATE = '/deallocate',
}

export enum EDIT_PROFILE_TYPE {
  PERSONAL = 'personal',
  ADDRESS = 'address',
  BANKING = 'banking',
  SOCIAL = 'social',
  HEALTH = 'health',
}

export enum EDIT_IDENTIFICATION_TYPE {
  ALL = 'ALL',
  VERIFY = 'VERIFY',
  VERIFIED = 'VERIFIED',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}
export enum EDIT_DOCUMENT_TYPE {
  ALL = 'ALL',
  PERSONAL = 'PERSONAL',
  COMPANY = 'COMPANY',
}

//TODO to changes these
// DETAILS = 'employment_details',
// 	ROLE = 'role',
// 	COMPENSATION = 'compensation',
// 	DIRECT_REPORTS = 'direct_reports',
export enum EDIT_EMPLOYMENT_TYPE {
  PERSONAL = 'employment_details',
  ROLE = 'Role',
  COMPENSATION = 'Compensation',
  DIRECT_REPORTS = 'Direct Reports',
  SEE_COMPENSATION = 'See compensation',
  SHOW_COMPENSATION_HISTORY = 'Show compensation history',
  EDIT_COMPENSATION = 'Edit compensation',
  DELETE_COMPENSATION = 'Delete compensation',
}

export enum EDIT_OFFBOARDED_TYPE {
  OFFBOARDING = 'offboarding',
  DETAILS = 'Offboarding schedule',
  ASSETS = 'Assets',
  DELETE_PROFILE = 'delete-profile',
}

export enum EDIT_CONTACT_TYPE {
  PRIMARY_CONTACT = 'primary_contact',
  SECONDARY_CONTACT = 'secondary_contact',
}

export const TEAM_DETAILS_QUERIES = {
  EMPLOYEE_DETAIL: 'employee_details',
  ROLE: 'employee_role_details',
  ROLE_HISTORY: 'role_history',
}

export enum EDIT_EQUIPMENT_TYPE {
  DEALLOCATE_EQUIPMENT = 'deallocate-equipment',
  EDIT_EQUIPMENT = 'edit-equipment',
}

export enum EDIT_EMERGENCY_CONTACT_TYPE {
  PRIMARY = 'primary_contact',
  SECONDARY = 'secondary_contact',
}

export enum EDIT_MANAGE_TIME_AWAY_TYPE {
  ADD_PTO = 'add-pto',
  CHANGE_POLICY_ALLOCATION = 'change-policy-allocation',
  EDIT_UPCOMING_LEAVE = 'edit-upcoming-leave',
  DELETE_UPCOMING_LEAVE = 'delete-upcoming-leave',
  EDIT_PERIOD_POLICY = 'edit-period-policy',
  DELETE_PERIOD_POLICY = 'delete-period-policy',
  EDIT_ADJUSTMENT = 'edit-adjustment',
  DELETE_ADJUSTMENT = 'delete-adjustment',
  BOOK_TIME_AWAY = 'book-time-away',
  LEAVE_HISTORY_BREAKDOWN = 'leave-history-breakdown',
  TIME_AWAY_HISTORY = 'time-away-history',
  SHOW_DROPDOWN_FOR_LEAVE_EDIT = 'show-dropdown-for-leave-edit',
  SHOW_DROPDOWN_FOR_PERIOD_EDIT = 'show-dropdown-for-period-edit',
  SHOW_DROPDOWN_FOR_ADJUSTMENT_EDIT = 'show-dropdown-for-adjustment-edit',
}

export enum ACCESS_TYPE {
  COMPLETE_ACCESS = 'COMPLETE_ACCESS',
  VIEW = 'VIEW',
  NO_ACCESS = 'NO_ACCESS',
}

export enum TEAM_ROLE_TYPE {
  OWNER = 'owner',
  ADMIN = 'admin',
  FINANCE = 'finance',
  USER = 'user',
  OWNER_SELF = 'OWNER_SELF',
  ADMIN_SELF = 'ADMIN_SELF',
  FINANCE_SELF = 'FINANCE_SELF',
  USER_SELF = 'USER_SELF',
  IT = 'it',
  IT_SELF = 'IT_SELF',
}

export enum EMPLOYEE_CONTRACT_TYPE {
  FULL_TIME = 'Full Time',
  PART_TIME = 'Part Time',
  INTERNSHIP = 'Internship',
}

export enum DOCS_TYPE {
  USER_DOCS = 'user_document',
  COMPANY_DOCS = 'company_document',
  IDENTIFICATION_DOC = 'identification_document',
  COMMENT_DOCS = 'comment_document',
  ORDER_DOC = 'order_document',
  SOFTWARE_DOCS = 'software_document',
  SOFTWARE_LOGO = 'software_logo',
  INVENTORY_DOCS = 'inventory_document',
  INVENTORY_DOCS_EQUIPMENT = 'equipment',
  INVENTORY_DOCS_FINANCE = 'finance',
  USER_OFFBOARDING_DOCS = 'user_offboarding_document',
  LEAVE_DOCS = 'leave_document',
  INTEGRATION_DOCS = 'integration_document',
}

export type TEAM_SECTIONS =
  | EDIT_EQUIPMENT_TYPE
  | TEAM_ROLE_TYPE
  | EDIT_DOCUMENT_TYPE
  | EDIT_CONTACT_TYPE
  | EDIT_EMERGENCY_CONTACT_TYPE
  | EDIT_EMPLOYMENT_TYPE
  | EDIT_OFFBOARDED_TYPE
  | EDIT_IDENTIFICATION_TYPE
  | EDIT_PROFILE_TYPE
  | EDIT_MANAGE_TIME_AWAY_TYPE
