import * as React from 'react'
import CardWrapper from '../CardWrapper'
import HUIModal from 'hybrid-ui/components/hui-modal'
import HUIInputGroup from 'hybrid-ui/components/hui-input-group'
import HUIProfileUpload from 'hybrid-ui/components/hui-upload/HUIProfileUpload'
import HUISpinner from 'hybrid-ui/components/hui-spinner'
import classes from './styles.module.scss'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'
import {
  useDeleteCompanyProfilePhoto,
  useUpdateCompanyDetails,
  useUpdateCompanyProfilePhoto,
} from 'app/services'
import {handleImageUpload} from 'hybrid-ui/components/hui-upload/upload-helper'
import {DOCS_TYPE} from 'app/modules/team/pages/team-member-details/utils'
import {useAuthActions} from 'app/store/auth'
import { BUTTON_V2_VARIANT, ButtonV2 } from '@hybr1d-tech/charizard'

interface CompanyProfileProps {
  companyDetailsResponse: any
}

export default function CompanyProfile({companyDetailsResponse}: CompanyProfileProps) {
  const [companyDetails, setCompanyDetails] = React.useState({name: '', slogan: ''})
  const {requestUser} = useAuthActions()

  const {mutateAsync: updateCompanyDetailsMutate} = useUpdateCompanyDetails(
    ['company-details'],
    'Successfully updated company details',
  )
  const {mutateAsync: updateCompanyProfilePhotoMutate, isPending: isUpdatingCompanyProfilePhoto} =
    useUpdateCompanyProfilePhoto(['company-details'], 'Successfully updated company profile photo')
  const {mutateAsync: deleteCompanyProfilePhotoMutate, isPending: isDeletingCompanyProfilePhoto} =
    useDeleteCompanyProfilePhoto(['company-details'], 'Successfully deleted company profile photo')

  React.useEffect(() => {
    setCompanyDetails({
      name: companyDetailsResponse?.name || '',
      slogan: companyDetailsResponse?.slogan || '',
    })
  }, [companyDetailsResponse])

  const {isOpen, onClose, onOpen} = useDisclosure()
  const {
    isOpen: isEditCompanyProfilePictureOpen,
    onClose: onEditCompanyProfilePictureClose,
    onOpen: onEditCompanyProfilePictureOpen,
  } = useDisclosure()

  const handleCompanyDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyDetails({
      ...companyDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleCompanyDetailsSubmit = async () => {
    await updateCompanyDetailsMutate(companyDetails, {
      onSuccess(data) {
        requestUser()
        onClose()
      },
      onError(err) {
        console.error(err)
      },
    })
  }

  const handleProfileImageChange = async image => {
    try {
      const fileData = await handleImageUpload(image, DOCS_TYPE.USER_DOCS)
      // api call to update user profile image
      const payload = {
        profile_photo: fileData[0].url,
      }
      await updateCompanyProfilePhotoMutate(payload, {
        onSuccess(data) {
          requestUser()
        },
        onError(error, variables, context) {
          console.error(error)
        },
      })
    } catch (err) {
      console.error({err})
    }
  }

  return (
    <>
      <CardWrapper
        title="Company Profile"
        customEditText="Edit profile"
        handleEdit={onOpen}
        customClasses="mt-0"
      >
        <div className="d-flex justify-content-start align-items-center gap-6 mt-3">
          <div onClick={onEditCompanyProfilePictureOpen} className={classes.companyLogo}>
            <HUIProfileUpload
              email={companyDetails.name}
              profileClassName={classes.profileImage}
              showEditOptions={false}
              imageUrl={companyDetailsResponse?.profile_photo || ''}
              onImageChange={handleProfileImageChange}
            />
          </div>

          <div>
            <div className={classes.companyName}>{companyDetailsResponse?.name}</div>
            <div className={classes.companySlogan}>{companyDetailsResponse?.slogan}</div>
          </div>
        </div>
      </CardWrapper>
      <HUIModal
        heading={'Edit Company Profile'}
        isOpen={isOpen}
        onClose={onClose}
        onOk={handleCompanyDetailsSubmit}
        showCancelBtn={false}
        size="lg"
      >
        <p>Add new team by typing and remove existing team by hovering</p>

        <HUIInputGroup customClasses={['mt-10']}>
          <HUIInputGroup.Label htmlFor="name">Company Name</HUIInputGroup.Label>
          <HUIInputGroup.Input
            placeholder="Company Name"
            name="name"
            value={companyDetails.name}
            onChange={handleCompanyDetailsChange}
          />
        </HUIInputGroup>
        <HUIInputGroup customClasses={['mb-0']}>
          <HUIInputGroup.Label htmlFor="slogan">Comany Slogan</HUIInputGroup.Label>
          <HUIInputGroup.Input
            placeholder="Company Slogan"
            name="slogan"
            value={companyDetails.slogan}
            onChange={handleCompanyDetailsChange}
          />
        </HUIInputGroup>
      </HUIModal>
      <HUIModal
        heading={'Update company logo'}
        isOpen={isEditCompanyProfilePictureOpen}
        onClose={onEditCompanyProfilePictureClose}
        onOk={() => {}}
        showFooter={false}
        size="lg"
      >
        <div className={classes.editImageModal}>
          {isDeletingCompanyProfilePhoto || isUpdatingCompanyProfilePhoto ? (
            <HUISpinner overrideStyle={{width: '100%', height: '11rem'}} />
          ) : (
            <HUIProfileUpload
              email={companyDetails.name}
              profileClassName={classes.profileImage}
              showEditOptions={true}
              imageUrl={companyDetailsResponse?.profile_photo || ''}
              onImageChange={handleProfileImageChange}
            />
          )}
          <p
            onClick={() => deleteCompanyProfilePhotoMutate(companyDetailsResponse?.id)}
            className={classes.deleteCompanyLogo}
            style={{
              pointerEvents: companyDetailsResponse?.profile_photo ? 'all' : 'none',

              opacity: companyDetailsResponse?.profile_photo ? 1 : 0.5,
            }}
          >
            <ButtonV2 variant={BUTTON_V2_VARIANT.LINK}>Delete company logo</ButtonV2>
          </p>
        </div>
      </HUIModal>
    </>
  )
}
