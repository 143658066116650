import {useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {MutateToastOptions, useMutate} from 'app/utils/hooks/useMutate'
import {TaskService} from './task.service'
import type {QueryKey} from '@tanstack/react-query'
import {checklistKeys, taskKeys} from '../query-key-factory'
import {TASK_TOASTS} from 'app/utils/constants/toast'
import {FORM_REQUEST_STATUS} from 'types/form'

const svc = new TaskService()

export const useGetTasks = (queryKey: QueryKey, query: string) => {
  const resp = useQuery({
    queryKey: taskKeys.list(query),
    queryFn: () => svc.getTasks(query),
  })

  const taskLength = resp.data?.meta_data?.total_tasks || 0
  const pendingTask = resp.data?.meta_data?.pending_tasks || 0
  const completedTask = resp.data?.meta_data?.completed_tasks || 0
  return {
    tasks: resp.data?.data,
    fetchingTasks: resp.isLoading,
    taskLength,
    completedTask,
    pendingTask,
    ...resp,
  }
}

export const useGetAssignees = (queryKey: QueryKey, searchTerm: string) => {
  const resp = useQuery({
    queryKey: taskKeys.assignees(searchTerm),
    queryFn: () => svc.getAssignees(searchTerm),
  })
  return {users: resp.data?.data, fetchingUsers: resp.isLoading, ...resp}
}

export const useGetTasksMe = (queryKey: QueryKey, query: string) => {
  const resp = useQuery({
    queryKey: taskKeys.myTasks(query),
    queryFn: () => svc.getMyCreatedTask(query),
  })
  return {createdTasks: resp?.data?.data, fetchingTasksCreated: resp.isLoading, ...resp}
}

export const useGetTaskDetails = (queryKey: QueryKey, id: string, isChecklistTask: boolean) => {
  const resp = useQuery({
    queryKey: taskKeys.detail(id),
    queryFn: () => svc.getTaskDetails(id),
    enabled: !isChecklistTask,
  })
  return {task: resp.data?.data, fetchingTask: resp.isLoading, ...resp}
}

export const useGetLeaveRequests = (queryKey: QueryKey) => {
  const resp = useQuery({
    queryKey: taskKeys.leaveRequest(),
    queryFn: svc.getLeaveRequests,
  })
  const leavesLength = resp.data?.meta_data?.total_items || 0

  return {leaves: resp.data?.data, fetchingLeaves: resp.isLoading, leavesLength, ...resp}
}

export const useGetFormRequests = (query: string) => {
  const resp = useQuery({
    queryKey: taskKeys.formRequests(query),
    queryFn: () => svc.getFormRequests(query),
  })
  const totalFormRequests = resp.data?.meta_data?.total_items || 0

  return {
    formRequests: resp.data?.data,
    fetchingFormRequests: resp.isPending,
    formRequestsSuccessful: resp.isSuccess,
    totalFormRequests,
    ...resp,
  }
}
export const useGetTriggerForms = (query: string) => {
  const resp = useQuery({
    queryKey: taskKeys.triggerForms(query),
    queryFn: () => svc.getTriggerForms(query),
  })
  const totalTriggerForms = resp.data?.meta_data?.total_items || 0

  return {
    triggerForms: resp.data?.data,
    fetchingTriggerForms: resp.isPending,
    triggerFormsSuccessful: resp.isSuccess,
    totalTriggerForms,
    ...resp,
  }
}
export const useGetFormApprovals = (query: string) => {
  const resp = useQuery({
    queryKey: taskKeys.formApprovals(query),
    queryFn: () => svc.getApprovalRequests(query),
  })
  const pendingFormApprovals =
    resp.data?.data.filter(approval => approval.status === FORM_REQUEST_STATUS.PENDING).length || 0

  const totalFormApprovals = resp.data?.data.length || 0

  return {
    formApprovals: resp.data?.data,
    fetchingFormApprovals: resp.isPending,
    formApprovalsSuccessful: resp.isSuccess,
    totalFormApprovals,
    pendingFormApprovals,
    ...resp,
  }
}

export const useUpdateFormRequest = () => {
  const res = useMutate(svc.updateFormRequests, taskKeys.formAllRequests(), TASK_TOASTS.submitForm)
  return {
    updateFormRequest: res.mutateAsync,
    isUpdatingFormRequest: res.isPending,
  }
}
export const useSubmitTriggerForm = () => {
  const res = useMutate(svc.submitTriggerForm, taskKeys.triggerAllForms(), TASK_TOASTS.submitForm)
  return {
    submitTriggerForm: res.mutateAsync,
    isSubmittingTriggerForm: res.isPending,
  }
}
export const useUpdateFormApproval = () => {
  const res = useMutate(
    svc.updateFormApprovals,
    taskKeys.formAllApprovals(),
    TASK_TOASTS.formApproval,
  )
  return {
    updateFormApproval: res.mutateAsync,
    isUpdatingFormApproval: res.isPending,
  }
}

export const useGetTaskComments = (
  queryKey: QueryKey,
  {task_id, type}: {task_id: string; type: 'task' | 'checklist'},
) => {
  const resp = useQuery({
    queryKey: taskKeys.taskComments(task_id),
    queryFn: () => svc.getTaskComments({task_id, type}),
  })
  return {comments: resp.data?.data, fetchingComments: resp.isLoading, ...resp}
}

export const useCreateSection = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.createSection, taskKeys.tasks(), successMsg)
}

export const useCreateTask = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.createTask, taskKeys.tasks(), successMsg)
}

export const useUpdateTask = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
  toastOptions?: MutateToastOptions,
) => {
  return useMutate(svc.updateTask, taskKeys.all, successMsg, toastOptions)
}
export const useCreateSubSection = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.createSubSection, taskKeys.tasks(), successMsg)
}

export const useDeleteTask = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deleteTask, taskKeys.tasks(), successMsg)
}
export const useDeleteSection = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deleteSection, taskKeys.tasks(), successMsg)
}
export const useDeleteSubSection = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deleteSubSection, taskKeys.tasks(), successMsg)
}

export const useUpdateSection = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateSection, taskKeys.tasks(), successMsg)
}

export const useUpdateSubSection = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateSubSection, taskKeys.tasks(), successMsg)
}

export const useCreateTaskComment = (taskId?: string, successMsg?: string) => {
  return useMutate(svc.createTaskComment, taskKeys.detail(taskId), successMsg)
}

export const useEditTaskComment = (taskId?: string, successMsg?: string) => {
  return useMutate(svc.editTaskComment, taskKeys.detail(taskId), successMsg)
}

export const useDeleteTaskComment = (taskId?: string, successMsg?: string) => {
  return useMutate(svc.deleteTaskComment, taskKeys.detail(taskId), successMsg)
}

export const useReactTaskComment = (taskId?: string, successMsg?: string) => {
  return useMutate(svc.reactTaskComment, taskKeys.detail(taskId), successMsg)
}

export const useRemoveTaskCommentReaction = (taskId?: string, successMsg?: string) => {
  return useMutate(svc.removeTaskCommentReaction, taskKeys.detail(taskId), successMsg)
}

/**
 * Checklist APIs
 */
export const useGetChecklists = (queryKey: QueryKey, query?: string) => {
  const resp = useQuery({
    queryKey: checklistKeys.list(),
    queryFn: () => svc.getChecklists(query),
  })
  return {checklists: resp.data?.data, fetchingChecklists: resp.isLoading, ...resp}
}

export const useGetInfiniteChecklists = (key, query, enabled = true) => {
  const response = useInfiniteQuery({
    queryKey: checklistKeys.list(query),
    queryFn: ({pageParam}) => svc.getInfiniteChecklists(query, pageParam),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    enabled,
    initialPageParam: 0,
  })

  const allChecklists = response?.data?.pages?.map(page => page.data).flat()

  return {
    checklists: allChecklists ?? [],
    fetchingChecklists: response.isLoading,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    metadata: response?.data?.pages?.[0]?.meta_data,
    ...response,
  }
}

export const useGetChecklistTasks = (id?: string) => {
  const resp = useQuery({
    queryKey: checklistKeys.checklistTasks(id),
    queryFn: () => svc.getChecklistTasks(id),
  })
  return {sections: resp.data?.data, fetchingChecklistTasks: resp.isLoading, ...resp}
}

export const useGetAssigneeChecklistTasks = (
  queryKey: QueryKey,
  checklistId?: string,
  assigneeId?: string,
) => {
  const resp = useQuery({
    queryKey: checklistKeys.assigneeChecklistTasks(checklistId, assigneeId),
    queryFn: () =>
      svc.getAssigneeChecklistTasks({checklistId: checklistId ?? '', assigneeId: assigneeId ?? ''}),
  })
  return {
    checklistDetails: resp.data?.data,
    fetchingAssigneeChecklistTasks: resp.isLoading,
    ...resp,
  }
}

export const useGetChecklistTaskDetails = (
  queryKey: QueryKey,
  taskId: string,
  isChecklistTask: boolean,
) => {
  const resp = useQuery({
    queryKey: checklistKeys.taskDetails(taskId),
    queryFn: () => svc.getChecklistTaskDetails(taskId),
    enabled: isChecklistTask,
  })
  return {checklistTask: resp.data?.data, fetchingChecklistTask: resp.isLoading, ...resp}
}

export const useGetChecklistRecipes = () => {
  const resp = useQuery({
    queryKey: checklistKeys.recipes(),
    queryFn: svc.getChecklistRecipes,
  })
  return {recipes: resp.data?.data, fetchingRecipes: resp.isLoading, ...resp}
}

export const useGetChecklistDetails = (queryKey: QueryKey, id: string = '') => {
  const resp = useQuery({
    queryKey: checklistKeys.detail(id),
    queryFn: () => svc.getChecklistDetails(id),
  })
  return {checklist: resp.data?.data, fetchingChecklist: resp.isLoading, ...resp}
}

export const useUpdatePersons = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
  toastOptions?: MutateToastOptions,
) => {
  return useMutate(svc.updatePersons, checklistKeys.all, successMsg, toastOptions)
}

export const useUpdateChecklistTask = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
  toastOptions?: MutateToastOptions,
) => {
  return useMutate(svc.updateChecklistTask, checklistKeys.all, successMsg, toastOptions)
}

export const useCreateChecklist = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.createChecklist, checklistKeys.all, successMsg)
}

export const useUpdateChecklist = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateChecklist, checklistKeys.all, successMsg)
}

export const useDeleteChecklist = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deleteChecklist, checklistKeys.all, successMsg)
}
export const useCreateChecklistTask = (checklistId?: string, successMsg?: string) => {
  return useMutate(svc.createChecklistTask, checklistKeys.checklistTasks(checklistId), successMsg)
}

export const useDeleteChecklistTask = (checklistId?: string, successMsg?: string) => {
  return useMutate(svc.deleteChecklistTask, checklistKeys.checklistTasks(checklistId), successMsg)
}

export const useReorderChecklistTasks = (checklistId?: string, successMsg?: string) => {
  return useMutate(svc.reorderChecklistTasks, checklistKeys.checklistTasks(checklistId), successMsg)
}

export const useDuplicateChecklist = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.duplicateChecklist, checklistKeys.all, successMsg)
}

export const useRemoveChecklistAssignee = (checklistId?: string, successMsg?: string) => {
  return useMutate(svc.removeChecklistAssignee, checklistKeys.detail(checklistId), successMsg)
}
