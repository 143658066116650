import classes from './whats-new-btn.module.css'
import iconClasses from '../../header-icon.module.css'
import {SVG} from '@hybr1d-tech/charizard'
import {useGetMetaData, useUpdateMetaData} from 'app/services'
import {utilityKeys} from 'app/services/query-key-factory'
import {ICONS} from 'app/utils/constants/icon'
import clsx from 'clsx'

export default function WhatsNewBtn() {
  const {meta} = useGetMetaData(utilityKeys.metaInfoTasks())
  const {mutateAsync: updateUserMetaInfoMutate} = useUpdateMetaData(utilityKeys.metaInfoTasks())

  const showUpdateNotification = !meta?.dashboard.seen_product_updates
  const updatesUrl = meta?.dashboard.notion_link
  const showWhatsNew = meta?.dashboard.product_updates_visible && updatesUrl

  const handleWhatsNew = () => {
    if (showWhatsNew) {
      if (showUpdateNotification) {
        updateUserMetaInfoMutate({
          seen_product_updates: true,
        })
      }
      window.open(updatesUrl, '_blank')
    }
  }

  return (
    showWhatsNew && (
      <button onClick={handleWhatsNew} className={clsx(classes.whatsNewContainer, 'hui-reset-btn')}>
        <SVG
          path={ICONS.whatsNew}
          spanClassName={iconClasses.headerIconSpan}
          svgClassName={iconClasses.headerIcon}
          customSvgStyles={{width: '20px', height: '21px'}}
        />
        {showUpdateNotification && <div className={classes.notificationsCount} />}
      </button>
    )
  )
}
