import * as React from 'react'
import * as Sentry from '@sentry/react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import {ENV, ZENADMIN_APP_VERSION, SENTRY_DSN} from './config'
import {isDev} from 'app/configs/config'

export const initializeSentry = () => {
  if (isDev) return
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    environment: ENV,
    release: ZENADMIN_APP_VERSION,
    tracesSampleRate: 0.2,
  })
}

export const ErrorBoundary = Sentry.ErrorBoundary
export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
