import SidebarMenuItem from '../sidebar-menu-item'
import SidebarMenuItemSingle from '../sidebar-menu-item-single'
import SidebarMenuItemWithSub from '../sidebar-menu-item-with-sub'
import {getRoleBasedPrivateRoutes, privateRoutes, USER_ROLE} from 'app/routing/authorized-routes'
import {
  APP_STORE,
  ORDERS,
  PRODUCTS,
  REPORTS,
  TASKS,
  IT_SUPPORT,
  CHECKLIST,
  DOCUMENT,
  // AWAY,
  // AWAY_ROOT,
  DOCUMENT_ROOT,
  INVENTORY_ROOT,
  SOFTWARE_TRACKING_ROOT,
  MDM_ROOT,
  TASKS_ROOT,
  WORKFLOWS_ROOT,
  SETTINGS_PERMISSIONS,
  SETTINGS_ROOT,
  TEAM_ROOT,
  WORKFLOWS_HOME_V2,
  DASHBOARD_SUMMARY,
  DASHBOARD_ROOT,
  // ATTENDANCE_POLICIES,
  // ATTENDANCE_ROOT,
  LEAVE_ROOT,
  // LEAVE_POLICIES,
  LEAVE_AWAY,
  TEAM_MEMBERS,
  INVENTORY_HOME,
  SOFTWARE_TRACKING,
  MDM,
} from 'app/routing/getters'
import {ICONS} from 'app/utils/constants/icon'

export const getMenuItem = ({role, modules, isMobile, showV2Route}) => [
  {
    name: privateRoutes.DASHBOARD,
    component: (
      <SidebarMenuItemSingle
        to={DASHBOARD_SUMMARY}
        _key={DASHBOARD_ROOT}
        icon={{default: ICONS.sidebar.dashboard.root, active: ICONS.sidebar.dashboard.rootActive}}
        title={'Dashboard'}
        isMobile={isMobile}
      />
    ),
  },
  {
    name: 'Zen HR',
    menuItems: getMenuItems(modules, role, isMobile).hr,
    component: (
      <SidebarMenuItemWithSub
        to={'/people'}
        title={'Zen HR'}
        icon={{default: ICONS.sidebar.zenHR.root, active: ICONS.sidebar.zenHR.rootActive}}
        isMobile={isMobile}
        menuItems={getMenuItems(modules, role, isMobile, true).hr}
      />
    ),
  },
  {
    name: 'Zen IT',
    menuItems: getMenuItems(modules, role, isMobile).it,
    component: (
      <SidebarMenuItemWithSub
        to={['/devices', '/software']}
        title={'Zen IT'}
        icon={{default: ICONS.sidebar.zenIT.root, active: ICONS.sidebar.zenIT.rootActive}}
        isMobile={isMobile}
        menuItems={getMenuItems(modules, role, isMobile, true).it}
      />
    ),
  },
  {
    name: 'Zen Tools',
    menuItems: getMenuItems(modules, role, isMobile).tools,
    component: (
      <SidebarMenuItemWithSub
        to={'/tools'}
        title={'Zen Tools'}
        icon={{
          default: ICONS.sidebar.zenTools.root,
          active: ICONS.sidebar.zenTools.rootActive,
        }}
        isMobile={isMobile}
        menuItems={getMenuItems(modules, role, isMobile, true).tools}
      />
    ),
  },
  {
    name: privateRoutes.APP_STORE,
    component: (
      <SidebarMenuItemSingle
        to={APP_STORE}
        _key={APP_STORE}
        icon={{default: ICONS.sidebar.appStore.root, active: ICONS.sidebar.appStore.rootActive}}
        title={'App Marketplace'}
        isMobile={isMobile}
      />
    ),
  },
  {
    name: privateRoutes.SETTINGS,
    component: (
      <SidebarMenuItemSingle
        to={SETTINGS_PERMISSIONS}
        _key={SETTINGS_ROOT}
        icon={{default: ICONS.sidebar.settings.root, active: ICONS.sidebar.settings.rootActive}}
        title={'Settings'}
        isMobile={isMobile}
      />
    ),
  },
]

export const getRoleBasedMenuItem = (role, isMobile, modules, showV2Route) => {
  if (role === 'owner') return getMenuItem({role, isMobile, modules, showV2Route})
  const routes = getRoleBasedPrivateRoutes[role] || getRoleBasedPrivateRoutes[USER_ROLE.user]
  // check to filter at a sub item level
  return getMenuItem({role, isMobile, modules, showV2Route}).filter(item => {
    if (!item?.name?.includes('/')) {
      if (!item.menuItems?.length) {
        return false
      } else {
        return true
      }
    } else {
      return routes.includes(item?.name)
    }
  })
}

// used to filter the menuItems for Sidebar menu with sub items based on access role
const filterRoleBasedMenuItem = (role, isMobile, items) => {
  // return items
  const routes = getRoleBasedPrivateRoutes[role] || getRoleBasedPrivateRoutes[USER_ROLE.user]
  return items?.filter(item => {
    const itemName = item.component.props.name
    return routes.some(route => route.startsWith(itemName)) || routes.includes(itemName)
  })
}

// used to filter the menuItems for Sidebar menu with sub items based on module control
const filterModuleBasedMenuItem = (modules, items) => {
  // return items
  return items.filter(item => {
    let hasAccess = true
    modules.forEach(module => {
      // if (module.routes.includes(item.component.props.to)) {
      //   if (!module.default_installed && !module.installed) {
      //     hasAccess = false
      //     return
      //   }
      // }

      if (module.routes.includes(item.component.key)) {
        if (!module.default_installed && !module.installed) {
          hasAccess = false
          return
        }
      }
    })
    return hasAccess
  })
}

export const getModuleBasedItem = (modules, menuItem, name, menuItems) => {
  if (!name.includes('/')) {
    // need to hide the menu when all the sub menus are hidden
    if (!menuItems?.length) {
      return null
    } else {
      return menuItem.component
    }
  } else {
    const hasAccess = getModuleBasedMenuItemSingle(modules, menuItem.name)
    if (hasAccess) {
      return menuItem.component
    } else {
      return null
    }
  }
}

export const getModuleHeader = item => {
  if (!item.name.includes('/') && !item.menuItems?.length) return false
  return true
}

export const getModuleBasedMenuItemSingle = (modules, route) => {
  let hasAccess = true
  modules.forEach(module => {
    if (module.routes.includes(route)) {
      if (!module.default_installed && !module.installed) {
        hasAccess = false
        return
      }
    }
  })

  return hasAccess
}

const getMenuItems = (modules, role, isMobile, isCollapsed = false) => ({
  hr: filterModuleBasedMenuItem(
    modules,
    filterRoleBasedMenuItem(role, isMobile, [
      {
        component: (
          <SidebarMenuItem
            isCollapsed={isCollapsed}
            name={privateRoutes.TEAM}
            to={TEAM_MEMBERS}
            key={TEAM_ROOT}
            _key={TEAM_ROOT}
            icon={{
              default: ICONS.sidebar.zenHR.employeeDirectory,
              active: ICONS.sidebar.zenHR.employeeDirectoryActive,
            }}
            title={'Employee Directory'}
            isMobile={isMobile}
          />
        ),
      },
      // {
      //   component: (
      //     <SidebarMenuItem
      //       isCollapsed={isCollapsed}
      //       name={privateRoutes.AWAY}
      //       to={AWAY}
      //       key={AWAY_ROOT}
      //       _key={AWAY_ROOT}
      //       icon={{
      //         default: ICONS.sidebar.zenHR.leaveManagement,
      //         active: ICONS.sidebar.zenHR.leaveManagementActive,
      //       }}
      //       title={'Leave Management'}
      //       isMobile={isMobile}
      //     />
      //   ),
      // },
      {
        component: (
          <SidebarMenuItem
            isCollapsed={isCollapsed}
            name={privateRoutes.LEAVE}
            to={LEAVE_AWAY}
            key={LEAVE_ROOT}
            _key={LEAVE_ROOT}
            icon={{
              default: ICONS.sidebar.zenHR.leaveManagement,
              active: ICONS.sidebar.zenHR.leaveManagementActive,
            }}
            title={'Leave Management'}
            isMobile={isMobile}
          />
        ),
      },

      // {
      //   component: (
      //     <SidebarMenuItem
      //       isCollapsed={isCollapsed}
      //       name={privateRoutes.ATTENDANCE}
      //       to={ATTENDANCE_POLICIES}
      //       key={ATTENDANCE_ROOT}
      //       _key={ATTENDANCE_ROOT}
      //       icon={{
      //         default: ICONS.sidebar.zenHR.employeeDirectory,
      //         active: ICONS.sidebar.zenHR.employeeDirectoryActive,
      //       }}
      //       title={'Attendance Management'}
      //       isMobile={isMobile}
      //     />
      //   ),
      // },
      {
        component: (
          <SidebarMenuItem
            isCollapsed={isCollapsed}
            name={privateRoutes.DOCUMENT}
            to={DOCUMENT}
            key={DOCUMENT_ROOT}
            _key={DOCUMENT_ROOT}
            icon={{
              default: ICONS.sidebar.zenHR.documents,
              active: ICONS.sidebar.zenHR.documentsActive,
            }}
            title={'Documents'}
            isMobile={isMobile}
          />
        ),
      },
    ]),
  ),
  it: filterModuleBasedMenuItem(
    modules,
    filterRoleBasedMenuItem(role, isMobile, [
      {
        component: (
          <SidebarMenuItem
            isCollapsed={isCollapsed}
            name={privateRoutes.INVENTORY}
            to={INVENTORY_HOME}
            key={INVENTORY_ROOT}
            _key={INVENTORY_ROOT}
            icon={{
              default: ICONS.sidebar.zenIT.inv,
              active: ICONS.sidebar.zenIT.invActive,
            }}
            title={'Inventory Management'}
            isMobile={isMobile}
          />
        ),
      },
      {
        component: (
          <SidebarMenuItem
            isCollapsed={isCollapsed}
            name={privateRoutes.SOFTWARE_TRACKING}
            to={SOFTWARE_TRACKING}
            key={SOFTWARE_TRACKING_ROOT}
            _key={SOFTWARE_TRACKING_ROOT}
            icon={{
              default: ICONS.sidebar.zenIT.software,
              active: ICONS.sidebar.zenIT.softwareActive,
            }}
            title={'Software Management'}
            isMobile={isMobile}
          />
        ),
      },
      {
        component: (
          <SidebarMenuItem
            isCollapsed={isCollapsed}
            name={privateRoutes.MDM}
            to={MDM}
            key={MDM_ROOT}
            _key={MDM_ROOT}
            icon={{
              default: ICONS.sidebar.zenIT.dm,
              active: ICONS.sidebar.zenIT.dmActive,
            }}
            title={'Device Management'}
            isMobile={isMobile}
          />
        ),
      },
      {
        component: (
          <SidebarMenuItem
            isCollapsed={isCollapsed}
            name={privateRoutes.PRODUCTS}
            to={PRODUCTS}
            key={PRODUCTS}
            _key={PRODUCTS}
            icon={{
              default: ICONS.sidebar.zenIT.webstore,
              active: ICONS.sidebar.zenIT.webstoreActive,
            }}
            title={'Webstore'}
            isMobile={isMobile}
          />
        ),
      },
      {
        component: (
          <SidebarMenuItem
            isCollapsed={isCollapsed}
            name={privateRoutes.ORDERS}
            to={ORDERS}
            key={ORDERS}
            _key={ORDERS}
            icon={{
              default: ICONS.sidebar.zenIT.orders,
              active: ICONS.sidebar.zenIT.ordersActive,
            }}
            title={'Orders'}
            isMobile={isMobile}
          />
        ),
      },
      {
        component: (
          <SidebarMenuItem
            isCollapsed={isCollapsed}
            name={privateRoutes.IT_SUPPORT}
            to={IT_SUPPORT}
            key={IT_SUPPORT}
            _key={IT_SUPPORT}
            icon={{
              default: ICONS.sidebar.zenIT.itSupport,
              active: ICONS.sidebar.zenIT.itSupportActive,
            }}
            title={'IT Support'}
            isMobile={isMobile}
          />
        ),
      },
    ]),
  ),
  tools: filterModuleBasedMenuItem(
    modules,
    filterRoleBasedMenuItem(role, isMobile, [
      {
        component: (
          <SidebarMenuItem
            isCollapsed={isCollapsed}
            name={privateRoutes.WORKFLOWS}
            to={WORKFLOWS_HOME_V2}
            key={WORKFLOWS_ROOT}
            _key={WORKFLOWS_ROOT}
            icon={{
              default: ICONS.sidebar.zenTools.workflow,
              active: ICONS.sidebar.zenTools.workflowActive,
            }}
            title={'Workflow'}
            isMobile={isMobile}
          />
        ),
      },
      {
        component: (
          <SidebarMenuItem
            isCollapsed={isCollapsed}
            name={privateRoutes.TASK}
            to={TASKS}
            key={TASKS_ROOT}
            _key={TASKS_ROOT}
            icon={{
              default: ICONS.sidebar.zenTools.tasks,
              active: ICONS.sidebar.zenTools.tasksActive,
            }}
            title={'Task & Approvals'}
            isMobile={isMobile}
          />
        ),
      },
      {
        component: (
          <SidebarMenuItem
            isCollapsed={isCollapsed}
            name={privateRoutes.CHECKLIST}
            to={CHECKLIST}
            key={CHECKLIST}
            _key={CHECKLIST}
            icon={{
              default: ICONS.sidebar.zenTools.checklist,
              active: ICONS.sidebar.zenTools.checklistActive,
            }}
            title={'Checklist'}
            isMobile={isMobile}
          />
        ),
      },

      {
        component: (
          <SidebarMenuItem
            isCollapsed={isCollapsed}
            name={privateRoutes.REPORTS}
            to={REPORTS}
            key={REPORTS}
            _key={REPORTS}
            icon={{
              default: ICONS.sidebar.zenTools.report,
              active: ICONS.sidebar.zenTools.reportActive,
            }}
            title={'Reports'}
            isMobile={isMobile}
          />
        ),
      },
    ]),
  ),
})
