export const TABLE_NAMES = {
  inventory: {
    list: 'inventory_list',
    archive: 'inventory_archive_list',
  },
  mdm: {
    devices: 'mdm_devices',
  },
  software: {
    list: 'software_list',
  },
  core: {
    team: 'team_list',
  },
  tools: {
    workflow: 'workflow_list',
  },
}
