import * as React from 'react'
import {usePurgeLayout} from 'app/utils/hooks'
import useAddSearchParams from 'app/utils/hooks/use-add-search-params'
import {stepsData} from './utils/data'
import OwnerAdminOnboarding from './pages/owner-admin-onboarding'
import FourthStep from './components'
import {
  useUpdateLoggedInUser,
  useUpdateShowTutorial,
  useUpdateTutorialStepsDone,
} from 'app/services'
import {useNavigate} from 'react-router-dom'
import {privateRoutes} from 'app/routing/authorized-routes'
import {useAuthActions, useCurrentUser} from 'app/store/auth'

function OnBoarding() {
  usePurgeLayout()
  const navigate = useNavigate()

  const currentUser = useCurrentUser()
  const {requestUser} = useAuthActions()

  const {mutateAsync: updateLoggedInUserMutate} = useUpdateLoggedInUser()
  const {mutateAsync: updateTutorialStepsDone} = useUpdateTutorialStepsDone()
  const {searchParams, addNewSearchParams} = useAddSearchParams()
  const [selectedCountries, setSelectedCountries] = React.useState(
    currentUser?.country_preferences || [],
  )
  const [currentStep, setCurrentStep] = React.useState(
    Number(
      currentUser?.tutorial_steps_visited?.length
        ? currentUser?.tutorial_steps_visited[currentUser?.tutorial_steps_visited.length - 1]
        : 1,
    ),
  )
  const {mutateAsync: updateShowTutorial} = useUpdateShowTutorial(
    [],
    'Onboarding Completed Successfully',
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = React.useMemo(() => stepsData(currentUser?.first_name), [currentUser?.id])

  async function updateStep(nextFlag: boolean) {
    try {
      if (currentStep === 4 && selectedCountries.length) {
        await updateLoggedInUserMutate({
          id: currentUser?.id,
          country_preferences: selectedCountries,
        })
      }
      if (nextFlag && currentStep + 1 <= data.length) {
        setCurrentStep(currentStep + 1)
        addNewSearchParams('step', currentStep + 1)
        updateTutorialStepsDone({tutorial_steps_visited: [currentStep + 1]})
      } else if (!nextFlag && currentStep - 1 >= 1) {
        setCurrentStep(currentStep - 1)
        addNewSearchParams('step', currentStep - 1)
        updateTutorialStepsDone({tutorial_steps_visited: [currentStep - 1]})
      }
    } catch (error) {
      console.log({error})
    }
  }

  async function handleSkipOrFinishTutorial() {
    try {
      await updateShowTutorial({is_show_tutorial: false})
      await requestUser()
      navigate(privateRoutes.DASHBOARD)
      window.location.reload()
    } catch (error) {
      console.log({error})
    }
  }

  React.useEffect(() => {
    if (searchParams.get('step')) {
      setCurrentStep(Number(searchParams.get('step')))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <OwnerAdminOnboarding
        currentStep={currentStep}
        totalStep={data.length}
        data={data[currentStep - 1]}
        handleSkipOrFinishTutorial={handleSkipOrFinishTutorial}
        updateStep={updateStep}
        component={
          <FourthStep
            selectedCountries={selectedCountries}
            onCountrySelect={setSelectedCountries}
          />
        }
      />
    </>
  )
}

export default OnBoarding
