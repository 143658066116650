import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import classes from './styles.module.scss'

interface HUISpinnerProps {
  // mostly used for spinner heights
  overrideStyle?: React.CSSProperties
  spinnerStyle?: React.CSSProperties
}

// todo @sohhamm remove this and find alternative spinner
export default function HUISpinner({overrideStyle, spinnerStyle}: HUISpinnerProps) {
  return (
    <div className={classes.container} style={overrideStyle}>
      <Spinner animation="border" role="status" variant="primary" style={spinnerStyle}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  )
}
