import QueryString from 'qs'
import {apiAxios} from '../../configs/axios'
import {
  FormRequestsResponse,
  IGetAssigneeChecklistTasksResponse,
  IGetAssigneesResponse,
  IGetChecklistByIdResponse,
  IGetChecklistRecipesResponse,
  IGetChecklistTasksResponse,
  IGetChecklistsResponse,
  IGetLeaveRequestsResponse,
  IGetMyTasksResponse,
  IGetTaskCommentsResponse,
  IGetTaskDetailsResponse,
  IGetTasksResponse,
} from 'types/tasks'
import {UpdateFormRequestPayload} from 'app/modules/tasks/components/form-request/utils/types'

export class TaskService {
  async createSection(payload) {
    return apiAxios.post(`/tasks/section`, payload)
  }

  async getTasks(query: string): Promise<IGetTasksResponse> {
    const res = await apiAxios.get(`/tasks?${query}`)
    return res.data
  }

  async createTask(payload) {
    return apiAxios.post(`/tasks`, payload)
  }

  async createSubSection(payload) {
    return apiAxios.post(`/tasks/sub-section`, payload)
  }
  async deleteTask(taskId) {
    return apiAxios.delete(`/tasks/${taskId}`)
  }

  async deleteSection(sectionId) {
    return apiAxios.delete(`/tasks/section/${sectionId}`)
  }

  async getAssignees(search: string): Promise<IGetAssigneesResponse> {
    const params = {
      limit: 5,
      skip: 0,
      search: search === '' ? undefined : search,
    }
    const res = await apiAxios.get(`/tasks/assignees`, {params})
    return res.data
  }

  async deleteSubSection(subSectionId) {
    return apiAxios.delete(`/tasks/sub-section/${subSectionId}`)
  }

  async getMyCreatedTask(query: string): Promise<IGetMyTasksResponse> {
    const res = await apiAxios.get(`/tasks/me?${query}`)
    return res.data
  }

  async getTaskDetails(taskId: string): Promise<IGetTaskDetailsResponse | null> {
    if (!taskId) return null
    const res = await apiAxios.get(`/tasks/${taskId}`)
    return res.data
  }

  async updateSection({sectionId, payload}) {
    return apiAxios.patch(`/tasks/section/${sectionId}`, payload)
  }

  async updateSubSection({subSectionId, payload}) {
    return apiAxios.patch(`/tasks/sub-section/${subSectionId}`, payload)
  }

  async updateTask({
    taskId,
    checklistTaskId,
    payload,
  }: {
    taskId?: string
    checklistTaskId?: string
    payload: any
  }) {
    return apiAxios.patch(`/tasks/${taskId ?? checklistTaskId}`, payload)
  }

  async getLeaveRequests(): Promise<IGetLeaveRequestsResponse> {
    // const res = await apiAxios.get(`/tasks/leaves`)
    const res = await apiAxios.get(`/tasks/v2/leaves`)
    return res.data
  }

  async getFormRequests(query: string): Promise<FormRequestsResponse> {
    const res = await apiAxios.get(`/tasks/form?${query}`)
    return res.data
  }

  async getTriggerForms(query: string): Promise<FormRequestsResponse> {
    const res = await apiAxios.get(`/tasks/trigger-form?${query}`)
    return res.data
  }

  async getApprovalRequests(query: string): Promise<FormRequestsResponse> {
    const res = await apiAxios.get(`/tasks/approval?${query}`)
    return res.data
  }

  async updateFormRequests({payload, task_id}: UpdateFormRequestPayload) {
    const res = await apiAxios.post(`/tasks/form/${task_id}`, payload)
    return res.data
  }

  async submitTriggerForm({payload, task_id}: UpdateFormRequestPayload) {
    const res = await apiAxios.post(`/tasks/trigger-form/${task_id}`, payload)
    return res.data
  }

  async updateFormApprovals({payload, task_id}: UpdateFormRequestPayload) {
    const res = await apiAxios.post(`/tasks/approval/${task_id}`, payload)
    return res.data
  }

  async createChecklist(payload: {name: string; recipe_id?: string}) {
    const res = await apiAxios.post(`/checklist`, payload)
    return res.data
  }

  async getChecklists(query = ''): Promise<IGetChecklistsResponse> {
    const res = await apiAxios.get(`/checklist${query ? `?${query}` : ''}`)
    return res.data
  }

  async getInfiniteChecklists(query: string, page: number): Promise<IGetChecklistsResponse> {
    const qs = QueryString.parse(query, {ignoreQueryPrefix: true})
    qs.page = String(page)
    const updatedQuery = QueryString.stringify(qs)
    const res = await apiAxios.get(`/checklist${updatedQuery ? '?' + updatedQuery : ''}`)
    return res.data
  }

  async updateChecklist({checklistId, payload}: {checklistId: string; payload: any}) {
    const res = await apiAxios.patch(`/checklist/${checklistId}`, payload)
    return res.data
  }

  async deleteChecklist(checklistId: string) {
    const res = await apiAxios.delete(`/checklist/${checklistId}`)
    return res.data
  }

  async updateAssignees({checklistId, payload}: {checklistId: string; payload: any}) {
    const res = await apiAxios.patch(`/checklist/${checklistId}/assignee`, payload)
    return res.data
  }

  async getChecklistTasks(checklistId?: string): Promise<IGetChecklistTasksResponse | null> {
    if (!checklistId) return null
    const res = await apiAxios.get(`/checklist/${checklistId}/tasks`)
    return res.data
  }

  async createChecklistTask(payload: any) {
    const res = await apiAxios.post(`/checklist/task`, payload)
    return res.data
  }

  async updateChecklistTask({checklistTaskId, payload}: {checklistTaskId: string; payload: any}) {
    const res = await apiAxios.patch(`/checklist/task/${checklistTaskId}`, payload)
    return res.data
  }

  async getChecklistTaskDetails(taskId: string): Promise<IGetTaskDetailsResponse | null> {
    if (!taskId) return null
    const res = await apiAxios.get(`/checklist/task/${taskId}`)
    return res.data
  }

  async getAssigneeChecklistTasks({
    checklistId,
    assigneeId,
  }: {
    checklistId: string
    assigneeId: string
  }): Promise<IGetAssigneeChecklistTasksResponse> {
    const res = await apiAxios.get(`/checklist/${checklistId}/tasks/${assigneeId}`)
    return res.data
  }

  async deleteChecklistTask(taskId: string) {
    const res = await apiAxios.delete(`/checklist/task/${taskId}`)
    return res.data
  }

  async updatePersons({checklistId, payload}: {checklistId: string; payload: any}) {
    const res = await apiAxios.patch(`/checklist/${checklistId}/person`, payload)
    return res.data
  }

  async reorderChecklistTasks({taskId, payload}: {taskId: string; payload: any}) {
    const res = await apiAxios.patch(`/checklist/task/${taskId}/reorder`, payload)
    return res.data
  }

  async getTaskComments({
    task_id,
    type,
  }: {
    task_id: string
    type: 'task' | 'checklist'
  }): Promise<IGetTaskCommentsResponse | null> {
    if (!task_id) return null
    const res = await apiAxios.get(`/tasks/${task_id}/comments?type=${type}`)
    return res.data
  }

  async createTaskComment({task_id, payload}: {task_id: string; payload: any}) {
    const res = await apiAxios.post(`/tasks/${task_id}/comment`, payload)
    return res.data
  }

  async editTaskComment({task_id, comment_id, payload}) {
    const res = await apiAxios.patch(`/tasks/${task_id}/comment/${comment_id}`, payload)
    return res.data
  }

  async deleteTaskComment({taskId, commentId}: {taskId: string; commentId: string}) {
    const res = await apiAxios.delete(`/tasks/${taskId}/comment/${commentId}`)
    return res.data
  }

  async reactTaskComment({comment_id, payload}) {
    const res = await apiAxios.post(`/tasks/comment/${comment_id}/reaction`, payload)
    return res.data
  }

  async removeTaskCommentReaction({comment_id, emoji}) {
    const res = await apiAxios.delete(`/tasks/comment/${comment_id}/reaction`, {data: {emoji}})
    return res.data
  }

  async getChecklistRecipes(): Promise<IGetChecklistRecipesResponse> {
    const res = await apiAxios.get(`/checklist/recipes`)
    return res.data
  }

  async duplicateChecklist(payload) {
    const res = await apiAxios.post(`/checklist/duplicate`, payload)
    return res.data
  }

  async removeChecklistAssignee({
    checklistId,
    assigneeId,
  }: {
    checklistId: string
    assigneeId: string
  }) {
    const res = await apiAxios.delete(`/checklist/${checklistId}/tasks/${assigneeId}`)
    return res.data
  }

  async getChecklistDetails(checklistId: string): Promise<IGetChecklistByIdResponse | null> {
    if (!checklistId) return null
    const res = await apiAxios.get(`/checklist/${checklistId}`)
    return res.data
  }
}
