import {useQuery} from '@tanstack/react-query'
import {timeAwayV2Keys} from '../query-key-factory'
import {useMutate} from 'app/utils/hooks/useMutate'
import {MANAGE_TIME_AWAY} from 'app/utils/constants/toast'
import {TimeAwayServiceV2} from './time-away.service'
import {LeaveActivitiesQueryParams} from 'types/time-away'

const svc = new TimeAwayServiceV2()

export const useGetTimeAwayDetails = (userId: string) => {
  const res = useQuery({
    queryKey: timeAwayV2Keys.details(userId),
    queryFn: () => svc.getTimeAwayDetails(userId),
  })

  return {
    details: res.data,
    fetchingDetails: res.isPending,
    isErrorDetails: res.isError,
    detailsSuccess: res.isSuccess,
  }
}
export const useGetLeaveActivities = (
  userId: string,
  leaveActivityPeriod: LeaveActivitiesQueryParams,
) => {
  const res = useQuery({
    queryKey: timeAwayV2Keys.leaveActivities(userId, leaveActivityPeriod),
    queryFn: () => svc.getLeaveActivities(userId, leaveActivityPeriod),
  })

  return {
    activities: res.data,
    fetchingActivities: res.isPending,
    isErrorActivities: res.isError,
    activitiesSuccess: res.isSuccess,
  }
}
export const useGetLeaveConfig = (userId: string) => {
  const res = useQuery({
    queryKey: timeAwayV2Keys.leaveConfig(userId),
    queryFn: () => svc.getLeaveConfig(userId),
  })

  return {
    configs: res.data,
    fetchingConfigs: res.isPending,
    isErrorConfigs: res.isError,
    configIsSuccess: res.isSuccess,
  }
}
export const useGetLeaveBalance = () => {
  const res = useMutate(svc.getLeaveBalance)
  return {
    getLeaveBalance: res.mutateAsync,
    gettingLeaveBalance: res.isPending,
  }
}

export const useCreateLeaveRequests = () => {
  const res = useMutate(svc.createLeaveRequests, timeAwayV2Keys.all, MANAGE_TIME_AWAY.bookTime)
  return {
    createRequest: res.mutateAsync,
    isCreatingRequest: res.isPending,
  }
}
