import {toastSuccess} from 'app/configs'

export const formatDate = d => {
  if (!d) return 'N/A'
  return new Date(d).toLocaleDateString(undefined, {
    weekday: undefined,
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}

// compose(fn1,fn2)(x)
export const compose =
  (...fns) =>
  x =>
    fns.reduceRight((y, f) => f(y), x)

// function composition using pipe of any number of functions
export const pipe =
  (...fns) =>
  x =>
    fns.reduce((y, f) => f(y), x)

interface ISanitizeConfig {
  allowEmptyStr?: boolean
  convertEmptyStrToNull?: boolean
}

export const sanitizeObj = (obj, config?: ISanitizeConfig) => {
  if (config?.allowEmptyStr) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === null) {
        delete obj[key]
      }
    })
    return obj
  } else {
    Object.keys(obj).forEach(key => {
      if (obj[key] === '' || obj[key] === null) {
        delete obj[key]
      }
      if (config?.convertEmptyStrToNull) {
        if (obj[key] === '') {
          obj[key] = null
        }
      }
    })
    return obj
  }
}

export const sanitizePayload = obj => {
  const newObj = {...obj}
  Object.keys(newObj).forEach(key => {
    if (newObj[key] === null) {
      delete newObj[key]
    }
    if (typeof newObj[key] === 'string' && newObj[key].trim() === '') {
      newObj[key] = null
    }
  })
  return newObj
}

type DynamicObj = {
  [key: string]: any
}

export const sanitizeParams = (obj: DynamicObj): DynamicObj => {
  const result: DynamicObj = {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      if (value !== null && value !== undefined && value !== '') {
        if (typeof value === 'object' && !Array.isArray(value)) {
          Object.assign(result, sanitizeParams(value))
        } else {
          result[key] = value
        }
      }
    }
  }
  return result
}

export const clipboard = async (text?: string, showToast = true) => {
  if (!text) return
  await navigator.clipboard.writeText(text).catch(console.error)

  if (showToast) {
    toastSuccess({
      msg: `Successfully copied ${text}`,
      options: {
        toastId: text,
        autoClose: 1000,
      },
    })
  }
}

export const isFloat = value => {
  if (typeof value === 'number' && !Number.isNaN(value) && !Number.isInteger(value)) {
    return true
  }

  return false
}

export const debounce = (fn, ms = 300) => {
  let timeoutId

  return (...args) => {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      fn.apply(this, args)
    }, ms)
  }
}

export const debouncePromise = (fn, ms = 0) => {
  let timeoutId
  const pending = []
  return (...args) =>
    new Promise((res, rej) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        const currentPending = [...pending]
        pending.length = 0
        Promise.resolve(fn.apply(this, args)).then(
          data => {
            currentPending.forEach(({resolve}: {resolve: (data: any) => {}}) => resolve(data))
          },
          error => {
            currentPending.forEach(({reject}: {reject: (err: any) => {}}) => reject(error))
          },
        )
      }, ms)
      // @ts-ignore
      pending.push({resolve: res, reject: rej})
    })
}

export const toSnakeCase = (str: string) => {
  return str
    .replace(/\s+/g, '_')
    .replace(/-+/g, '_')
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toLowerCase()
}
