import 'react-toastify/dist/ReactToastify.css'
import ErrorBoundaryFallback from './modules/errors/ErrorBoundaryFallback'
import {createPortal} from 'react-dom'
import {Outlet, useNavigate} from 'react-router-dom'
import {AuthInit} from './modules/auth'
import {ToastContainer} from 'react-toastify'
import {ErrorBoundary} from './configs/sentry'
import {ToastCloseButton} from './configs/toasts'
import {DASHBOARD_SUMMARY} from 'app/routing/getters'
import {ToastCloseButtonProps} from 'types/toast'

const getErrorFallback = props => {
  return (
    <ErrorBoundaryFallback {...props} error={props.error} resetErrorBoundary={props.resetError} />
  )
}

export default function App() {
  const navigate = useNavigate()

  return (
    <ErrorBoundary
      fallback={getErrorFallback}
      onReset={() => {
        navigate(DASHBOARD_SUMMARY)
      }}
    >
      <AuthInit>
        <Outlet />
        {createPortal(
          <ToastContainer
            pauseOnFocusLoss={false}
            limit={2}
            closeButton={({closeToast}: ToastCloseButtonProps) => {
              return <ToastCloseButton closeToast={closeToast} />
            }}
          />,
          document.body,
        )}
      </AuthInit>
    </ErrorBoundary>
  )
}
