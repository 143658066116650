import QueryString from 'qs'
import {apiAxios} from '../../configs/axios'

export class ProductService {
  async getAllProducts(query, page) {
    const qs = QueryString.parse(query, {ignoreQueryPrefix: true})
    qs.page = String(page)
    const updatedQuery = QueryString.stringify(qs)

    const res = await apiAxios.get(`/products${updatedQuery ? '?' + updatedQuery : ''}`)
    return res.data
  }

  async getProductTypes(query = '') {
    // if (!query.length) return null
    const res = await apiAxios.get(`/products/product-types${query ? '?' + query : ''}`)
    return res.data.data
  }

  async getProductByID(query) {
    const res = await apiAxios.get(`/products/variants${query ? '?' + query : ''}`)
    return res.data
  }
}
