interface HUIAvatarProps {
  user: any
  imgClassNames?: string
  avatarSize?: number
  avatarSquare?: boolean
  imageStyle?: React.CSSProperties
  avatarScale?: boolean
}

export default function HUIAvatar({
  user,
  imgClassNames,
  avatarSize = 20,
  imageStyle = {},
  avatarScale = false,
}: HUIAvatarProps) {
  return (
    <img
      src={user?.profile_img_url}
      alt="User Profile"
      className={imgClassNames}
      style={{
        width: imageStyle?.width ? imageStyle.width || '28px' : `${avatarSize}px`,
        height: imageStyle?.height ? imageStyle.height || '28px' : `${avatarSize}px`,
        borderRadius: '8px',
        ...imageStyle,
      }}
    />
  )
}
