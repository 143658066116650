import clsx from 'clsx'
import classes from './styles.module.css'
import {motion} from 'framer-motion'
import {Link, useLocation} from 'react-router-dom'
import {SVG, Tooltip} from '@hybr1d-tech/charizard'
import {checkIsActive} from 'hybrid-ui/helpers'
import {useLayoutActions, useSidebarMinimized} from 'app/store/layout'

interface SidebarMenuItemSingleProps {
  to: string
  title: string
  icon: {default: string; active: string}
  children?: React.ReactNode
  isMobile?: boolean
  _key: string
}

export default function SidebarMenuItemSingle({
  children,
  to,
  title,
  icon,
  _key,
  isMobile = false,
}: SidebarMenuItemSingleProps) {
  const isMini = useSidebarMinimized()
  return isMini ? (
    <Tooltip placement="right">
      <Tooltip.Trigger customStyles={{width: '100%', padding: 0}}>
        <MenuItemSingle {...{children, to, title, isMobile, _key, icon, isMini}} />
      </Tooltip.Trigger>

      <Tooltip.Content>{title}</Tooltip.Content>
    </Tooltip>
  ) : (
    <MenuItemSingle {...{children, to, title, isMobile, _key, icon, isMini}} />
  )
}

function MenuItemSingle({isMobile, _key, to, icon, children, title, isMini}) {
  const {pathname} = useLocation()

  const isActive = checkIsActive(pathname, _key)
  const {toggleMini} = useLayoutActions()

  const condition = !isMini || isMobile
  const condition2 = isMini && !isMobile
  return (
    <div
      className={clsx(classes.menu, condition2 && classes.menuMini)}
      onClick={() => {
        if (!isMini) toggleMini()
      }}
    >
      <Link to={to}>
        <div className={clsx(classes.link, isActive && classes.linkActive)}>
          <div className={classes.logoBox}>
            <SVG
              path={icon.default}
              svgClassName={clsx(
                classes.logo,
                condition2 && classes.logoMini,
                isActive && classes.logoCurrActive,
              )}
            />
            <SVG
              path={icon.active}
              svgClassName={clsx(
                classes.logoActive,
                condition2 && classes.logoMini,
                isActive && classes.logoCurrActive,
              )}
            />
          </div>

          {condition && (
            <motion.span
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              transition={{duration: 0.6}}
              className={clsx(classes.title, isActive && classes.activeTitle)}
            >
              {title}
            </motion.span>
          )}
        </div>
      </Link>
      {children}
    </div>
  )
}
