import './hybrid-ui/assets/sass/style.scss'
import './app/styles/_typography.css'
import './app/styles/_variables.css'
import './app/styles/_global.css'
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import AppRoutes from './app/routing/AppRoutes'
import {QueryClientProvider} from '@tanstack/react-query'
import {queryClient} from 'app/configs/react-query'
import {initializeSentry} from './app/configs/sentry'
import {GOOGLE_CLIENT_ID} from 'app/configs/config'
import {GoogleOAuthProvider} from '@react-oauth/google'
import {HelmetProvider} from 'react-helmet-async'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {mixpanelInit} from 'app/configs'

initializeSentry()
mixpanelInit()

ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement).render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <AppRoutes />
        </HelmetProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom" />
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
)
