import * as React from 'react'
import classes from './styles.module.css'
import {BUTTON_V2_VARIANT, ButtonV2, BUTTON_V2_SIZE, Input, SVG, Select} from '@hybr1d-tech/charizard'
import {ICONS} from 'app/utils/constants/icon'
import {UtilityService, useGetDefaultList} from 'app/services'
import {useFormik} from 'formik'
import {inputValidation} from './utils'
import {v4 as uuidv4} from 'uuid'
import HUIToolTip from 'hybrid-ui/components/hui-tooltip/HUIToolTip'
import {FixedSizeList as List} from 'react-window'
import {toastInfo} from 'app/configs'
import clsx from 'clsx'

export default function CustomList({formik}) {
  const svc = new UtilityService()
  const [showAddNewItem, setShowNewItem] = React.useState(false)
  const [showInputForNewItem, setShowInputForNewItem] = React.useState(false)
  const [editingOptionIndex, setEditingOptionIndex] = React.useState<number | null>(null)

  const {data: defaultOptionList, isLoading} = useGetDefaultList()

  const inputFormik = useFormik({
    initialValues: {text: ''},
    validationSchema: inputValidation,
    onSubmit: (values, newFormik) => {
      const newListItemAlreadyExist = formik.values.list_options.find(
        options => options.label.toLowerCase() === values.text.toLowerCase(),
      )
      if (newListItemAlreadyExist) {
        toastInfo({msg: 'Items already exist'})
        return
      }
      const newOption = {label: values.text, value: uuidv4()}
      const sortedList = [newOption, ...formik.values.list_options].sort((a, b) =>
        a.label.localeCompare(b.label),
      )
      formik.setFieldValue('list_options', sortedList)
      setShowInputForNewItem(false)
      setShowNewItem(false)
      newFormik.resetForm()
    },
  })

  const handleOptionAddition = async val => {
    const listOptions = await svc.getDefaultListOption(val)
    const alreadyAdded = formik.values.list_options.filter(
      option =>
        JSON.stringify(listOptions).includes(option.value) ||
        listOptions.find(item => item.label.toLowerCase() === option.label.toLowerCase()),
    )
    if (alreadyAdded.length === listOptions.length) {
      toastInfo({msg: 'Items added in the list'})
      setShowNewItem(false)
      return
    }
    if (alreadyAdded.length) {
      const toBeAddedDifference = listOptions.filter(
        option =>
          !JSON.stringify(formik.values.list_options).includes(option.value) &&
          !formik.values.list_options.find(
            item => item.label.toLowerCase() === option.label.toLowerCase(),
          ),
      )
      const sortedList = [...toBeAddedDifference, ...formik.values.list_options].sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      )
      formik.setFieldValue('list_options', sortedList)
      setShowNewItem(false)
      return
    }
    const sortedList = [...listOptions, ...formik.values.list_options].sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
    )
    formik.setFieldValue('list_options', sortedList)
    setShowNewItem(false)
  }

  const deleteOption = index => {
    let newList = structuredClone(formik.values.list_options)
    newList = newList.filter((list, idx) => idx !== index)
    formik.setFieldValue('list_options', newList)
  }
  
  return (
    <div className={classes.listParentContainer}>
      <div className={classes.listHeaderContainer}>
        <div className={classes.listTitle}>List items</div>
        {showAddNewItem ? (
          <></>
        ) : (
          <div className={classes.addListBtn} onClick={() => setShowNewItem(true)}>
            {' '}
            + New Item
          </div>
        )}
      </div>
      <div className={classes.errorMsg}>
        {!!formik.touched.list_options && formik.errors.list_options}
      </div>
      {showAddNewItem && (
        <div className={classes.createNewListContainer}>
          <div
            className={classes.createNewListContainerCloseBtn}
            onClick={() => setShowNewItem(false)}
          >
            <SVG
              path={ICONS.close}
              width={24}
              height={24}
              svgClassName={classes.createNewListContainerCloseIcon}
            />
          </div>
          {showInputForNewItem ? (
            <div className={classes.newListOptionInputContainer}>
              <Input
                {...inputFormik.getFieldProps('text')}
                onBlur={() => formik.setFieldTouched('list_options')}
                placeholder="New List option"
                value={inputFormik.values.text}
                onChange={e => inputFormik.setFieldValue('text', e.target.value)}
                errorMsg={inputFormik.touched.text && inputFormik.errors.text}
              />
              <div className={classes.newListOptionBtnContainer}>
                <ButtonV2
                  size={BUTTON_V2_SIZE.SMALL}
                  onClick={() => setShowInputForNewItem(false)}
                  variant={BUTTON_V2_VARIANT.SECONDARY}
                >
                  Cancel{' '}
                </ButtonV2>
                <ButtonV2 size={BUTTON_V2_SIZE.SMALL} onClick={inputFormik.submitForm} variant={BUTTON_V2_VARIANT.PRIMARY}>
                  Save{' '}
                </ButtonV2>
              </div>
            </div>
          ) : (
            <>
              <div
                className={clsx(
                  classes.addNewOptionBtn,
                  editingOptionIndex != null && editingOptionIndex >= 0 ? classes.disabled : '',
                )}
                onClick={() =>
                  editingOptionIndex != null && editingOptionIndex >= 0
                    ? ''
                    : setShowInputForNewItem(true)
                }
              >
                <SVG path={ICONS.plus} width={20} height={20} />
                <div> Create new list item</div>
              </div>
              <Select
                placeholder="Add from a list"
                isDisabled={isLoading}
                options={defaultOptionList}
                onChange={handleOptionAddition}
              />
            </>
          )}
        </div>
      )}
      <div className={classes.listContainer}>
        {formik.values.list_options?.length ? (
          // formik.values.list_options.map((list, idx) => (
          <List
            className="List"
            height={700}
            itemCount={formik.values.list_options.length}
            itemSize={55}
            width={'100%'}
          >
            {({index, style}) => (
              <div style={style} key={formik.values.list_options[index]?.value}>
                <Row
                  idx={index}
                  formik={formik}
                  editingOptionIndex={editingOptionIndex}
                  list={formik.values.list_options[index]}
                  deleteOption={deleteOption}
                  setEditingOptionIndex={setEditingOptionIndex}
                  showInputForNewItem={showInputForNewItem}
                />
              </div>
            )}
          </List>
        ) : (
          // ))
          <div className={classes.emptyStateContainer}>
            <SVG path={ICONS.planetRingStars} height={80} width={80} />
            <div className={classes.emptyDesc}>Start adding list items</div>
          </div>
        )}
      </div>
    </div>
  )
}

const Row = ({
  idx,
  editingOptionIndex,
  formik,
  list,
  setEditingOptionIndex,
  deleteOption,
  showInputForNewItem,
}) => {
  const editFormik = useFormik({
    initialValues: {text: ''},
    validationSchema: inputValidation,
    onSubmit: (values, newFormik) => {
      const newListItemAlreadyExist = formik.values.list_options.find(
        options => options.label === values.text,
      )
      if (newListItemAlreadyExist) {
        toastInfo({msg: 'Items already exist'})
        return
      }
      let newList = structuredClone(formik.values.list_options)
      newList = newList?.map((item, idx) => {
        if (idx === editingOptionIndex) {
          return {value: uuidv4(), label: values.text}
        }
        return item
      })
      newList = newList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
      formik.setFieldValue('list_options', newList)
      setEditingOptionIndex(null)
      newFormik.resetForm()
    },
  })
  React.useEffect(() => {
    editFormik.setFieldValue('text', list.label)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return idx === editingOptionIndex ? (
    <>
      <div className={classes.newListOptionInputContainer}>
        <Input
          {...editFormik.getFieldProps('text')}
          placeholder="Edit List option"
          value={editFormik.values.text}
          onChange={e => editFormik.setFieldValue('text', e.target.value)}
          errorMsg={editFormik.touched.text && editFormik.errors.text}
        />
        <div className={classes.newListOptionBtnContainer}>
          <ButtonV2
            size={BUTTON_V2_SIZE.SMALL}
            onClick={() => {
              setEditingOptionIndex(null)
              editFormik.resetForm()
            }}
            variant={BUTTON_V2_VARIANT.SECONDARY}
          >
            Cancel{' '}
          </ButtonV2>
          <ButtonV2 size={BUTTON_V2_SIZE.SMALL} onClick={editFormik.submitForm} variant={BUTTON_V2_VARIANT.PRIMARY}>
            Save{' '}
          </ButtonV2>
        </div>
      </div>
    </>
  ) : (
    <div className={classes.listItem} key={list.value}>
      <div className={classes.itemText}> {list.label} </div>
      <div className={classes.actionIconContainer}>
        {showInputForNewItem ? (
          <SVG
            path={ICONS.editPen2}
            svgClassName={clsx(classes.settingIcon, showInputForNewItem ? classes.disabled : '')}
            width={22}
            height={22}
          />
        ) : (
          <HUIToolTip
            id={list.value}
            trigger={
              <div
                onClick={() => {
                  if (!showInputForNewItem) {
                    setEditingOptionIndex(idx)
                    editFormik.setFieldValue('text', list.label)
                  }
                }}
              >
                <SVG
                  path={ICONS.editPen2}
                  svgClassName={clsx(
                    classes.settingIcon,
                    showInputForNewItem ? classes.disabled : '',
                  )}
                  width={22}
                  height={22}
                />
              </div>
            }
            content="Edit"
          />
        )}

        <HUIToolTip
          id="dkjkfidk"
          trigger={
            <div onClick={() => deleteOption(idx)}>
              <SVG
                path={ICONS.delete.deleteOutlineGray}
                svgClassName={classes.settingIcon}
                height={22}
                width={22}
              />
            </div>
          }
          content="Delete"
        />
      </div>
    </div>
  )
}
