export const SOFTWARE_TRACKING_TOASTS = {
  addSoftwareTracking: name =>
    `Successfully added manual software tracking ${name ? 'for ' + name : ''}`,
  updateSoftwareTracking: name =>
    `Successfully updated software tracking ${name ? 'for ' + name : ''}`,
  archiveSoftwareTracking: 'Successfully archived software tracking',
  unarchiveSoftwareTracking: 'Successfully unarchived software tracking',
  deleteSoftwareTracking: 'Successfully deleted software tracking',
  addSoftwareLicense: 'Successfully added software license',
  updateSoftwareLicense: 'Successfully updated software license',
  deleteSoftwareLicense: 'Successfully removed manual software',
  docsAddedSuccessfully: 'Successfully added document',
  docsRemovedSuccessfully: 'Successfully removed document',
  syncedSuccessfully: 'Successfully synced software',
  updateSoftwareLicenseDetails: 'Successfully updated software license details',
  updateSoftwareDetails: 'Successfully updated software details',
  provision: `Successfully provisioned users`,
  deprovision: (name?: string) => `Successfully de-provisioned ${name || 'user'}`,
  bulkUpload: `Successfully bulk uploaded users to software`,
  addDefaultAssignmentRules: 'Successfully updated default assignment rules',
}

export const INV_TOASTS = {
  addInv: 'Successfully added inventory',
  editTag: 'Successfully updated tag',
  updateInvFinance: 'Successfully updated inventory finance details',
  archivedSuccessfully: 'Successfully archived inventory',
  deallocateInv: 'Successfully deallocated inventory',
  updateProductSpecs: 'Successfully updated product specifications',
  updateAdditionalInfo: "Successfully updated product's additional info",
  changeStatus: 'Successfully updated inventory status',
  unArchiveInventory: 'Successfully unarchived inventory',
  deleteInventory: 'Successfully deleted inventory',
  archivedInfoUpdate: 'Successfully updated archived info',
  cancelInventory: (type: 'allocation' | 'deallocation') => `Successfully cancelled ${type}`,
}

export const MDM_TOASTS = {
  syncDeviceDetails: 'Successfully synced device details',
  syncSummary: 'Successfully synced device details',
  updatedPolicy: 'Successfully updated device policies',
  updatedDeviceGroup: 'Successfully updated device group',
  appInstalled: 'Successfully installed application',
  appUninstalled: 'Successfully uninstalled application',
  appActionSuccessful: action => `Successfully done ${action} action`,
}

export const AWAY_TOASTS = {
  createTimeAwayPolicy: 'Successfully created the PTO policy',
  updateTimeAwayPolicy: 'Successfully updated the PTO policy',
  deleteTimeAwayPolicy: 'Successfully deleted PTO policy',
  // deleteTimeAwayPolicy: 'Successfully deleted PTO policy',
}

export const TEAM_TOASTS = {
  activateUser: `Successfully activated user account`,
  inviteUser: `Invitation sent successfully`,
  deleteUser: 'Successfully deleted user profile',
  assignTag: 'Successfully assigned tag(s)',
  updateDepartment: 'Successfully updated department',
  assignRole: 'Successfully assigned role',
  createNewHire: 'Successfully created new hire',
  createReHire: 'Successfully created rehire',
  activate: 'Successfully activated new hire',
}

export const USER_TOASTS = {
  updateProfilePicture: 'Successfully updated profile picture',
}

export const WORKFLOWS_TOASTS = {
  createWorkflow: 'Successfully created workflow',
  updateWorkflow: 'Successfully updated workflow',
  duplicateWorkflow: 'Successfully duplicated workflow',
  workflowAction: 'Successfully updated workflow',
  workflowNodeUpdate: 'Successfully updated the node',
  stopWorkflowTriggerExecution: 'Successfully stopped workflow trigger execution',
  deleteWorkflow: 'Successfully deleted workflow',
  retryNodeExecution: 'Successfully re-run node execution',
  cancelTriggerExecution: 'Successfully cancelled execution',
}

export const FORM_TOASTS = {
  formDataDownload: 'Successfully downloaded filled data',
  deleteForm: 'Successfully deleted form',
  updateForm: 'Successfully updated form',
}

export const TASK_TOASTS = {
  formApproval: 'Successfully updated approval form',
  submitForm: 'Successfully submitted form',
}

export const MANAGE_TIME_AWAY = {
  bookTime: 'Successfully booked time away',
}

export const HIRING_TEMPLATE_TOAST = {
  createHiringTemplate: 'Successfully created hiring template',
  deleteHiringTemplate: 'Successfully deleted hiring template',
  updateHiringTemplate: 'Successfully updated hiring template',
}
