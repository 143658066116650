import {Address} from 'app/modules/settings/utils/schema'
import {DEVICE_HANDLING_METHOD, YES_NO, DATA_HANDLING_METHOD} from './inventory'
import {CONTRACT_TYPE} from './order'
import {OS, ProductDetailsVariant} from './product'
import {USER_STATUS} from './user'
import {ProductCompany, ProductType} from 'types'
import {FieldType} from './utils'
import {TabSubCategoryFields} from './hiring-template'

export interface ITeamMembersResponse {
  data: ITeamMemberDetails[]
  error: boolean
  message: string
  meta_data: {
    items_on_page: number
    page_no: number
    total_items: number
    total_new_hires: number
    total_users: number
  }
}

export interface ITeamMemberDetailsResponse {
  data: ITeamMemberDetails
  error: boolean
  message: string
  meta_data: null
}

// todo clean up types
export interface ITeamMemberDetails {
  addresses?: IAddress[]
  allocated_inventories?: IAllocatedInventory[]
  company: ICompany
  country?: ICountry
  country_id: string
  country_preferences?: ICountry[]
  created_at?: string
  date_of_birth?: string | null
  direct_reports?: ITeamMemberDetails[]
  email?: string
  employee_id?: string
  employment_end_date?: string | null
  first_active_at?: string | null
  first_name: string
  middle_name?: string
  gender?: string
  incognito_present?: boolean
  health?: IHealth
  hide_work_email?: boolean
  id: string
  invite_on_start_date?: boolean
  invited_by_user?: ITeamMemberDetails
  invited_by_user_id?: string
  is_password_changeable?: boolean
  is_show_tutorial?: boolean
  is_work_remote?: boolean
  join_date?: string
  probation_end?: string
  last_name?: string
  last_working_date?: string | null
  nationalities?: string[]
  offboard_note?: string
  offboard_on?: string | null
  offboard_reason?: string
  offboarded_by?: string
  offboarded_by_user?: ITeamMemberDetails
  password_reset_token?: string
  personal_address?: IAddress | null
  personal_phone_number?: string | null
  phone_number?: string | null
  plan?: string // 'starter'
  position_effective_date?: string | null
  position_note?: string | null
  primary_contact?: string | null
  profile_img_url?: string | null
  profile_progress?: IProfileProgress
  public_holiday_calendar_id?: string | null
  reporting_manager?: ITeamMemberDetails
  reporting_manager_id?: string | null
  resignation_file?: IResginationFile
  role?: string
  secondary_contact?: string | null
  social?: ISocial
  spoken_languages?: string[]
  start_date?: string
  status?: USER_STATUS
  time_away_allocation_id?: string | null
  time_away_period_id?: string | null
  time_away_policy_id?: string
  tutorial_steps_visited?: number[]
  user_bank?: IBanking
  user_department?: IUserDepartment
  user_email?: string
  user_info?: IUserInfo
  user_position?: IUserPosition
  user_tags?: IUserTag[]
  work_email?: string
  work_location?: IAddress | null
  work_location_id?: string | null
  work_timezone?: IWorkTimezone

  job_position?: IUserJobPosition
  user_id?: string
  password?: string
  dial_code?: string
  company_id?: string
  tag_ids?: string[]
  city: string
  state: string
  postal_code: string
  remember_token?: string
  user_roles?: UserRole[]
  address_line1?: string
  address_line2?: string
  profile_completion?: string
  eventType?: string[]
  personal_email?: string
  department_id?: string
  personal_number?: string
  work_number?: string
  department?: {
    id: string
    name: string
  }
  position?: {
    id: string
    name: string
  }
  validDomains: string[]
  allocated_inventories_v2: AllocatedInventoryV2[]
  working_days?: number[]
  tenure?: string
  work_anniversary_years?: string
  last_working_day?: string
  last_accessed_at?: string
  tax_id?: string
  employment_type?: EMPLOYMENT_TYPE
  reporting_to?: {
    email?: string
    first_name: string
    id: string
    last_name: string
    middle_name?: string
    profile_img_url?: string
    work_email: string
  }
  number_of_direct_reports?: string
  new_hire_template_id?: string
}

// TODO: @abhishek use this type after testing
// export interface ITeamMemberDetails {
//   id: string
//   email: string
//   first_name: string
//   middle_name?: string
//   last_name: string
//   work_number: string
//   created_at: string
//   first_active_at: string | null
//   start_date: string
//   join_date: string
//   work_email: string
//   country_id: string
//   profile_img_url: string
//   reporting_manager_id: string | null
//   date_of_birth: string
//   is_deleted: boolean
//   offboard_on: string | null
//   is_work_remote: boolean
//   work_location_id: string
//   employee_id: string
//   time_away_policy_id: string | null
//   time_away_allocation_id: string | null
//   time_away_period_id: string | null
//   public_holiday_calendar_id: string | null
//   status: USER_STATUS
//   hide_work_email: boolean
//   nationalities: string[]
//   spoken_languages: string[]
//   gender: string
//   personal_number: string
//   probation_end: string
//   tenure: string
//   last_working_date: string | null
//   employment_end_date: string | null
//   working_days: string[]
//   work_anniversary_years: string
//   tax_id: string
//   number_of_direct_reports: string
//   employment_type: EMPLOYMENT_TYPE
//   last_accessed_at: string | null
//   reporting_to: string | null
//   contract_type: EMPLOYMENT_CONTRACT_TYPE | null
//   user_position: IUserPosition
//   user_department: IUserDepartment
//   work_location: IAddress
//   user_tags: IUserTag[]
//   first_tag_name: string | null
//   user_roles: UserRole[]
//   first_role_name: string | null
//   total_count: string
//   invite_on_start_date: boolean
//   addresses: IAddress[]
//   personal_address: IAddress | null
//   primary_contact: string | null
//   secondary_contact: string | null
//   user_bank: IBanking
//   user_info: ISocial
//   reporting_manager: ITeamMemberDetails
//   country: ICountry
//   allocated_inventories: IAllocatedInventory[]
//   allocated_inventories_v2: AllocatedInventoryV2[]
//   eventType: string[]
//   work_timezone: IWorkTimezone
//   last_working_day: string
// }
export interface ICompany {
  id: string
  name: string
  slogan: string
  company_pic: string
  created_at: string
}
export interface AllocatedInventoryV2 {
  allocation_date: string
  amount: number
  asset_tag: string
  currency: {
    id: string
    name: string
    symbol: string
  }
  deallocation_status: string
  id: string
  location: {
    city: string
    country: string
  }
  product_details: {
    inventory_product_id: string
    model: string
    product_company: string
    product_type: string
  }
  serial_number: string
}

export interface IOrgTreeResponse {
  data: ITeamMemberDetails[]
  is_array_cyclic: boolean
  success: boolean
}

export interface IEmploymentDataResponse {
  compensations: ICompensation
  contract_type?: string
  direct_reports: ITeamMemberDetails[]
  employee_id?: string
  employment_history: IEmploymentHistory[]
  job_role?: string
  probation_end?: Date
  profile_img_url?: string
  reporting_manager?: ITeamMemberDetails
  start_date?: Date
  tax_code?: string
  tax_id?: string
  user_roles?: UserRole[]
  user_tags?: UserTag[]
  work_location?: IAddress
  work_location_id?: string
  working_days?: number[]
}

export interface ICompensation {
  allowance: IAllowance[]
  salary: ISalary[]
}

export interface IAllowance {
  id: string
  currency: ICurrency
  frequency: string
  type: string
  amount: number
  note: string
  effective_date: string
  created_at: string
  is_current: boolean
  is_upcoming: boolean
  end_date: any
}

export interface ICurrency {
  id: string
  name: string
  symbol: string
}

export interface ISalary {
  id: string
  currency: ICurrency
  frequency: string
  type: string
  amount: number
  note: string
  effective_date: string
  created_at: string
  is_current: boolean
  is_upcoming: boolean
  end_date?: string
}

export interface IEmploymentHistory {
  id: string
  note?: string
  effective_date: string
  end_date?: string
  is_current: boolean
  user_position: IUserPosition
  user_department: IUserDepartment
  reporting_manager: ITeamMemberDetails
}

export interface ITeamEventsResponse {
  message: string
  error: boolean
  meta_data: {
    total_items: number
    items_on_page: number
    page_no: number
  }
  data: {
    events: ITeamEvent[]
    today_events: ITeamEvent[]
  }
}

export interface ITeamEvent {
  id: string
  email: string
  first_name: string
  middle_name?: string
  last_name: string
  phone_number: any
  start_date: string
  join_date: string
  work_email: string
  user_email: string
  country_id: string
  reporting_manager_id: string
  date_of_birth: any
  is_deleted: boolean
  offboard_on: any
  is_work_remote: boolean
  employment_end_date: any
  work_location_id: string
  employee_id: string
  status: string
  probation_end: any
  profile_img_url: any
  first_active_at: any
  work_location: IAddress
  user_tags: any[]
  user_department: IUserDepartment
  user_roles: UserRole[]
  user_position: IUserPosition
  country: ICountry
  work_timezone: IWorkTimezone
  eventType: string[]
}

export interface UserRole {
  role: {
    id: string
    name: string
  }
}

export interface UserTag {
  id: string
  tag: {
    id: string
    name: string
  }
}

export interface IReportingManager {
  id: string
  email: string
  first_name: string
  middle_name?: string
  last_name: string
}

export interface IUserPosition {
  position: {
    id: string
    name: string
  }
  id?: string
  name?: string
}

export interface IUserDepartment {
  department: {
    id: string
    name: string
  }
}

export interface IWorkTimezone {
  name: string
  offset: number
}

export interface IAllocatedInventory {
  allocated_date: string
  id: string
  product_variant: ProductDetailsVariant
  serial_number: string
}

export interface OffboardingTeamMember {
  user_id: string
  inventory_ids: string[]
  date: string
  values: {
    reassign: {
      [key: string]: string
    }
    off_boarding_date: string
    off_boarding_time: string
    handle_date: string
    comments: string
    shipping: string
    shipping_address: string
  }
}

export enum OFFBOARD_TYPE {
  ASSIGN = 'assign',
  HQ = 'hq',
  STORE = 'store',
  SELL = 'sell',
}
export enum ASSET_HANDLING_METHOD {
  REASSIGN = 'reassign',
  REASSIGN_AND_SHIP = 'reassign_and_ship',
  REASSIGN_LATER = 'reassign_later',
  SEND_TO_HQ = 'send_to_hq',
  STORE = 'store',
  SELL = 'sell',
}

export interface AddTeamMember {
  first_name: string
  middle_name?: string
  last_name: string
  work_email?: string
  personal_email?: string
  reporting_manager_id?: string
  start_date: string
  tag_ids?: string[]
  reporting_manager?: string
  join_date?: string
  job_title?: string
  employee_id?: string
  place?: string
  country_id?: string
  send_invite_today?: boolean
}

export interface activateUser {
  first_name: string
  middle_name?: string
  last_name: string
  work_email?: string
  personal_email?: string
  reporting_manager_id?: string
  start_date: string
  tag_ids?: string[]
  reporting_manager?: string
  join_date?: string
  job_title?: string
  employee_id?: string
  place?: string
  country_id?: string
  send_invite_today?: boolean
}

interface ICountry {
  country_code: number
  id: string
  name: string
  dial_code: string
}

interface ISocial {
  id?: string
  bio?: string | null
  linkedin?: string | null
  github?: string | null
  twitter?: string | null
}

interface IUserInfo {
  id?: string
  bio?: string | null
  linkedin_id?: string | null
  github_id?: string | null
  twitter_id?: string | null
}

interface IBanking {
  bank_name?: string
  ac_name?: string
  ac_no?: string
  routing_no?: string
  sorting_code?: string
  swift?: string
}

interface IHealth {
  user_allergies: Array<{allergy: IUserAllergies}>
  dietary_preference: string | null
}

export interface IUserAllergies {
  id?: string
  user_id?: string
  allergy_id?: string
  name?: string
  allergy?: IAllergies[]
}

export interface IAllergies {
  id?: string
  name?: string
}

// todo confirm types
export interface IUserTag {
  tag_id: string
  id: string
  tag: {
    id: string
    name: string
  }
}

export interface IPersonal {
  first_name: string
  middle_name?: string
  last_name: string
  email: string
  dial_code: string
  personal_phone_number: string | null
  phone_number: string | null
  work_email: string
  country_id: string
  company_id: string
  start_date: string
  date_of_birth: string
  country: ICountry
  gender: string
  hide_work_email: boolean
  spoken_languages: string[]
  nationalities: string[]
}

export interface IProfileResponse {
  data: IProfile
  error: boolean
  message: string
  meta_data: null
}

export interface IProfile {
  health: IHealth | null
  id: string
  personal: IPersonal
  personal_address: IAddress | null
  role?: string
  social: ISocial | null
  user_bank: IBanking | null
  user_department?: IUserDepartment
  user_position?: IUserPosition
  user_tags?: UserTag[]
}

export interface IAllergiesResponse {
  data: IAllergy[]
  error: boolean
  message: string
  meta_data: {
    total_items: number
    page_no: number
    items_on_page: number
  }
}

export interface IAllergy {
  id: string
  name: string
}

export interface IOffboardingDetails {
  employment_end_date: string
  last_working_date: string
  offboard_note: string
  offboard_reason: string
  offboard_attachment: [{file_name: string; doc_link: string}]
  status: string
  inventories?: IOffboardingDeallocation[]
}

export interface IResginationFile {
  file_name?: string
  url?: string
}

export interface IOffboardingDeallocation {
  id: string
  name: string
  asset_tag: string
  serial_number: string
  retrieval: DEVICE_HANDLING_METHOD
  physical_check: YES_NO
  physical_clean: YES_NO
  data_handling: DATA_HANDLING_METHOD
  deallocation_date: string
  pickup_address: Address
  pickup_contact: string
  shipping_address: Address
  delivery_contact: string
}

export interface IInventory {
  contract_type?: CONTRACT_TYPE
  id?: string
  product_variant?: IProductVariant
  serial_number?: string
}

export interface IProductVariant {
  color: string
  config: {
    CPU?: string
    DISPLAY?: string
    RAM?: string
    STORAGE?: string
  }
  id?: string
  images?: IProductVariantImage[]
  operating_system?: OS
  product?: IProduct
  variant_specification?: string

  configuration: string
}

export interface IProduct {
  company_id?: string
  id?: string
  product_company?: ProductCompany
  product_type?: ProductType
  title?: string
}

export interface IProductVariantImage {
  uploaded_document?: {
    location?: string
  }
}

export interface IUserJobPosition {
  id: string
  name: string
}

export enum LINK_TYPE {
  LINKEDIN = 'linkedin',
  GITHUB = 'github',
  TWITTER = 'twitter',
  LINKEDIN_ID = 'linkedin_id',
  GITHUB_ID = 'github_id',
  TWITTER_ID = 'twitter_id',
}

export interface IProfileProgress {
  total: number
  filled: number
  progress: number
}

export type UserRoleResponse = {
  reporting_manager: ITeamMemberDetails
  role: string
  user_position: IUserPosition
  user_department: IUserDepartment
  user_tags: IUserTag[]
  position_note: string
  position_effective_date: string
}

export interface IWebstoreTeam {
  id: string
  first_name: string
  middle_name?: string
  last_name: string
  email: string
  work_email: string
  profile_img_url: any
  phone_number: string
  personal_phone_number: any
  country_id: string
  name: string
  created_at: string
  is_deleted: boolean
  status: string
  personal_address: PersonalAddress
  allocated_inventories: any[]
  allocated_inventories_v2: AllocatedInventoriesV2[]
}

interface IAddress {
  id: string
  address_line1: string
  address_line2?: string
  label: null
  city: string
  state: string
  postal_code: string
  country: {
    name: string
    id: string
  }
  place?: string
}

export interface PersonalAddress {
  label: any
  address_line1: string
  address_line2: any
  city: string
  state: string
  postal_code: string
  unit_number: any
  user_id: string
  created_at: string
  updated_at: string
  id: string
  country_id: string
  company_id: string
  order_id: any
  contact_number: any
  address_type: string
  recipient_name: any
  time_away_policy_id: any
}

export interface AllocatedInventoriesV2 {
  id: string
  mdm: boolean
  notes: any
  status: string
  location: Location
  asset_tag?: string
  company_id: string
  created_at: string
  created_by?: string
  deleted_at: any
  updated_at?: string
  allocated_to: string
  order_item_id?: string
  serial_number?: string
  archived_notes: any
  archived_reason: any
  archived_details: any
  product_variant_id?: string
  inventory_finance_id: string
  inventory_product_id: string
}

export interface Location {
  city: string
  type: string
  country_id: string
  country_name?: string
}

export enum USER_INVITE {
  IMMEDIATELY = 'immediately',
  START_DATE = 'start_date',
  NEVER = 'never',
}

/**
 * GET /users/team/count
 * Returns TeamMembersCountResponse[]
 */
export interface TeamMembersCountResponse {
  label: string
  value: string
  count: number
  tooltip: string
}

export interface SubSectionDetailsResponse {
  default: FieldType[]
  custom_fields: FieldType[]
  additional_data: any
}

export interface TeamBulkUploadMappingResponse {
  fields: TeamBulkUploadField[]
  mapping_values: TeamBulkUploadMappingValue[]
}

export interface TeamBulkUploadField {
  value: string
  label: string
  mapped_value: TeamBulkUploadMappedValue
  is_required: boolean
}

export interface TeamBulkUploadMappedValue {
  value: string
  label: string
}

export interface TeamBulkUploadMappingValue {
  value: string
  label: string
}
export interface NewHireDetails {
  hiring_template_id: string
  employment_type: EMPLOYMENT_TYPE
}

export interface ReHireDetails {
  hiring_template_id: string
  employment_type: EMPLOYMENT_TYPE
  inactive_user_id: string
}

export interface ActivateDetails {
  inactive_user_id: string
  employment_type: EMPLOYMENT_TYPE
  hiring_template_id: string
}

export interface NewHireCache {
  template_id: string
  employment_type: EMPLOYMENT_TYPE
  categories: {position: number; category_id: string; sub_category: TabSubCategoryFields[]}[]
}

export interface ReHireCache {
  template_id: string
  employment_type: EMPLOYMENT_TYPE
  inactive_user_id: string
  categories: string[]
  data: {position: number; category_id: string; sub_category: TabSubCategoryFields[]}[]
}

export interface ActivateCache {
  template_id: string
  employment_type: EMPLOYMENT_TYPE
  inactive_user_id: string
  categories: string[]
  data: {position: number; category_id: string; sub_category: TabSubCategoryFields[]}[]
}

export enum EMPLOYMENT_TYPE {
  DIRECT_EMPLOYEE = 'direct_employee',
  EOR = 'eor',
  CONTRACTOR = 'contractor',
}

export enum EMPLOYMENT_CONTRACT_TYPE {
  FULL_TIME = 'full_time',
  PART_TIME = 'part_time',
  FIXED_TERM = 'fixed_term',
}

export enum MARITAL_STATUS_TYPE {
  MARRIED = 'married',
  SINGLE = 'single',
  DOMESTIC_PARTNERSHIP = 'domestic_partnership',
  WIDOWED = 'widowed',
  DIVORCED = 'divorced',
}

// org chart

// GET /api/users/org-chart
export interface OrgChartResponse {
  success: boolean
  data: OrgChart[]
}

export interface OrgChart {
  id: string
  parentId: null | string
  first_name: string
  _directSubordinates: number
  _totalSubordinates: number
  middle_name?: string
  last_name?: string
  profile_img_url?: null
  reporting_manager_id?: null
  user_position?: {
    id: string
    position: {
      id: string
      name: string
    }
  }
  company_profile_url: string | null
  company_name: string | null
}
