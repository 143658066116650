export const ICONS = {
  // todo legacy icons needs to be replaced with new ones
  legacy: {
    externalLink: '/icons/_legacy/external-link.svg',
  },
  calendar: '/icons/calendar/calendar.svg',
  calendarDotGrid2: '/icons/calendar/calendar-dot-grid-2.svg',
  calendarGrid: '/icons/calendar/calendar-grid.svg',
  calendarPlus: '/icons/calendar/calendar-plus.svg',
  custom: {
    apps: '/icons/custom/apps.svg',
    checkCircleFilled: '/icons/custom/check-cirlce-filled.svg',
    checkCircle: '/icons/custom/check-circle.svg',
  },
  chevronLeft: '/icons/arrows/chevron-left.svg',
  chevronRight: '/icons/arrows/chevron-right.svg',
  chevronDown: '/icons/arrows/chevron-down.svg',
  undoCircle: '/icons/arrows/undo-circle.svg',
  arrowLeft: '/icons/arrows/arrow-left.svg',
  arrowRight: '/icons/arrows/arrow-right.svg',
  arrowCollapseDown: '/icons/arrows/arrow-collapse-down.svg',
  arrowCollapseDownDark: '/icons/arrows/arrow-collapse-down-dark.svg',
  userInterface: {
    moreMenuVertical: '/icons/user-interface/more-menu-vertical.svg',
    moreMenuHorizontal: '/icons/user-interface/more-menu-horizontal.svg',
    restricted: '/icons/user-interface/restricted.svg',
    infoCircle: '/icons/user-interface/info-circle.svg',
    draggable: '/icons/user-interface/draggable.svg',
  },
  deleteBin2: '/icons/user-interface/delete-bin-2.svg',
  deleteBin3: '/icons/user-interface/delete-bin-3.svg',
  sidebar: {
    dashboard: {
      root: '/icons/sidebar/dashboard.svg',
      rootActive: '/icons/sidebar/dashboard-active.svg',
    },
    people: {
      root: '/icons/sidebar/people.svg',
      team: '/icons/user/users-group-3.svg',
      whoIsAway: '/icons/charts/chart-timeline-box.svg',
      document: '/icons/sidebar/document.svg',
    },
    zenHR: {
      root: '/icons/sidebar/user.svg',
      rootActive: '/icons/sidebar/user-active.svg',
      employeeDirectory: '/icons/sidebar/user-search.svg',
      employeeDirectoryActive: '/icons/sidebar/user-search-active.svg',
      leaveManagement: '/icons/sidebar/airplane-2-clock.svg',
      leaveManagementActive: '/icons/sidebar/airplane-2-clock-active.svg',
      documents: '/icons/sidebar/document-paper.svg',
      documentsActive: '/icons/sidebar/document-paper-active.svg',
    },
    zenIT: {
      root: '/icons/devices/laptop-phone.svg',
      rootActive: '/icons/sidebar/laptop-phone-active.svg',
      inv: '/icons/logistic/warehouse.svg',
      invActive: '/icons/sidebar/warehouse-active.svg',
      software: '/icons/sidebar/apps-software.svg',
      softwareActive: '/icons/sidebar/apps-software-active.svg',
      dm: '/icons/sidebar/mdm.svg',
      dmActive: '/icons/sidebar/mdm-active.svg',
      webstore: '/icons/logistic/globe-parcel.svg',
      webstoreActive: '/icons/sidebar/globe-parcel-active.svg',
      orders: '/icons/sidebar/order.svg',
      ordersActive: '/icons/sidebar/order-active.svg',
      itSupport: '/icons/sidebar/it-support.svg',
      itSupportActive: '/icons/sidebar/chat-active.svg',
    },
    zenTools: {
      root: '/icons/tools/wrench-box-2.svg',
      rootActive: '/icons/sidebar/wrench-box-2-active.svg',
      workflow: '/icons/development/flow-2.svg',
      workflowActive: '/icons/sidebar/flow-2-active.svg',
      tasks: '/icons/user-interface/list-box.svg',
      tasksActive: '/icons/sidebar/list-box-active.svg',
      checklist: '/icons/user-interface/check-list-2.svg',
      checklistActive: '/icons/sidebar/check-list-2-active.svg',
      report: '/icons/clipboards/clipboard-chart-bar.svg',
      reportActive: '/icons/sidebar/clipboard-chart-bar-active.svg',
    },
    devices: {
      root: '/icons/sidebar/devices.svg',
      inventory: '/icons/logistic/clipboard-package.svg',
      webstore: '/icons/buildings/home-smile.svg',
      orders: '/icons/logistic/parcel-arrow.svg',
      mdm: '/icons/devices/laptop-phone.svg',
    },
    software: {
      root: '/icons/sidebar/software.svg',
      itSupport: '/icons/devices/headset.svg',
      software: '/icons/devices/cpu.svg',
    },
    tools: {
      root: '/icons/sidebar/tools.svg',
      tasks: '/icons/custom/check-circle.svg',
      checklists: '/icons/user-interface/check-list.svg',
      workflows: '/icons/user-interface/lightning-bolt.svg',
      reports: '/icons/files/dots.svg',
    },
    appStore: {
      root: '/icons/sidebar/apps.svg',
      rootActive: '/icons/sidebar/apps-active.svg',
    },
    settings: {
      root: '/icons/sidebar/settings.svg',
      rootActive: '/icons/sidebar/settings-active.svg',
    },
    timeAway: {
      rootActive: '/icons/sidebar/calendar-event-active.svg',
    },
    profileDropdown: {
      bellActive: '/icons/sidebar/bell-active.svg',
      doorExitActive: '/icons/sidebar/door-exit-active.svg',
      changePassword: '/icons/sidebar/profile-dropdown/form-password.svg',
    },
    caretDown: '/icons/sidebar/caret-down.svg',
    caretUp: '/icons/sidebar/caret-up.svg',
  },
  astronomy: {
    planetRingStars: '/icons/astronomy/planet-ring-stars.svg',
    sparkleStars2: '/icons/astronomy/sparkle-stars-2.svg',
  },
  editor: {
    editPenCircle2: '/icons/editor/edit-pen-circle-2.svg',
  },
  editPen2: '/icons/editor/edit-pen-2.svg',
  editPen3: '/icons/editor/edit-pen-3.svg',
  maps: {
    mapPinFill: '/icons/maps/map-pin-fill.svg',
  },
  delete: {
    deleteOutline: '/icons/delete/delete-bin-outline.svg',
    deleteOutlineGray: '/icons/delete/delete-bin-outline-gray.svg',
  },
  user: {
    userGroupOutlineBlue: '/icons/user/users-outline-blue.svg',
    userCircleSearch: '/icons/user/user-circle-search.svg',
    usersGroup2: '/icons/user/users-group-2.svg',
    users: '/icons/user/users.svg',
    userBlock: '/icons/user/user-block.svg',
    usersGroupNew: '/icons/user/users-group-new.svg',
    usersNewHire: '/icons/user/user-new-hire.svg',
    usersUpload: '/icons/user/user-upload.svg',
  },
  userExclamation: '/icons/user/user-exclamation.svg',
  archive: '/icons/interior/archive.svg',
  checkedCircleOutlined: '/icons/checked/check-circle-outlined.svg',
  circleClosed: '/icons/checked/close-circle.svg',
  infoCircle: '/icons/info/info-circle.svg',
  fileVertical: '/icons/files/file-vertical.svg',
  document: '/icons/files/document.svg',
  closeBox: '/icons/inv/close-box.svg',
  deleteBinPermanent: '/icons/inv/delete-bin-3-permanent.svg',
  handCoins: '/icons/inv/hand-coins.svg',
  heart: '/icons/inv/heart.svg',
  pinwheel: '/icons/inv/pinwheel.svg',
  questionOctagon: '/icons/inv/question-octagon.svg',
  rotateLeft: '/icons/inv/rotate-left.svg',
  settingCogCheck: '/icons/inv/settings-cog-check.svg',
  laptop2: '/icons/inv/laptop-2.svg',
  interior: {
    archive: '/icons/interior/archive.svg',
  },
  checked: {
    checkedCircleOutlined: '/icons/checked/check-circle-outlined.svg',
    circleClosed: '/icons/checked/close-circle.svg',
  },
  charts: {
    chartBar: '/icons/charts/chart-bar.svg',
  },
  warehouseEmptyGradient: '/icons/logistic/warehouse-empty-gradient.svg',
  files: {
    fileVertical: '/icons/files/file-vertical.svg',
    document: '/icons/files/document.svg',
  },
  media: {
    surroundSoundSetup: '/icons/media/surround-sound-setup.svg',
  },
  folderUpload: '/icons/folders/folder-upload.svg',
  browserCode: '/icons/development/browser-code.svg',
  fileUpload: '/icons/files/file-upload.svg',
  plusCircle: '/icons/user-interface/plus-circle.svg',
  textboxEdit: '/icons/user-interface/textbox-edit.svg',
  minusCircle: '/icons/user-interface/minus-circle.svg',
  userMinus: '/icons/user/user-minus.svg',
  userPlus: '/icons/user/user-plus.svg',
  userCircle2: '/icons/user/user-circle-2.svg',
  boxPlus: '/icons/interior/box-plus.svg',
  laptop: '/icons/devices/laptop-2.svg',
  noteTextReload: '/icons/files/note-text-reload.svg',
  login2HalfCircle: '/icons/user-interface/login-2-half-circle.svg',
  logoutHalfCircle: '/icons/user-interface/logout-half-circle.svg',
  checkmark: '/media/icons/settings/checkmark.svg',
  devices: {
    monitorMove: '/icons/devices/monitor-move.svg',
    monitor: '/icons/devices/monitor.svg',
  },
  monitor: '/icons/devices/monitor.svg',
  smartphone: '/icons/devices/smartphone.svg',
  tablet: '/icons/devices/iphone-old.svg',
  peripheralDevice: '/icons/devices/usb-symbol.svg',
  attachment: '/icons/files/attachment.svg',
  document2: '/icons/files/document2.svg',
  arrowRightDown: '/icons/arrows/arrow-right-down.svg',
  googleCalendar: '/icons/calendar/google-calendar.svg',
  warehouseEmpty: '/icons/logistic/warehouse-empty.svg',
  server4settings: '/icons/devices/server-4-settings.svg',
  monitorSwap: '/icons/devices/monitor-swap.svg',
  monitorPhone: '/icons/devices/monitor-phone.svg',
  valid: '/icons/inv/valid.svg',
  boxPlusWhite: '/icons/inv/box-plus-white.svg',
  swap: '/icons/custom/swap-horizontal.svg',
  command2: '/icons/development/command-2.svg',
  note: '/icons/notes/note.svg',
  user1: '/icons/user/user.svg',
  calendarClock: '/icons/calendar/calendar-clock.svg',
  editPenBox2: '/icons/inv/edit-pen-box-2.svg',
  monitorDesktop: '/icons/inv/monitor-desktop.svg',
  scanLines: '/icons/scans/scan-lines.svg',
  cpuSettings: '/icons/inv/cpu-settings.svg',
  hardDrive2: '/icons/inv/hard-drive-2.svg',
  noteTextCheck: '/icons/inv/note-text-check.svg',
  money: '/icons/money/money.svg',
  thunderbolt: '/icons/devices/thunderbolt.svg',
  keyboard: '/icons/devices/keyboard.svg',
  mouse: '/icons/devices/mouse.svg',
  webcam: '/icons/devices/webcam.svg',
  browser: '/icons/devices/browser.svg',
  microphone: '/icons/devices/microphone.svg',
  serverSignal: '/icons/devices/server-signal.svg',
  printer: '/icons/devices/printer.svg',
  ticket2text: '/icons/tickets/ticket-2-text.svg',
  wrenchBox2: '/icons/tools/wrench-box-2.svg',
  pin: '/icons/tools/pin.svg',
  search: '/icons/user-interface/search.svg',
  noteBlockText: '/icons/notes/noteblock-text.svg',
  fileCopy: '/icons/files/file-copy.svg',
  open: '/icons/user-interface/open.svg',
  laptopPhone: '/icons/devices/laptop-phone.svg',
  cdDriveOff: '/icons/devices/cd-drive-off.svg',
  signalStation: '/icons/devices/signal-station.svg',
  user2: '/icons/user/users-2.svg',
  repeatCircleHorizontal: '/icons/arrows/repeat-circle-horizontal.svg',
  redCloseCircle: '/icons/math/close-circle-red.svg',
  mapMarker: '/icons/maps/map-marker.svg',
  greenCheckCircle: '/icons/custom/green-check-circle.svg',
  users: '/icons/user/users.svg',
  usersGroupNew: '/icons/user/users-group-new.svg',
  userBlock: '/icons/user/user-block.svg',
  applicationPlus: '/icons/development/application-plus.svg',
  remote: '/icons/devices/remote.svg',
  menu: '/icons/user-interface/menu.svg',
  mail: '/icons/mail/mail.svg',
  noteText: '/icons/notes/note-text.svg',
  chat2: '/icons/messaging/chat-2.svg',
  emoji: '/icons/messaging/emoji.svg',
  bookOpen: '/icons/user-interface/book-open.svg',
  bookOpenSearch: '/icons/user-interface/book-open-search.svg',
  phone: '/icons/telecommunication/phone.svg',
  heartHand: '/icons/medical/heart-hand.svg',
  userBoxDots: '/icons/user/user-box-dots.svg',
  creditCardCheck: '/icons/finances/creditcard-check.svg',
  codeBlock: '/icons/development/code-block.svg',
  mapMarkerArea: '/icons/maps/map-marker-area.svg',
  textbox: '/icons/user-interface/textbox.svg',
  mapPinSimple2: '/icons/maps/map-pin-simple-2.svg',
  government: '/icons/buildings/government.svg',
  userCircle: '/icons/user/user-circle.svg',
  barcode: '/icons/user-interface/barcode.svg',
  forkKnife: '/icons/food/fork-knife.svg',
  burgerOff: '/icons/food/burger-off.svg',
  globeEarth2: '/icons/maps/globe-earth-2.svg',
  megaphone: '/icons/user-interface/megaphone.svg',
  atom5: '/icons/science/atom-5.svg',
  network: '/icons/devices/network.svg',
  box: '/icons/interior/box.svg',
  monitorMove: '/icons/devices/monitor-move.svg',
  monitorEye: '/icons/devices/monitor-eye.svg',
  dataCursor: '/icons/data/data-cursor.svg',
  login2: '/icons/arrows/login-2.svg',
  logout: '/icons/arrows/logout.svg',
  scan: '/icons/devices/scan-line.svg',
  battery: '/icons/devices/battery.svg',
  textboxCheck: '/icons/files/textbox-check.svg',
  laptopSettings: '/icons/devices/laptop-settings.svg',
  loginCircle: '/icons/arrows/login-circle.svg',
  users3: '/icons/user/users-3.svg',
  alertCircle: '/icons/user-interface/alert-circle.svg',
  usersFlash: '/icons/user/user-flash.svg',
  eye: '/icons/user-interface/eye.svg',
  flagPattern: '/icons/user-interface/flag-pattern.svg',
  listBoxSearch: '/icons/user-interface/list-box-search.svg',
  moneySearch: '/icons/money/money-search.svg',
  cutlery: '/icons/food/cutlery.svg',
  file: '/icons/files/file.svg',
  filterLines: '/icons/user-interface/filter-lines.svg',
  lock: '/icons/user-interface/lock.svg',
  flag: '/icons/user-interface/flag.svg',
  close: '/icons/math/close.svg',
  shield: '/icons/security/shield.svg',
  alertTriangle: '/icons/user-interface/alert-triangle.svg',
  minus: '/icons/math/minus.svg',
  plus: '/icons/math/plus.svg',
  arrowsExpandFull: '/icons/arrows/arrows-expand-full.svg',
  usersStage: '/icons/user/users-stage.svg',
  userCheck: '/icons/user/user-check.svg',
  userTie: '/icons/user/user-tie.svg',
  userExternal: '/icons/user/user-external.svg',
  settingsCog: '/icons/user-interface/settings-cog.svg',
  sitemap: '/icons/development/sitemap.svg',
  inboxExport: '/icons/mail/inbox-export.svg',
  moneyHand: '/icons/money/money-hand.svg',
  smartPhoneOff: '/icons/smartphones/smartphone-off.svg',
  bug: '/icons/nature/bug.svg',
  shoppingCart: '/icons/shopping/shopping-cart.svg',
  viewGridBox: '/icons/views/view-grid-box.svg',
  viewRows: '/icons/views/view-rows.svg',
  monitorOff: '/icons/devices/monitor-off.svg',
  cloudPlus: '/icons/cloud/cloud-plus.svg',
  reload2: '/icons/arrows/reload-2.svg',
  dataDelete: '/icons/data/data-delete.svg',
  laptopSearch: '/icons/devices/laptop-search.svg',
  mapMarkerOff: '/icons/maps/map-marker-off.svg',
  share2: '/icons/info/share-2.svg',
  mapMarkerLocation: '/icons/maps/map-marker-location.svg',
  powerCircle: '/icons/user-interface/power-circle.svg',
  powerCircle2: '/icons/user-interface/power-circle-2.svg',
  unlockOpen: '/icons/user-interface/unlock-open.svg',
  check: '/icons/custom/check.svg',
  bell: '/icons/user-interface/bell.svg',
  bellBlack: '/icons/user-interface/bell-black.svg',
  whatsNew: '/icons/user-interface/megaphone-alt.svg',
  atomic2: '/icons/science/atomic-2.svg',
  userGroup3: '/icons/user/users-group-3.svg',
  clipboardPackage: '/icons/logistic/clipboard-package.svg',
  cpu: '/icons/devices/cpu.svg',
  checklist: '/icons/user-interface/check-list.svg',
  link2Circle: '/icons/user-interface/link-2-circle.svg',
  multiplyCircle: '/icons/math/multiply-circle.svg',
  threeDots: '/media/icons/teams/three-dots.svg',
  calendarSettings: '/icons/calendar/calendar-settings.svg',
  building: '/icons/buildings/building.svg',
  userOff: '/icons/user/user-off.svg',
  apps: '/icons/custom/apps.svg',
  swapVertical: '/icons/arrows/swap-vertical.svg',
  clipboardPlus: '/icons/clipboards/clipboard-plus.svg',
  arrowCollapseRight: '/icons/arrows/arrow-collapse-right.svg',
  arrowUp: '/icons/arrows/arrow-up.svg',
  arrowDown: '/icons/arrows/arrow-down.svg',
  chevronDoubleRight: '/icons/arrows/chevron-double-right.svg',
  chevronDoubleLeft: '/icons/arrows/chevron-double-left.svg',
  checkCircle: '/icons/custom/check-circle.svg',
  lightningBolt: '/icons/user-interface/lightning-bolt.svg',
  hourglass2Half: '/icons/time/hourglass-2-half.svg',
  closeCircle: '/icons/math/close-circle.svg',
  arrowRightDecision: '/icons/arrows/arrow-decision-right.svg',
  clock: '/icons/time/clock.svg',
  trendUp: '/icons/charts/trend-up.svg',
  trendDown: '/icons/charts/trend-down.svg',
  clipboardSave: '/icons/clipboards/clipboard-save.svg',
  parcelArrow: '/icons/logistic/parcel-arrow.svg',
  speedometer3: '/icons/vehicles/speedometer-3.svg',
  clockFlash: '/icons/time/clock-flash.svg',
  merge: '/icons/arrows/merge.svg',
  creditCardFlash: '/icons/finances/creditcard-flash.svg',
  gitPullPlus: '/icons/development/git-pull-plus.svg',
  suitCaseCheck: '/icons/shopping/suitcase-check.svg',
  clockPlus: '/icons/time/clock-plus.svg',
  userFlash1: '/icons/user/user-flash1.svg',
  link2Angled: '/icons/user-interface/link-2-angled.svg',
  link2AngledOff: '/icons/user-interface/link-2-anlged-off.svg',
  listBoxEdit: '/icons/user-interface/list-box-edit.svg',
  restricted2Square: '/icons/user-interface/restricted-2-square.svg',
  editPen5: '/icons/editor/edit-pen-5.svg',
  clipboardText2: '/icons/clipboards/clipboard-text-2.svg',
  userCircleClock: '/icons/user/user-circle-clock.svg',
  clockCheck: '/icons/time/clock-check.svg',
  clipboardCheck: '/icons/clipboards/clipboard-check.svg',
  hexnode: '/icons/software/hexnode.svg',
  miradore: '/icons/software/miradore.svg',
  chip: '/icons/software/chip.svg',
  infoCircleWhat: '/icons/info/info-circle-what.svg',
  calendarEvent: '/icons/calendar/calendar-event.svg',
  doorExit: '/icons/interior/door-exit.svg',
  totalTickets: '/modules/it-support/total-tickets.svg',
  planetRingStars: '/icons/science/planet-ring-stars.svg',
  cloudUpload: '/icons/cloud/cloud-upload.svg',
  lightingBulbShine: '/icons/devices/lighting-bulb-shine.svg',
  customField: '/icons/files/custom-field.svg',
  eyeOff: '/icons/user-interface/eye-off.svg',
  checkedCircleFilled: '/icons/custom/check-cirlce-filled.svg',
  gender: '/icons/user/user-gender.svg',
  ring: '/icons/user/user-ring.svg',
  flow2: '/icons/development/flow-2.svg',
  scanMenu: '/icons/scans/scan-menu.svg',
  linkedin: '/icons/socials/linkedin-icon.svg',
  x: '/icons/socials/x-icon.svg',
  github: '/icons/socials/github-icon.svg',
  infoTriangle: '/icons/info/info-triangle.svg',
  jpg: '/icons/upload/jpg.svg',
  jpeg: '/icons/upload/jpeg.svg',
  png: '/icons/upload/png.svg',
  webp: '/icons/upload/webp.svg',
  doc: '/icons/upload/doc.svg',
  csv: '/icons/upload/csv.svg',
  xls: '/icons/upload/xls.svg',
  pdf: '/icons/upload/pdf.svg',
  fileMultiple: '/icons/files/file-multiple.svg',
  // ---------------------------------
  _ADD_ICONS_ABOVE_THIS_TO_AVOID_MERGE_CONFLICTS: '🔼🔼🔼🔼🔼🔼🔼🔼',
  // ---------------------------------
}
