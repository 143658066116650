import {ButtonV2} from '@hybr1d-tech/charizard'
import {isDev} from 'app/configs/config'
import {toAbsoluteUrl} from 'hybrid-ui/helpers'

export default function ErrorBoundaryFallback({error, resetErrorBoundary}) {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`}}
      >
        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20">
          <div className="d-flex align-items-center justify-content-center">
            <a href="https://www.zenadmin.ai" className="mb-8">
              <img alt="Logo" src={toAbsoluteUrl('/assets/logo-full.svg')} className="h-80px" />
            </a>
          </div>
          <div className="pt-lg-10 mb-10">
            <div role="alert" className="mb-10">
              <h1 className="mb-5">Something went wrong 😞</h1>
              {isDev && <pre>{error.message}</pre>}
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <ButtonV2 onClick={resetErrorBoundary}>Go to homepage</ButtonV2>
            </div>
          </div>
          <div
            className="
          d-flex
          flex-row-auto
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-bottom
          min-h-100px min-h-lg-350px
        "
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}
          ></div>
        </div>

        <div className="d-flex flex-center flex-column-auto p-10">
          <div className="d-flex align-items-center fw-bold fs-6">
            <a href="https://www.zenadmin.ai/" className="text-muted text-hover-primary px-2">
              About
            </a>
            <a
              href="https://www.zenadmin.ai/contact-us"
              className="text-muted text-hover-primary px-2"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
