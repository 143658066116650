import * as React from 'react'
import * as accordion from '@zag-js/accordion'
import clsx from 'clsx'
import classes from './styles.module.css'
import {motion} from 'framer-motion'
import {useMachine, normalizeProps} from '@zag-js/react'
import {useModulesStore} from 'app/store/integrations'
import {useSidebarMinimized} from 'app/store/layout'
import {useCurrentUser} from 'app/store/auth'
import {getModuleBasedItem, getModuleHeader, getRoleBasedMenuItem} from '../authorized-sidebar-menu'
import {EXCEPTION_COMPANY_IDS} from 'app/routing/getters'

export default function SidebarMenu({isMobile = false}: {isMobile?: boolean}) {
  const modules = useModulesStore(s => s.modules)
  const currentUser = useCurrentUser()
  const isMini = useSidebarMinimized()
  const showV2Route =
    currentUser?.company.id && EXCEPTION_COMPANY_IDS.includes(currentUser?.company.id)
  const [state, send] = useMachine(
    accordion.machine({
      id: 'zenadmin-sidebar',
      collapsible: true,
      multiple: true,
    }),
  )

  const api = accordion.connect(state, send, normalizeProps)

  const contentRefs = React.useRef<{[key: string]: HTMLDivElement | null}>({})

  const setContentRef = (key: string, node: HTMLDivElement | null) => {
    contentRefs.current[key] = node
  }

  // Collapse all items when sidebar is minimized
  React.useEffect(() => {
    if (isMini) {
      api.setValue([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMini])

  return (
    <div
      {...api.getRootProps()}
      className={!isMini && !isMobile ? classes.menuOverflow : undefined}
      style={{display: 'flex', flexDirection: 'column', gap: '10px', width: '100%'}}
    >
      {getRoleBasedMenuItem(currentUser?.role, isMobile, modules, showV2Route)?.map((item, idx) => {
        const itemState = api.getItemState({value: item.name})
        const contentHeight = contentRefs.current[item.name]?.scrollHeight || 'auto'

        return (
          <div {...api.getItemProps({value: item.name})} key={idx} style={{width: '100%'}}>
            {getModuleHeader(item) && (
              <button
                {...api.getItemTriggerProps({value: item.name})}
                className={clsx('hui-reset-btn', classes.triggerBtn)}
                disabled={isMini && !isMobile}
              >
                {item.component}
              </button>
            )}

            {item.menuItems?.length > 0 && (
              <motion.div
                key={item.name}
                initial={{height: 0, opacity: 0}}
                animate={{
                  height: itemState.expanded ? contentHeight : 0,
                  opacity: itemState.expanded ? 1 : 0,
                }}
                transition={{
                  type: 'spring',
                  stiffness: 177.8,
                  damping: 20,
                }}
              >
                <div
                  {...api.getItemContentProps({value: item.name})}
                  ref={node => setContentRef(item.name, node)}
                  style={{display: isMini && !isMobile ? 'none' : undefined}}
                  className={classes.accordionContent}
                >
                  {item.menuItems?.map((menuItem, idx) => (
                    <div key={`${menuItem.name}-${idx}`}>
                      {getModuleBasedItem(modules, menuItem, item.name, item.menuItems)}
                    </div>
                  ))}
                </div>
              </motion.div>
            )}
          </div>
        )
      })}
    </div>
  )
}
