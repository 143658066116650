import clsx from 'clsx'
import classes from './styles.module.css'
import {SVG, Tooltip} from '@hybr1d-tech/charizard'
import {Link, useLocation} from 'react-router-dom'
import {checkIsActive} from 'hybrid-ui/helpers'
import {useGetMetaData} from 'app/services'
import {useLayoutActions, useSidebarMinimized} from 'app/store/layout'
import {utilityKeys} from 'app/services/query-key-factory'

interface SidebarMenuItemProps {
  to: string
  title: string
  icon?: {default: string; active: string}
  children?: React.ReactNode
  isMobile?: boolean
  name: string
  _key: string
  isCollapsed?: boolean
}

export default function SidebarMenuItem({
  children,
  to,
  title,
  icon,
  isMobile = false,
  name,
  _key,
  isCollapsed,
}: SidebarMenuItemProps) {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, _key)
  const isMini = useSidebarMinimized()
  const {toggleMini} = useLayoutActions()
  const {meta} = useGetMetaData(utilityKeys.metaInfoTasks())

  return (
    <div
      className={clsx(
        classes.menu,
        isActive && classes.menuActive,
        isCollapsed && classes.collapsed,
      )}
      onClick={() => {
        if (!isMini) toggleMini()
      }}
    >
      <Link to={to}>
        <div className={clsx(classes.link, isActive && classes.activeLink)} style={{paddingLeft: isMini ? '15px' : undefined}}>
          <div className={classes.textIcon}>
            {icon && (
              <div className={classes.logoBox}>
                <SVG
                  path={icon.default}
                  svgClassName={clsx(classes.logo, isActive && classes.logoCurrActive)}
                />
                <SVG
                  path={icon.active}
                  svgClassName={clsx(classes.logoActive, isActive && classes.logoCurrActive)}
                />
              </div>
            )}

            <span
              className={clsx(classes.title, isActive && classes.activeTitle)}
              style={{maxWidth: isMini ? '200px' : undefined}}
            >
              {title}
            </span>
          </div>

          {title.toLowerCase().includes('task') && meta?.tasks && +meta?.tasks.total_tasks > 0 ? (
            <Tooltip key={'pending_task12'} placement="right">
              <Tooltip.Trigger key={`pending_task1234`}>
                <div className={classes.badge}>{meta?.tasks.total_tasks}</div>
              </Tooltip.Trigger>
              <Tooltip.Content>
                <div style={{color: 'white', background: 'var(--text-primary)'}}>Pending task</div>
              </Tooltip.Content>
            </Tooltip>
          ) : null}
        </div>
      </Link>
      {children}
    </div>
  )
}
