import {acronymRegex, specialCharRegex} from '../constants/regex'

export const pluralize = (count: number, singular: string, plural: string) => {
  return count === 1 || count === 0 || count === undefined || count === null ? singular : plural
}

export const truncate = (text: string, max?: number) => {
  if (!max) {
    return text
  }
  return text.length > max ? `${text.substring(0, max)}...` : text
}

export const standardizeString = (message: string): string => {
  const words = message.split(' ')
  const prefixWords = words.slice(0, -1)
  const field = words.slice(-1)[0]
  const isSpecial = specialCharRegex.test(field) || acronymRegex.test(field)
  const standardizedField = isSpecial ? field : field.toLowerCase()
  const prefix = prefixWords.join(' ')
  const standardizedPrefix = prefix
    ? prefix.charAt(0).toUpperCase() + prefix.slice(1).toLowerCase()
    : ''
  const standardizedString = prefix
    ? `${standardizedPrefix} ${standardizedField}`
    : standardizedField.charAt(0).toUpperCase() + standardizedField.slice(1)

  return standardizedString
}
