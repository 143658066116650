import * as React from 'react'
import HUIInputGroup from 'hybrid-ui/components/hui-input-group'
import HUICheckbox from 'hybrid-ui/components/hui-checkbox'
import ReactCountryFlag from 'react-country-flag'
import HUISpinner from 'hybrid-ui/components/hui-spinner'
import classes from './styles.module.scss'
import {useCountry} from 'app/utils/hooks'
import {SVG} from '@hybr1d-tech/charizard'

function FourthStep({selectedCountries, onCountrySelect}) {
  const {countryOptions, getCountryInfo} = useCountry()
  const [searchText, setSearchText] = React.useState('')

  function handleCountrySearch(e) {
    setSearchText(e.target.value)
  }

  function handleCountrySelect(add, countryId) {
    if (add) {
      onCountrySelect([...selectedCountries, countryId])
      return
    }
    onCountrySelect(preState => preState.filter(id => id !== countryId))
  }
  return (
    <div className={classes.container}>
      <div className={classes.selectCountryContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.selectCountryHeader}>Select your country</div>
          <div className={classes.selectCountryDescription}>
            Selected countries will be listed on webstore to procure device for employee
          </div>
          <div className={classes.search}>
            <div className="card-title">
              <div className="d-flex align-items-center position-relative my-1">
                <SVG
                  path="/media/icons/duotune/general/gen004.svg"
                  customSpanStyles={{marginLeft: '6px', position: 'absolute'}}
                />
                <HUIInputGroup.Input
                  customClasses="form-control form-control-solid ps-14 w-100 bg-white"
                  placeholder="Search your country"
                  value={searchText}
                  onChange={handleCountrySearch}
                />
              </div>
            </div>
          </div>
        </div>
        {!countryOptions?.length && <HUISpinner overrideStyle={{height: '20rem'}} />}
        <div className={classes.countriesContainer}>
          {(searchText
            ? countryOptions.filter(country =>
                country.label.toLowerCase().includes(searchText.toLowerCase()),
              )
            : countryOptions
          ).map(country => (
            <div key={country.value} className={classes.singleCountry}>
              <HUICheckbox
                checked={selectedCountries.includes(country.value)}
                onChange={e => handleCountrySelect(e, country.value)}
              />
              <div className={classes.countryFlag}>
                <ReactCountryFlag
                  svg
                  countryCode={getCountryInfo(country.value)?.country_code.toLowerCase() || ''}
                  style={{
                    width: '2rem',
                    height: '1rem',
                    borderRadius: '10px',
                    marginTop: '-2px',
                  }}
                />
              </div>
              <div className={classes.countryName}>{country.label}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.secondSection}>
        {selectedCountries?.length ? (
          <div className={classes.selectedCountriesContainer}>
            <div className={classes.headerContainer}>
              <div className={classes.selectedCountryHeader}>Selected country</div>
              <div className={classes.selectedCountryDescription}>
                {' '}
                Procure your device from below countries from webstore
              </div>
            </div>
            <div className={classes.selectedCountries}>
              {selectedCountries.map(country => (
                <div className={classes.singleSelectedCountry}>
                  <div className="d-flex">
                    <div className={classes.countryFlag}>
                      <ReactCountryFlag
                        svg
                        countryCode={getCountryInfo(country)?.country_code.toLowerCase() || ''}
                        style={{
                          width: '2rem',
                          height: '1rem',
                          borderRadius: '10px',
                          marginTop: '-2px',
                        }}
                      />
                    </div>
                    <div className={classes.countryName}>{getCountryInfo(country)?.name} </div>
                  </div>

                  <img
                    className={classes.remove}
                    onClick={() => handleCountrySelect(false, country)}
                    src="/media/icons/onboarding/cross.svg"
                    alt="remove"
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className={classes.noCountrySelected}>
            <div className={classes.emptyHeader}>WebStore</div>
            <img
              className="my-4"
              src="/media/icons/onboarding/no-country-selected.svg"
              alt="no country selected"
            />
            <div className={classes.emptyDescription}>Procure device from anywhere</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default FourthStep
