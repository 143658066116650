import {useQuery} from '@tanstack/react-query'
import {useMutate} from 'app/utils/hooks/useMutate'
import {UIService} from './ui.service'

const svc = new UIService()

export const useGetTableCustomCols = (name: string, queryKey) => {
  const res = useQuery({
    queryKey,
    queryFn: () => svc.getTableCols(name),
  })
  return {
    tableColumns: res.data,
    isPendingTableColumns: res.isPending,
    isErrorTableColumns: res.isError,
  }
}
export const useSaveTableCustomCols = (name: string, queryKey) => {
  return useMutate(columns => svc.saveTableCols(columns, name), queryKey)
}

export const useExportTableData = (tableName, queryKey, format = 'csv') => {
  const {isLoading, isError, refetch, isFetching, status} = useQuery({
    queryKey,
    queryFn: () => svc.exportTableData(tableName),
    enabled: false, // Disable automatic fetching
    staleTime: Infinity, // Data won't be considered stale
    retry: 1, // Retry failed requests up to 3 times
  })

  const exportData = async () => {
    const result = await refetch()

    if (result.isSuccess) {
      handleFileDownload(result.data, tableName, format)
    } else {
      console.error('Export failed:', result.error)
      // Optionally, handle the error by showing a message to the user
    }
  }

  return {
    exportData,
    isPendingExport: isLoading || isFetching,
    isErrorExport: isError,
    exportStatus: status,
  }
}

// Helper function to handle file download without third-party libraries
const handleFileDownload = (data, tableName, format) => {
  const blob = new Blob([data], {type: getMimeType(format)})
  const url = window.URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = url
  a.download = `${tableName}.${format}`
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url) // Clean up the object URL
  document.body.removeChild(a) // Remove the temporary anchor element
}

// Helper function to determine MIME type based on format
const getMimeType = format => {
  switch (format) {
    case 'csv':
      return 'text/csv;charset=utf-8;'
    case 'json':
      return 'application/json;charset=utf-8;'
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;'
    default:
      return 'text/plain;charset=utf-8;'
  }
}
