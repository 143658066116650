export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string | string[]) {
  if (typeof url === 'string') {
    if (pathname.includes(url)) {
      return true
    }
  } else {
    for (let u of url) {
      if (pathname.includes(u)) {
        return true
      }
    }
  }

  return false
}
